.neo__grid-container .neo__content-block.full {
  .neo__featured-product-list-block__innerbackground {
    background: $neo-light-gray;
    width: 100%;
    left: 0;
    height: calc(100% - 64px * 5);
    bottom: 128px;
    position: absolute;
    max-width: unset !important;
  }
}

.neo__featured-product-list-block__outer {
  position: relative;

  .neo__featured-product-list-block__inner {
    max-width: calc(266px * 4 + 64px * 3);
    margin: 0 auto;

    .VueCarousel-dot {
      @include until-desktop {
        width: 12px !important;
        height: 12px !important;
        padding: 12px !important;
        margin-top: 12px !important;
        @include until-tablet {
          width: 16px !important;
          height: 16px !important;
          padding: 16px !important;
          margin-top: 16px !important;
        }
      }
    }

    .VueCarousel-dot--active {
      background-color: $neo-blue !important;
    }

    @include tablet-only {
      max-width: calc(266px * 3 + 64px * 2);
    }
    @include mobile-only {
      max-width: calc(266px * 2 + 64px * 1);
    }

    .neo__featured-product-category-list-item__outer {
      max-width: 412px;
      .neo__featured-product-category-list-item {
        width: calc(100% - 32px);
        height: 100%;
        margin: 0 auto;
        border-radius: 4px;
        padding-bottom: 8px;
        box-shadow: inset 0 -8px 0 0 $neo-blue;
        border: solid 1px $neo-dark-gray;
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .neo__featured-product-category-list-item-top {
          // max-width: calc(266px + 16%);
          width: 100%;
          margin-right: auto;
          margin-left: auto;

          h3 {
            padding: 16px 24px;
          }

          .neo__featured-product-category-list-item-top-content {
            padding: 0 24px;

            *,
            & {
              color: $black;
              line-height: 1.75;
            }

            ul li {
              min-height: 1.75em;
            }
          }

          .neo__featured-product-category-list-item-top-image-holder {
            height: 266px;
            // width: 266px;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            position: relative;
            overflow: hidden;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-height: 100%;
              // max-width: calc(100% - 16px);
              height: 100%;
              // max-width: 100%;
              width: auto;
              height: auto;
              object-fit: contain;
            }
          }
        }

        .neo__featured-product-category-list-item-top-title {
          font-size: 16px;
          font-weight: bold;
        }

        .neo__featured-product-category-list-item-bottom {
          background-color: rgba($neo-light-gray, 0.5);

          .neo__featured-product-category-list-item-bottom-featured {
            width: max-content;
            margin: 0 auto 16px auto;
            padding: 12px;

            a {
              padding: 0;
              display: flex;
              flex-direction: column;
              margin: 0;
            }

            .neo__featured-product-category-list-item-bottom-image-holder {
              height: 200px;
              width: 200px;
              margin: 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: auto;
              }
            }

            .neo__featured-product-category-list-item-bottom-title-text {
              width: max-content;
              margin: 0 auto;
              max-width: 100%;
              justify-self: center;
              align-self: center;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
