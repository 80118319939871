.duurzaamCampaignPages {
	background: #d5d5d5;
	.page {
		margin: 0 auto;
		max-width: 1280px;
	}
	.grid-container {
		margin: 0 auto !important;
		max-width: 1280px !important;
		padding: 0 !important;
		height: 100%;
	}
	.triptych__item-content {
		position: relative;
	}
	@media (min-width: 768px) {
		.triptych__item-content {
			padding-bottom: 80px;
			.triptych__item-cta {
				margin: 0;
				position: absolute;
				bottom: 10px;
				left: 50%;
				width: 100% !important;
				max-width: 80% !important;
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
			}
		}
	}
}

// Hero text
.duurzaamCampaignPages {
	.hero__title,
	.hero__subtitle {
		color: $white;
		font-family: Tahoma, Verdana, Segoe, sans-serif;
	}

	.hero__ctas a {
		margin: 12px 0 !important;
	}
}

// Hero Images
.duurzaamCampaignPages {
	// Duurzaam
	.page--home {
		.hero__outer,
		.hero__outside-grid-wrapper {
			height: 55vw;
			min-height: 220px;
			max-height: 600px;
			overflow: hidden;
		}
		.hero--other {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}
	}

	// hybride-systeem-kwaliteit
	.page {
		.hero__outer,
		.hero__outside-grid-wrapper {
			margin-top: 56px !important;
			@media (min-width: 768px) {
				margin: 0 !important;
			}
		}
	}

	// Huren
	.page--rental {
		.grid-wrapper {
			height: 100%;
			box-sizing: border-box;
		}
		.hero-without-product-shot {
			height: 100%;
			box-sizing: border-box;
		}
		.hero--other {
			display: flex;
			justify-content: space-between;
			height: 100%;
			box-sizing: border-box;
		}
	}

	// Elektrische warmtepomp en cv-ketel-prijs
	.page--heat-pump,
	.page--boiler-price {
		.hero-with-product-shot {
			position: relative;
		}
		.hero--other {
			@media (min-width: 768px) {
				height: 474px;
			}
			@media (min-width: 992px) {
				height: 600px;
			}
			@media (min-width: 1280px) {
				height: 600px;
				background-position-x: center;
			}
		}
	}
}

// Buy and rent
.duurzaamCampaignPages {
	z-index: 0;
	.product-comparison__body {
		z-index: 0;
	}
	.product__content {
		padding: 16px;
		box-sizing: border-box;
	}
	.product__price-amount .buy,
	.product__price-amount .rent {
		display: none;
		width: 105px;
	}
	.product__price-amount .per-month {
		display: none;
		font-size: 0.5em;
	}
	.product__price-amount.buy-active {
		.buy {
			display: block;
		}
	}
	.product__price-amount.rent-active {
		.rent,
		.per-month {
			display: block;
		}
	}
	.product__footer {
		.product__cta.button {
			box-sizing: border-box;
		}
	}
}

// slick sliders (defect)
.duurzaamCampaignPages {
	.others-say__nav {
		display: none !important;
	}

	// Remove the hardcoded styles form the static html
	.slick-track {
		max-width: 100%;
	}

	.slick-slide,
	.slick-slide:nth-of-type(n + 2),
	.slick-slide:nth-of-type(n + 3),
	.slick-slide:nth-of-type(n + 4),
	.slick-slide:nth-of-type(n + 5) {
		display: block !important;
	}
	.slick-slide:nth-of-type(n + 2) {
		display: none !important;
	}
	@media (min-width: 600px) {
		.slick-slide,
		.slick-slide:nth-of-type(n + 2),
		.slick-slide:nth-of-type(n + 3),
		.slick-slide:nth-of-type(n + 4),
		.slick-slide:nth-of-type(n + 5) {
			display: block !important;
		}
		.slick-slide:nth-of-type(n + 3) {
			display: none !important;
		}
	}
	@media (min-width: 768px) {
		.slick-slide,
		.slick-slide:nth-of-type(n + 2),
		.slick-slide:nth-of-type(n + 3),
		.slick-slide:nth-of-type(n + 4),
		.slick-slide:nth-of-type(n + 5) {
			display: block !important;
		}
		.slick-slide:nth-of-type(n + 4) {
			display: none !important;
		}
	}
	@media (min-width: 992px) {
		.slick-slide,
		.slick-slide:nth-of-type(n + 2),
		.slick-slide:nth-of-type(n + 3),
		.slick-slide:nth-of-type(n + 4),
		.slick-slide:nth-of-type(n + 5) {
			display: block !important;
		}
		.slick-slide:nth-of-type(n + 5) {
			display: none !important;
		}
	}
}

// text and buttons
.duurzaamCampaignPages {
	.expert__content {
		box-sizing: border-box;
		padding: 16px 12px;
		@media (min-width: 600px) {
			padding: 20px 16px;
		}
	}
	.button {
		padding: 10px 12px;
		@media (min-width: 768px) {
			padding: 10px 12px;
		}
	}
	.find-dealer__cta {
		padding: 6px 12px;
		@media (min-width: 768px) {
			padding: 10px 12px;
		}
	}
}

.duurzaamCampaignPages {
	font-family: Tahoma, Verdana, Segoe, sans-serif;
	.page--hybrid-quality {
		.calculation__sticker {
			box-sizing: border-box;
		}
		.calculation__receipt {
			& > * {
				box-sizing: border-box;
			}
		}
	}
	.page--hybrid-quality,
	.page--hybrid-cost-saving {
		@media (min-width: 768px) {
			.product-comparison__body {
				.inner {
					padding-top: 120px;
				}
			}
		}
	}
}

.duurzaamCampaignPages {
	.page--hybrid-cost-saving,
	.page--hybrid-quality {
		.product {
			display: flex;
			height: auto !important;
		}
	}
}
