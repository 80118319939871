.neo__related-products-block__outer {
  grid-column: 2/14;
  .neo__related-products-block__title {
    margin-bottom: 16px;
    font-size: 26px;
  }

  .neo__related-products-block__inner {
    padding: 0;
    margin: 0;
    border-radius: 4px;
    border: solid 1px $neo-dark-gray;
    list-style: none;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    padding-bottom: 8px;
    box-shadow: inset 0 -8px 0 0 $neo-blue;
    position: relative;

    .VueCarousel-inner {
      justify-content: flex-start;
    }

    .VueCarousel-navigation {
      position: relative;
      width: 100%;
      top: -132px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include until-tablet {
        transform: scale(1.05);
      }

      button {
        transition: opacity 0.2s ease-in-out;
        margin: 0 !important;

        &:first-child {
          transform: none !important;
        }

        &:last-child {
          transform: none !important;
        }
      }
    }

    .neo_related-product {
      display: inline-block;
      padding: 24px 0;
      width: 164px;

      a {
        height: max-content;
        width: 100%;
        display: block;

        .neo_related_image-holder {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          display: flex;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        p {
          width: max-content;
          display: flex !important;
          justify-content: center !important;
          padding: 8px 0 0 !important;
          margin: 0 auto !important;
          max-width: 80%;
          text-align: center;
        }
      }
    }
  }
}
