.image__container{
  display: block;
  width: 100%;
  height: 100%;
  .image, 
  img{
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .image{
    &.mode--cover img{
      object-fit: cover;
    }
    &.mode--contain img{
      object-fit: contain;
    }
  }

  // 
  &.top,
  &.center,
  &.bottom{
    display: flex;
    flex-direction: row;
    &__left {
      justify-content: flex-start;
    }
    &__center {
      justify-content: center;
    }
    &__right {
      justify-content: flex-end;
    }
  }
  &.top{
    align-items: flex-start;
  }
  &.center{
    align-items: center;
  }
  &.bottom{
    align-items: flex-start;
  }

  picture{
    display: flex;
    justify-content: inherit;
    align-items: inherit;
  }
}