.product-properties-section {
  width: 100%;

  .product-properties {
    .product-properties__image {
      height: 100px;
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom center;

      @include tablet {
        width: 100%;
        height: 315px;
      }
    }

    .product-properties__bottom-row {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include tablet {
        margin-top: 32px;
        flex-direction: row;
        justify-content: space-between;
      }

      .product-properties__left-col {
        width: 100%;

        @include tablet {
          width: 45%;

          h5 {
            margin-top: 0;
          }
        }
      }

      .product-properties__right-col {
        width: 100%;

        .detail-grid {
          display: flex;
          flex-wrap: wrap;

          div {
            margin-bottom: 16px;
            width: 50% !important;
          }

          @supports (display: grid) {
            @include tablet {
              display: grid;
              grid-gap: 32px;
              grid-template-columns: 1fr 1fr;
              height: 100%;
            }
          }


          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            small {
              width: 80px;
              text-align: center;

              @include tablet {
                width: 100px;
              }
            }

            .detail-grid__img {
              margin-bottom: 16px;
              max-width: 100%;
            }
          }
        }

        @include tablet {
          width: 45%;
        }
      }
    }
  }
}