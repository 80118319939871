.block--neo-advice-block {
  position: relative;
  background: #f1f1f1;
  padding: 32px 0;
  .block__header {
    grid-column: 2/14;
    .block--title {
      text-align: center;
      flex-direction: column-reverse;
      justify-content: center;
      @include desktop {
        flex-direction: row;
      }
    }
  }
  .block__content {
    grid-column: 2/14;
    gap: 32px;
    @include desktop {
      &.col--2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @include desktop {
      &.col--3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .block__item,
    .block__partial,
    .partial__block {
      margin-bottom: 16px;
      grid-column: 1fr;
      @include desktop {
        margin-bottom: 0;
      }
    }
  }
}
