.product-category-section {
    display: flex;
    flex-direction: column;

    &:last-child {
        margin-bottom: 64px;
    }

    @include tablet {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .product-category {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $grey;
        padding: 20px;
        margin: 16px 0;

        @include tablet {
            flex: 0 0 47%;

            &:nth-of-type(odd) {
                margin-right: 3%;
            }
        }

        @include desktop {
            margin: 0;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 16px 0;
        }

        .product-category__header {
            order: 1;
            margin-bottom: 16px;
            text-align: center;

            @include desktop {
                text-align: left;
            }

            @include tablet {
                flex: 100%;
            }

            h3 {
                margin: 0;
            }
        }

        .product-category__body {
            order: 3;

            @include desktop {
                flex: 50%;
                display: flex;
                align-self: stretch;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .product-category__footer {
            order: 2;

            @include desktop {
                margin-right: 32px;
            }
        }
    }

    .product-category__image {
        max-width: 175px;

        @include widescreen {
            margin: 0;
            order: 3;
        }
    }
}