@use "sass:color";

.nefit-quote-block {
    position: relative;
    margin-bottom: 48px;
}

.nefit-quote-block__header {
    font-size: 24px;
    margin-bottom: 0;
}

.nefit-quote-block__text {
    width: 70%;
}

.nefit-quote-block__image {
    position: absolute;
    bottom: 0;
    width: 110px;
    right: -10px;
}

.nefit-quote-block__form {
    display: flex;
    width: 100%;

    @include tablet {
        width: 60%;
    }
}

.nefit-quote-block__label {
    display: flex;

    &--textinput {
        flex: 70%;
    }

    &--submit {
        flex: 30%;
        position: relative;

        &::after {
            content: url("#{ $base-url }img/icons/epsilon_right.svg'}");
            position: absolute;
            right: 11px;
            top: 12px;
        }
    }
}

.nefit-quote-block__submit {
    font-size: 14px;
    font-weight: lighter;
    text-decoration: none;
    width: 100%;
    height: 40px;
    padding: 8px 2px;
    background: $buttonGradient;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;

    &:hover {
        background: color.scale($red, $lightness: -10%);
    }

    span {
        margin-left: 12px;
    }
}