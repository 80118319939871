.form-wrapper {
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 18px;
    width: 100%;
    max-width: 568px;

    input,
    textarea {
        font-family: inherit;
        font-size: 14px;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #808285;
    }

    .form-title {
        margin-top: 0;
    }

    .submit-input {
        height: 40px;
        padding: 8px 32px;
        border: none;
        background: linear-gradient(90deg, #e20015 0%, #b00010 100%);
        cursor: pointer;
        color: white;

        &:hover {
            background: #af0010;
        }
    }

    .success {
        padding: 8px;
        border: 2px solid #76d576;
        color: #76d576;
        text-align: center;
    }
}