.faq-page-block {
  color: $black;

  .categories {
    .button-container {
      color: $black;

      button {
        display: block;
        font-size: 16px;
        color: $grey;
        position: relative;
        transition: color 0.3s;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
          color: $black;
        }

        &.active {
          color: $black;

          &:after {
            display: block;
            content: "";
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid $lightgrey;
            position: absolute;
            right: 0;
            top: calc(50% - 5px);
          }
        }
      }
    }
  }

  .questions {
    strong {
      margin: 18px 0;
      display: block;
    }
  }

  .question-container {
    border: 1px solid $lightgrey;
    margin-bottom: 16px;

    .question-container_question {
      padding: 20px 44px 20px 24px;
      background: $lightgrey;
      position: relative;
      cursor: pointer;
      transition: background 0.3s;
      font-weight: bold;

      &:hover {
        background: white;
      }

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-top: 3px solid black;
        border-right: 3px solid black;
        display: block;
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        transform: rotate(135deg);
        transition: transform 0.3s, border 0.3s;
      }
    }

    .question-container_answer {
      max-height: 0;
      overflow: hidden;
      padding: 0 24px;
    }

    &.open {
      .question-container_question {
        background: white;

        &:after {
          border-top: 3px solid $red;
          border-right: 3px solid $red;
          transform: rotate(-45deg);
        }
      }

      .question-container_answer {
        max-height: 600px;
        padding: 0 24px 24px;
      }
    }
  }
}

.faq-block__title {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  box-sizing: border-box;
}
