.why-block {
    border-radius: 4px;
    box-shadow: inset 0 -8px 0 0 $blue;
    border: 1px solid $grey;
    background-color: $white;
    max-width: 600px;
    margin-bottom: 32px;
    display: block;
    // display: flex;
    // flex-direction: column;

    .block-header-image {
        width: 100%;
        max-height: 210px;
    }

    .block-header-image-container {
        height: 210px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .block-body {
        flex: 1;
        padding: 16px;
    }

    .block-footer {
        padding: 16px;
    }

    .block-title {
        margin-top: 0;
        text-align: center;
    }

    .block-footer {
        background: rgb(241, 241, 241);
        background: linear-gradient(0deg, rgba(241, 241, 241, 0) 0%, rgba(232, 232, 232, 0.5) 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 32px;

        .block-footer-image {
            max-width: 200px;
            max-height: 200px;
            margin-bottom: 2em;
        }

        .block-bullet-list {
            list-style: none;
            padding-left: 0;
            margin-bottom: 32px;

            .block-bullet {
                position: relative;
                padding-left: 16px;
                margin: 8px 0;
                color: $black;

                &::before {
                    position: absolute;
                    content: '✓';
                    color: $blue;
                    left: 0;
                }
            }
        }

        .block-cta-list {
            width: 100%;
            display: flex;
            justify-content: space-around;

            align-items: center;
            flex-direction: column;

            @include desktop {
                align-items: flex-end;
                flex-direction: row;
            }

            .cta {
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;

                &:last-child {
                    margin-bottom: 0;
                }

                @include desktop {
                    margin-bottom: 0;
                }

                .cta-img {
                    margin-bottom: 16px;
                    max-width: 190px;
                }
            }
        }
    }

}