
            @import "@/styles/__abstracts/abstracts.scss";
          

.c-sticky-anchor {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transform: translateX(40px);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  @include fullscreen {
    right: 120px;
  }

  &--visible {
    transform: translateX(0);
    opacity: 1;
  }

  &__link {
    @include basic-reset;

    position: relative;
    display: inline-flex;
    align-items: center;
    border: none;
    background: none;
    padding: 12px 18px 12px 18px;
    text-decoration: none;
    color: $neo-btn-text-color;
    background: $bosch-blue;
    cursor: pointer;
    width: auto;
    outline: none;
    font-size: 16px;
    @include desktop {
      max-width: 300px;
    }
    @include on-event {
      outline: none;
      color: white;
      background: $bosch-blue-hover;
      &:before {
        left: 6px;
      }
      &:after {
        right: 6px;
      }
    }
  }
}
