.category-toggle-section {
  .category-list {
    list-style: none;
    padding-left: 0;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    border-bottom: 2px solid $lightgrey;
    @include tablet {
      flex-wrap: nowrap;
    }

    .category-list__item {
      display: flex;
      flex: 50%;
     
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      opacity: .3;
      cursor: pointer;

      @include tablet {
        flex: 16.666%;
      }

      &.active {
        cursor: initial;
        opacity: 1;
      }

      .category-list__label {
        font-size: 14px;
        text-align: center;
      }

    }
  }
}