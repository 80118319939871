.featured-image {
    max-width: 100%;

    @include tablet {
        margin: 64px;
    }
}

.text-block-w-image {
    justify-content: space-between;

    h3 {
        margin: 0;
    }
}