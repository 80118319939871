.neo-dealer-locator-block-outer {
  position: relative;
  grid-column: 2/14;

  .neo__grid-container-background-image {
    position: absolute;
    z-index: -1;
    left: 0;
    background-color: $neo-light-gray;
    bottom: 0;
    height: calc(100% - 64px);
    width: 100%;
    background-size: cover;

    &.neo__grid-container-background-color-important {
      background-image: linear-gradient($neo-light-gray, $neo-light-gray) !important;
    }
  }

  .neo-dealer-locator-block-empty, .neo-dealer-locator-block-loading {
    height: calc(30vw + 72px);
    margin: 32px 0 64px;
  }

  .neo-dealer-locator-block-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    @include until-tablet {
      height: calc(90vh - 64px);
    }
  }

  .neo-dealer-locator-block-inner {
    .neo-dealer-locator-block-navigation {
      form {
        padding: 24px;
        background: $white;
        border: solid 1px $neo-dark-gray;
        display: flex;
        justify-content: space-between;
        @include until-tablet {
          flex-wrap: wrap;
        }

        .neo-dealer-locator-block-navigation-left, .neo-dealer-locator-block-navigation-right {
          flex: 0 0 50%;
          max-width: calc(50% - 16px);
          display: flex;
          justify-content: space-between;
          @include until-tablet {
            flex: 0 0 100%;
            max-width: none;
          }

          select, input {
            font-size: 14px;
            border-radius: 0;

            &::-ms-expand {
              display: none;
            }
          }
        }

        .neo-dealer-locator-block-navigation-left {
          position: relative;

          .neo-dealer-locator-block-adress-field-outer {
            flex: 1;
            position: relative;

            .neo-dealer-locator-block-adress-field {
              height: 100%;
              width: 100%;
              padding: 12px 16px;
              border: 1px solid $neo-dark-gray;

              &:focus {
                outline: none;
                border-color: Highlight;
                border-color: -webkit-focus-ring-color;
                box-shadow: 0 0 5px 2px ThreeDHighlight;
                box-shadow: 0 0 5px 2px -webkit-focus-ring-color;
              }

              &:focus ~ .neo-dealer-locator-block-adress-field-placeholder,
              &:not(:focus):valid ~ .neo-dealer-locator-block-adress-field-placeholder {
                top: 0;
                font-size: 12px;
              }
            }

            .neo-dealer-locator-block-adress-field-placeholder {
              display: block;
              position: absolute;
              margin: 0 8px;
              padding: 0 8px;
              top: 50%;
              left: 0;
              background-image: linear-gradient(to right, transparent 0%, $white 3%, $white 97%, transparent 100%);
              transform: translateY(-50%);
              z-index: 1;
            }
          }

          .neo-dealer-locator-block-select-outer {
            position: absolute;
            right: 0;
            top: 0;
            width: max-content;
            height: 100%;

            select {
              background: $white;
              height: 100%;
              -webkit-appearance: none;
              -moz-appearance: none;
              padding: 12px 16px;
              appearance: none;
              border: solid 1px $neo-dark-gray;
              display: block;
              margin-right: -32px;
              padding-right: 32px;
            }
          }
        }

        .neo-dealer-locator-block-navigation-right {
          @include until-tablet {
            padding-top: 16px;
            flex-wrap: wrap;
            * {
              flex: 0 0 100%;
              margin-left: 0 !important;

              .neo__cta {
                margin-top: 16px !important;
                width: 100%;
              }
            }
          }

          .neo-dealer-locator-block-select-outer {
            flex: 1;

            select {
              width: 100%;
              background: $white;
              height: 100%;
              -webkit-appearance: none;
              -moz-appearance: none;
              padding: 12px 16px;
              appearance: none;
              border: solid 1px $neo-dark-gray;
              display: block;
              margin-right: -32px;
              padding-right: 32px;
            }
          }

          .neo-dealer-locator-block-submit-button {
            margin-left: 32px;
            font-size: 14px;
            text-align: center;
            max-width: none;

            button {
              margin: 0;
              max-width: none;
            }
          }

          .neo-dealer-locator-block-submit-button-hidden {
            display: none;
          }
        }
      }
    }

    .neo-dealer-locator-block-result-outer {
      display: flex;
      padding-top: 32px;
      padding-bottom: 64px;
      justify-content: space-between;
      @include until-tablet {
        flex-wrap: wrap;
        > * {
          @include flex-width(100%, 0);

          &:not(:last-child) {
            padding-bottom: 32px;
            @include until-tablet {
              padding-bottom: 8px;
            }
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 0;
        }
      }

      .neo-dealer-locator-block-result-list-outer {
        @include flex-width(50%, 16px);
        @include until-tablet {
          @include flex-width(100%, 0px);
        }
      }

      .neo-dealer-locator-block-map-outer {
        position: relative;
        display: block;
        max-height: 760px;
        z-index: 1;

        @include flex-width(50%, 16px);
        @include until-tablet {

          @include flex-width(100%, 0px);
          height: calc(90vh - 64px);
        }
      }
    }
  }
}