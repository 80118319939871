.neo__quote-block-outer {
  .neo__quote-block-inner {
    grid-column: 2/14;
    padding-left: 4px;
    border-left: solid 4px $neo-blue;
    display: flex;
    flex-wrap: wrap;

    .neo__quote-block-content {
      flex-grow: 1;
      padding: 0 16px;

      @include tablet{
        @include flex-width(50%, 0);
      }
     
      h4 {
        font-weight: bold;
        font-size: 16px;
        line-height: 2;
      }

      h5 {
        font-size: 14px;
        line-height: 16px;
        color: $neo-dark-gray;
      }

      div,
      p {
        line-height: 1.75;
      }
    }

    .neo__quote-block-image {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
      @include tablet{
        @include flex-width(50%, 0);
      }

      .image{
        object-fit: contain;
      }
    }
  }
}

//THIS STYLING IS USED FOR _neo-personal-info.scss TOO, IF YOU CHANGE SOMETHING HERE..
