.block--neo-time-line-block {
  .container {
    overflow: hidden;
  }
  .block--intro,
  .block--list {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-column: 2/14;
  }

  // Intro
  .block--intro {
    max-width: 568px;
    margin-bottom: 32px;

    .block--title {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      @include tablet {
        flex-direction: row;
      }
    }
  }
  .block__cta__list {
    list-style: none;
    margin-top: 24px;
  }
  .block--list {
    margin: 0 0 36px 0;
    padding: 0;
    display: block;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200vw;
      margin-left: -100vw;
      margin-top: 36px;
      height: 100%;
      background: $neo-link-border-color;
      z-index: -1;
    }
  }

  .block--list--item {
    list-style: none;

    &:first-of-type {
      .block--list--item--year {
        margin-top: 0;

        &::before {
          display: none;
        }
      }
    }

    .block--list--item--year {
      position: relative;
      margin: 36px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before,
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 36px;
        background: black;
        position: absolute;
        left: 50%;
      }

      &::before {
        top: 0;
        transform: translate(-50%, -100%);
      }

      &::after {
        bottom: 0;
        transform: translate(-50%, 100%);
      }

      .block--list--item--year--sphere {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-width: 64px;
        min-height: 64px;
        background-color: $neo-blue;
        font-size: 16px;
        text-align: center;
        padding: 8px;
        border-radius: 32px;
      }

      p {
        color: white;
        font-weight: bold;
      }
    }

    .block--list--item--content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      background: white;
      border: 1px solid black;
      padding: 24px;
      .content--image {
        display: flex;
        width: 100%;
        position: relative;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
        @include tablet {
          width: calc(40% - 16px);
        }
        .image {
          object-fit: contain;
        }
      }
      .content--text + .content--image,
      .content--image + .content--text {
        margin-top: 16px;
        @include tablet {
          margin-top: 0;
        }
      }
      .content--text {
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        @include tablet {
          width: calc(60% - 16px);
        }

        .content--text--title {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
}
