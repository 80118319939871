.savings-result {
    position: relative;
    margin: 0 0 24px;

    .container {
        padding: 0;

        &:after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 8px;
            width: 100%;
            background-image: url('#{ $base-url }neo/fallback/brandbar.png');
            background-size: cover;
        }
    }
  .exception {
    &:after {
      bottom: -25px;
    }
  }
}

.savings-block {
    margin: 40px 0 40px $container-padding-mobile;

    @include mobile-only {
        width: calc(100vw - #{$container-padding-mobile * 2});
    }

    @include tablet {
        grid-column: 2 / -2;
        margin: 48px 0;
    }

    @include desktop {
        grid-column: 3 / -3;
    }

    @include widescreen {
        grid-column: 4 / -4;
    }
}