.product-detail-introduction {
  padding-right: 32px;

  h2 {
    max-width: 100%;
    word-break: break-word;
    @include tablet {
      font-size: 36px;
    }
    @include desktop {
      font-size: 48px;
    }
  }
  figure iframe {
    /* YouTube embed limits */
    width: 100% !important;
    max-width: 500px;
  }

  .downloads {
    h5 {
      margin-bottom: 0;
    }

    .downloads-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 32px;

      li {
        margin: 12px 0;
        padding-left: 30px;
        position: relative;

        &:before {
          background-image: url("https://s3.eu-west-1.amazonaws.com/np-nefit/Iconen/download.png");
          background-size: contain;
          content: "";
          position: absolute;
          left: 0;
          height: 20px;
          width: 20px;
          background-repeat: no-repeat;
          background-position: center center;
        }

        a {
          color: $black;
          transition: color 0.1s ease-in-out;

          &:hover {
            color: $red;
            transition: color 0.1s ease-in-out;
          }
        }
      }
    }
  }
}
