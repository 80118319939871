.block--neotext-with-image {
  .block--outer {
    grid-column: 2/14;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }

    .block--text-container,
    .block--image {
      width: 100%;
      @include tablet {
        flex: 1;
      }
    }
    .block--image {
      @include tablet {
        &.image-right {
          padding-left: 32px;
        }
        &.image-left {
          padding-right: 32px;
        }
      }
    }

    .block--text-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include tablet {
        flex: 1;
      }

      .block--text-container--inner {
        width: 100%;
        display: flex;
        flex-direction: column;

        .block--title {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          text-align: center;
          margin-top: 20px;
          @include desktop {
            flex-direction: row;
            text-align: left;
            margin-top: 0;
          }
        }

        .block__cta__list {
          list-style: none;
          padding-left: 0;
        }
      }
    }

    .block--image {
      display: flex;
      justify-content: space-between;

      .image__container {
        @include mobile-only {
          justify-content: flex-start;
          margin: 34px 0 0 0;
        }
        @include tablet-only {
          justify-content: flex-start;
          margin: 34px 0 0 0;
        }
      }

      .image {
        object-fit: contain;
      }

      .block--image-container--inner {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;

        .block--image--outer {
          height: 100%;
          display: block;
          position: relative;
        }
      }

      .video__container {
        display: block;
        width: 100%;
        max-width: 800px;
        margin: 16px auto 0;
        height: 0;
        padding-top: 56.5%;
        position: relative;
        @include desktop {
          width: 800px;
          padding-top: 0;
          height: calc(800px * 0.565);
        }

        iframe,
        .block--video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .block--logos--outer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 16px;

      .block--logos--inner {
        height: 70px;
        margin-right: 16px;
        @include tablet {
          height: 100px;
        }

        &--item {
          height: 100%;

          .image {
            object-fit: contain;
          }
        }
      }
    }
  }
}
