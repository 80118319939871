.neo__image-links__outer {
  .neo__image-links-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .neo__image-links__inner {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    list-style: none;

    flex-wrap: wrap;

    &.col-3 {
      .neo__image-links-link {
        @include desktop-only {
          flex: 0 0 calc(100% / 3);
          max-width: calc(100% / 3);
        }
      }
    }
    &.col-4 {
      .neo__image-links-link {
        @include tablet {
          flex: 0 0 calc(100% / 2);
          max-width: calc(100% / 2);
        }
        @include desktop {
          flex: 0 0 calc(100% / 4);
          max-width: calc(100% / 4);
        }
      }
    }

    .neo__image-links-link {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 16px;
      margin: 0;
      a {
        .neo__image-loading__container .image {
          transition: all 0.3s;
        }
        &:hover,
        &:focus {
          .neo__image-loading__container .image {
            transform: scale(1.2);
          }
        }
      }

      > a {
        text-decoration: none;
        display: block;
        width: 100%;
        @include until-desktop {
          max-width: 360px;
          margin: 0 auto;
        }

        .neo__image-links-link-image__outer {
          display: block;
          width: 100%;
          height: 232px;
          overflow: hidden;
        }

        > .neo__image-links-link-text {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;

          p {
            font-size: 24px;
            line-height: 32px;
            width: max-content;
            max-width: 200px;
            @include mq(1200px) {
              max-width: calc(100% - 32px);
            }
          }

          .neo-epsilon {
            align-self: center;
            justify-self: center;
            margin-left: 14px;
          }
        }
      }
    }
  }
}
