.block--neo-full-link-block {
  background: $full-link-block-background-mobile;
  @include desktop {
    background: $full-link-block-background-mobile;
  }

  .block--outer {
    grid-column: 2/14;
    @include desktop {
      grid-column: 4/12;
    }

    .block--inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin: 32px 0;
      @include tablet {
        justify-content: space-between;
        text-align: left;
        margin: 107px 0;
      }

      .block--header {
        .block--title {
          width: 100%;
          margin-bottom: 8px;
          text-align: left;
        }
      }

      .block--content {
        max-width: 644px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        @include tablet {
          max-width: unset;
          grid-column: 1/2;
        }
        @include desktop {
          grid-column: 1/3;
        }

        .block--title {
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
          @include tablet {
            flex-direction: row;
          }
        }

        .block--text {
          width: 100%;
          p {
            margin-top: 0;
          }
          & > * {
            font-size: 16px;
            line-height: 1.33;
            text-align: left;
          }
        }
      }

      .block--side-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include tablet {
          height: 100%;
          grid-column: 2/3;
        }
        @include desktop {
          grid-column: 3/4;
        }

        .image__container {
          display: flex;
          justify-content: center;
          .image {
            object-fit: contain;
            max-width: 240px;
            max-height: 240px;
            width: auto;
            height: auto;
          }
        }

        .image__container {
          margin: 32px 0 0 0;
          @include tablet {
            margin: 32px 0 0 0;
          }
        }
      }

      .block__cta__list {
        list-style: none;
        width: 100%;
        padding-left: 0;

        li {
          margin: 0;
          padding: 8px;
          padding-left: 0;

          .cta,
          .download {
            margin: 0;
            width: 100%;
            max-width: none;
            background: $white;
            color: $hoverblue;

            span {
              font-size: 16px;
            }
          }

          .cta {
            &:after {
              background-image: url("#{ $base-url }icons/epsilon_blue.svg");
              display: block;
              content: "";
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              right: 10px;
              top: 50%;
              width: 13px;
              height: 12px;
              display: block;
              transform: translateY(-50%);
              transition: 0.3s ease-in-out all;
            }
          }
          .download {
            padding-left: 18px !important;

            &:before {
              display: none;
            }
            &:after {
              background-image: url("#{ $base-url }icons/download_blue.svg");
              content: "";
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              right: 10px;
              top: 50%;
              width: 18px;
              height: 17px;
              margin-top: 3px;
              display: block;
              transform: translateY(-50%);
              transition: 0.3s ease-in-out all;
            }
          }
        }
      }
    }
  }
}
