.block--neo-table-block {
  .block__content {
    display: flex;
    flex-direction: column;
    grid-column: 2 /14;
  }
  .block__title {
    display: inline-block;
    text-align: center;
    @include mobile-only {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .block__table {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .block__text {
    font-size: 12px;
    color: $grey;
    line-height: 16px;
    padding: 8px 0;
    margin: 0;
  }
}
