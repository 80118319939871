@use "sass:color";

.block--neo-compare-block {
  position: relative;
  padding: 32px 0;
  background: #f1f1f1;
  color: black;

  .block__content {
    grid-column: 2/14;
    width: 100%;
  }

  .block__tile-wrapper {
    display: flex;
    justify-content: center;
  }

  .compare-tile {

    &__link {
      all: initial;
      display: inline-block;
      cursor: pointer;
      width: calc(20% - 12px);
      margin: 12px;
    }

    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // justify-content: flex-start;
      align-content: center;
      background-color: white;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }

    &__wrapper-inner {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__content {
      flex: 1;
      overflow: hidden;
      padding: 32px 18px 0;
    }

    &__image {
      flex: 0 0 auto;
      background-color: #E6E6E6;
      padding: 12px 20px;

      object-fit: contain;
      object-position: center;

      height: 207px;
      width: 100%;
    }

    &__title {
      font-family: $ff-bosch-sans;
      font-weight: 400;

      font-size: 24px;
      line-height: 30px;
    }

    &__category {
      padding-top: 8px;
      margin: 0;
      color: #005691;

      font-family: $ff-bosch-sans;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px; 
    }

    &__product-title {
      padding-top: 16px;

      font-family: $ff-bosch-sans;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px; 
    }

    &__bullet-list {
      // padding-bottom: 8px;

      * {
        font-family: $ff-bosch-sans;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }

      ul {
        padding: 0;
        list-style-type: none;

        li {
          position: relative;
          padding: 8px 0px 8px 20px;
          
          &::before {
            content:url('https://s3.eu-west-1.amazonaws.com/np-nefit-pro-staging/Iconen/list-check.svg');
            width: 12px;
            height: 12px;
            position: absolute;
            left: 0;
            display: inline-block;
          }
        }
      }
    }

    &__list-wrapper {
      flex: 1;
      padding: 0 18px ;

    }

    &__list-block {
      padding: 16px 0;
      border-bottom: 2px solid #f1f1f1;
      &:first-of-type {
        border-top: 2px solid #f1f1f1;
      }

    }

    &__list-block-title {
      font-family: $ff-bosch-sans;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px; 
      margin: 0;
      padding-bottom: 4px;
    }

    &__list-block-detail {
      display: flex;
      align-content: center;
    }

    &__list-block-image {
      display: block;
      height: 24px;
      width: 24px;
      object-fit: contain;
      object-position: center;
      margin-right: 8px;
    }

    &__list-block-value {
      margin: 0;
      display: flex;
      align-items: center;
      align-content: center;
      font-family: $ff-bosch-sans;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px; 
    }

    &__cta-wrapper {
      justify-self: flex-end;
      padding: 32px 18px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__cta-btn {
      display: block;
      padding: 10px;

      font-family: $ff-bosch-sans;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
      border: 2px solid #007BC0;

      &--primary {
        background-color: #007BC0;
        color: white;
        &:hover {
          background-color: color.scale(#007BC0, $lightness: -10%);
        }
      }

      &--secondary {
        background-color: white;
        color: #007BC0;
        margin-top: 16px;
        &:hover {
          background-color: color.scale(white, $lightness: -10%);
        }
      }
    }
  }

  @include until-desktop {
    .block__tile-wrapper {
      flex-wrap: wrap;
    }

    .compare-tile {
      &__link {
        width: calc(33% - 24px);
      }
    }
  }

  @include until-tablet {
    .compare-tile {
      &__link {
        width: calc(100% - 24px);
      }
    }
  }
}
