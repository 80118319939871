.page-section {
  padding: 8px 0;
  opacity: 0;
  transition: opacity 1.3s, bottom 0.8s;
  position: relative;
  bottom: -200px;

  &.animate {
    opacity: 1;
    bottom: 0;
  }

  @include tablet {
    padding: 48px 0;
  }

  &--small {
    @include tablet {
      padding-left: 64px;
      padding-right: 64px;
    }
  }
}

.page-section__header {
  text-align: center;
}
