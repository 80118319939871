.neo__steps-block__outer {
  overflow: hidden;
  position: relative;

  .neo__steps-block__inner {
    overflow: initial;

    .neo__steps-block-content {
      max-width: 568px;
      margin: 0 auto;
      text-align: center;

      * {
        text-align: center;
      }

      .neo__steps-block-content-text {
        line-height: 1.75;
        font-size: 14px;
        padding-bottom: 64px;
      }
    }

    .neo__steps-block-steps__outer {
      > .neo__steps-block__cta__list {
        padding: 16px 0;

        li {
          margin: 4px;
          padding: 0;
          text-align: center;

          a {
            color: $neo-blue;
            margin: 0;

            &:hover,
            &:focus {
              color: $black;
            }
          }
        }
      }

      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 40px;
        z-index: -1;
        background: $neo-light-gray;
        width: 101vw;
        height: calc(100% - 40px);
        transform: translateX(-50%);
      }

      .neo__steps-block-steps {
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        list-style: none;
        flex-wrap: wrap;
        @include until-desktop {
          width: calc(100% - 32px);
          margin: 0 16px;
        }
        .neo__steps-block__step {
          width: 100%;
          background-color: $white;
          padding: 0;
          margin: 0;
          border: 2px solid $neo-dark-gray;
          background-image: linear-gradient($neo-blue, $neo-blue);
          background-repeat: no-repeat;
          background-position: center 100%;
          background-size: 100% 8px;
          display: flex;
          flex-direction: column;
          flex: 0 0 100%;
          margin: 0;
          margin-bottom: 32px;
          @include desktop {
            margin: 0 16px;
            padding-bottom: 32px;
          }
        }
        &.col-3 {
          .neo__steps-block__step {
            @include desktop {
              flex: 1 1 calc(33% - 32px);
              max-width: calc(33% - 32px);
            }
          }
        }
        &.col-4 {
          .neo__steps-block__step {
            @include tablet {
              flex: 1 1 calc(50% - 32px);
              margin: 0 16px;
            }
            @include desktop {
              flex: 1 1 calc(25% - 32px);
              max-width: calc(25% - 32px);
            }
          }
        }

        .neo__steps-block__step {
          .neo__steps-block__step-image {
            height: 80px;
            margin: 16px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            .image {
              object-fit: contain;
            }
          }

          .neo__steps-block__step-content {
            display: flex;
            flex-direction: column;
            padding: 0 48px 42px;
            height: 100%;

            h4 {
              text-align: center;

              span {
                color: $neo-blue;
              }
            }

            div {
              text-align: center;

              * {
                text-align: center;
              }
            }

            .neo__steps-block__cta__list {
              margin: 0 -4px;
              width: calc(100% + 8px);
              max-width: calc(100% + 8px);
              display: flex;
              padding: 0 0 16px;
              margin-top: auto;
              li {
                width: max-content;
                max-width: 100%;
                padding: 0;
                margin: 16px auto 0;

                a {
                  width: 100%;
                  max-width: none;
                  margin: 0;
                }
              }
            }
          }
        }

        // @include until-desktop {
        //   flex-direction: column;
        //   .neo__steps-block__step {
        //     margin: 4px auto;
        //   }
        // }
      }
    }
  }
}
