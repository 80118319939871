
            @import "@/styles/__abstracts/abstracts.scss";
          

.quizTool {
  .col-6 {
    position: relative;
    border: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    height: 100%;
    > div {
      margin: 0;
      padding: 18px;
      box-sizing: border-box;
    }
    .neo__locator-block__title,
    .neo__advice__cta-block__title {
      margin: 0;
      .neo__locator-block_content {
        padding: 0;
      }
    }
  }
}
