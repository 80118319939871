.partials__table__inner {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

table {
  width: 100%;
  overflow: visible;
  min-width: max-content;
  border-spacing: 0;
  background: #f1f1f1;

  thead {
    font-size: 16px;
    text-align: left;

    tr {
      min-height: 40px;
    }

    th {
      background: #d1e4ff;
      color: $neo-blue;
      font-weight: bold;
      font-size: 16px;
      padding: 8px 24px;
    }
  }

  tbody {
    font-size: 14px;
    position: relative;
    min-height: 30px;
    padding: 8px;
    border-bottom: solid 1px white;

    tr {
      &:nth-of-type(1) td {
        padding-top: 24px;
      }
      &:not(:last-child) td {
        border-bottom: solid 1px $white;
      }
      &:last-child td {
        padding-bottom: 24px;
      }
    }
    
    td {
      padding: 8px 24px;
      &:first-child,
      &:first-child > p,
      &:first-child td,
      &:first-child td > p {
        color: $black;
      }
    }
  }

  td,
  th {
    .link,
    .cta,
    .neo__link {
      padding: 0;
      margin: 0;
      min-width: auto;
      height: auto;
      color: $black;
    }

    p {
      padding: 0;
      margin: 0;
    }

    &:hover {
      img {
        transform: scale(1.5);
      }
    }
  }

  img {
    z-index: 1;
    background: white;
    transform: scale(1);
    
    height: auto;
    max-height: 100px;
    min-height: 40px;
    width: max-content;
    
    transition: transform .3s ease-in-out;
    transform-origin: 0 0;
    box-shadow: 0 0 1rem rgb(0 0 0 / 25%);
  }
}
