// BASE GRID LAYOUT STYLING
$grid-gap: 25px;
$grid-width: 1600px;
$container-padding: 10px;
$container-padding-mobile: 24px;

.container {
 @include container;

  &__inner {
    grid-column: 2 / -2;
  }
}
