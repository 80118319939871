.intro-section {
  text-align: center;
  max-width: 40em;
  margin: 0 auto;

  &.wide {
    max-width: 60em !important;
  }
  .cta__button {
    margin-top: 16px;
  }
}

.intro-section__header {
  font-size: 48px;
  line-height: 1.1;
  margin: 0 0 32px 0;
}

.intro-section__body {
  font-size: 14px;
  p,
  ul {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
  p,
  li {
    color: black;
    line-height: 1.5;
  }
  ul {
    text-align: left;
    display: inline-block;
  }
}
