.block--neo-half-till-points {
  .neo_half-till-points {
    display: flex;
    flex-wrap: wrap;
    grid-column: 2/14;

    .neo_half-till-points-left-text,
    .neo_half-till-points-points-holder {
      flex: 1;
      @include until-desktop {
        flex: 0 0 100%;
      }
    }

    .neo_half-till-points-left-text {
      @include desktop-only {
        padding-right: 25px;
      }

      h5,
      h4,
      h3,
      h2,
      h1 {
        font-weight: bold;
        padding: 0 0 0.5em;
      }

      p {
        margin: 0;
        padding: 0 0 2em;
      }
    }

    .neo_half-till-points-points-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .neo_half-till-points-point {
        @include flex-width(50%, 32px);
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;

        div {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          > div {
            min-height: 80px;
          }

          p {
            text-align: center;
            line-height: 1.2;
          }
        }
      }
    }
  }
}
