// DIVICE SIZES
$mobile: 412px;
$phone-big: 565px;
$tablet: 768px;
$desktop: 1024px;
$widescreen: 1280px;
$fullscreen: 1700px;

$navbar-min-height: 840px;

// BASIC QUERIES
@mixin phone-big {
  @media only screen and (min-width: #{$phone-big}) {
    @content;
  }
}
@mixin tablet {
  @media only screen and  (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: #{$desktop}) {
    @content;
  }
}
@mixin widescreen {
  @media only screen and (min-width: #{$widescreen}) {
    @content;
  }
}
@mixin fullscreen {
  @media only screen and (min-width: #{$fullscreen}) {
    @content;
  }
}

@mixin container-min {
  @media only screen and  (min-width: #{$grid-width + $grid-gap * 2}) {
    @content;
  }
}

// DEVICE ONLY QUERIES
@mixin mobile-only {
  @media (max-width: #{$tablet - 1}) {
    @content;
  }
}
@mixin tablet-only {
  @media only screen and (min-width: #{$tablet}) and (max-width: #{$desktop - 1}) {
    @content;
  }
}
@mixin desktop-only {
  @media only screen and (min-width: #{$desktop}) and (max-width: #{$widescreen - 1}) {
    @content;
  }
}

// UNTIL SIZE QUERIES
@mixin until-tablet {
  @media only screen and (max-width: #{$tablet - 1}) {
    @content;
  }
}
@mixin until-desktop {
  @media only screen and (max-width: #{$desktop - 1}) {
    @content;
  }
}
@mixin until-widescreen {
  @media only screen and (max-width: #{$widescreen - 1}) {
    @content;
  }
}

// CUSTOM QUERIES
@mixin mq($width1, $width2: 0) {
  @if $width2 > 0 {
    @media only screen and (min-width: #{$width1} and (max-width: #{$width2}) ) {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$width1}) {
      @content;
    }
  }
}

// DEVICE HEIGHT QUERIES
@mixin large-page-height {
  @media only screen and (min-height: $navbar-min-height) {
    @content;
  }
}