@use "sass:color";

.independant-advice-block {
    position: relative;
    min-height: 150px;

    &--background {
        background: $white;
        padding: 16px;
    }
}

.independant-advice-block__header {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 0;
}

.independant-advice-block__text {
    width: 70%;
}

.independant-advice-block__image {
    position: absolute;
    bottom: 0;
    width: 200px;
    right: 0;
}

.independant-advice-block__form {
    display: flex;
    width: 100%;

    @include tablet {
        width: 60%;
    }
}

.independant-advice-block__label {
    display: flex;

    &--textinput {
        flex: 70%;
    }

    &--submit {
        flex: 30%;
        position: relative;

        &::after {
            content: url("#{ $base-url}img/icons/epsilon_right.svg'}");
            position: absolute;
            right: 13px;
            top: 13px;
        }
    }
}

.independant-advice-block__submit {
    font-size: 14px;
    font-weight: lighter;
    text-decoration: none;
    width: 100%;
    height: 40px;
    padding: 8 dpx 2px;
    background: $buttonGradient;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;

    &:hover {
        background: color.scale($red, $lightness: -10%);
    }

    span {
        margin-left: 12px;
    }
}