.block--neo-promotion-block {
  position: relative;
  padding: 32px 0;
  color: black;

  .block__content {
    grid-column: 2/14;
    width: 100%;
  }

  .block__tile-wrapper {
    display: flex;
    justify-content: center;
  }

  .promotion-tile {

    &__color {

      &--blue {
        border: solid 2px #007BC0;
        .promotion-tile__title {
          color: #007BC0;
        }
        .promotion-tile__cta {
          background-color: #007BC0;
        }
      }
      &--blueLight {
        border: solid 2px #489ECF;
        .promotion-tile__title {
          color: #489ECF;
        }
        .promotion-tile__cta {
          background-color: #489ECF;
        }
      }

      &--purple {
        border: solid 2px #9E2896;
        .promotion-tile__title {
          color: #9E2896;
        }
        .promotion-tile__cta {
          background-color: #9E2896;
        }
      }
      &--purpleLight {
        border: solid 2px #B764B1;
        .promotion-tile__title {
          color: #B764B1;
        }
        .promotion-tile__cta {
          background-color: #B764B1;
        }
      }

      &--green {
        border: solid 2px #00884A;
        .promotion-tile__title {
          color: #00884A;
        }
        .promotion-tile__cta {
          background-color: #00884A;
        }
      }
      &--greenLight {
        border: solid 2px #48A87C;
        .promotion-tile__title {
          color: #48A87C;
        }
        .promotion-tile__cta {
          background-color: #48A87C;
        }
      }

      &--turquoise {
        border: solid 2px #18837E;
        .promotion-tile__title {
          color: #18837E;
        }
        .promotion-tile__cta {
          background-color: #18837E;
        }
      }
      &--turquoiseLight {
        border: solid 2px #5DA8A5;
        .promotion-tile__title {
          color: #5DA8A5;
        }
        .promotion-tile__cta {
          background-color: #5DA8A5;
        }
      }

    }

    &__link {
      all: initial;
      display: inline-block;
      cursor: pointer;
      width: calc(20% - 12px);
      margin: 12px;
    }

    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: center; 
      text-align: center; 

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }

    &__content {
      flex: 0 0 auto;
      overflow: hidden;
    }

    &__title {
      padding-top: 16px;
      text-align: center;

      font-size: 24px;
      line-height: 30px;
    }

    &__subtitle {
      padding: 0 8px;
      text-align: center;

      font-family: $ff-bosch-sans;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    &__image {
      margin: 14px 20px 0;

      object-fit: contain;
      object-position: center;

      height: 135px;
      width: calc(100% - 40px);
    }

    &__description {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      padding: 8px;

      font-family: $ff-bosch-sans;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px; 
      text-align: center;

      flex: 1;
    }

    &__cta {
      width: 100%;
      align-self: flex-end;
      flex: 0 0 auto;
      padding: 16px;
    }

    &__cta-title {
      font-family: $ff-bosch-sans-bold;
      font-size: 18px;
      line-height: 22px;

      color: white;

      margin: 0;
    }

    &__cta-subtitle {
      font-family: $ff-bosch-sans;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      height: calc(17px * 2);

      color: white;

      margin: 0;
    }
  }

  @include until-desktop {
    .block__tile-wrapper {
      flex-wrap: wrap;
    }

    .promotion-tile {
      &__link {
        width: calc(33% - 24px);
      }
    }
  }

  @include until-tablet {
    .promotion-tile {
      &__link {
        width: calc(100% - 24px);
      }
    }
  }
}
