.bullet-points-section {
    padding: 18px 0;
}

.bullet-list {
    display: flex;
    padding-left: 20px;
    flex-direction: column;

    @include phone-big {
        flex-direction: row;
    }

    .col-6 {
        flex: 50%;
    }
}

.bullet-list__item {
    margin-bottom: 12px;
    width: 90%;

    a {
        color: $black;

        &:hover {
            color: $red;
        }
    }
}