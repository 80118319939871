.downloads-cta-list__outer {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 20px 0 40px 0;
	box-sizing: border-box;

	.list-title {
		margin-bottom: 10px;
	}

	.downloads,
	.links {
		width: 100%;
		margin: 0;
		box-sizing: border-box;

		@include tablet {
			width: calc(50% - 20px);
			margin-right: 20px;
		}
	}

	.downloads-list,
	.cta-button-list {
		padding: 0;
		margin: 0;

		li {
			list-style: none;
			margin-bottom: 10px;
			width: calc(100% - 20px);
			overflow: hidden;
		}
	}

	.downloads-list {
		li {
			a {
				height: 40px;
				padding: 0;
				width: 100%;
				font-size: 14px;
				font-weight: normal;
				color: $black;
				display: flex;
				align-items: center;
			}

			.download_icon {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				stroke: $black;
				fill: $black;
			}
		}
	}

	.cta-button-list {
		.cta__button {
			width: 100%;
		}
	}
}
