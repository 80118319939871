.neo__breadcrumbs__outer {
  display: none;

  @include tablet {
    display: block;
    width: 100%;
  }

  & > .neo__content-block {
    margin-top: 0;
  }
  
  .neo__breadcrumbs__list {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-transform: uppercase;
    list-style: none;
    padding: 12px 0 12px 16px;
    overflow-x: auto;
    overflow-y: hidden;
    @include desktop {
      max-width: 1600px;
    }
  }
}

.neo__breadcrumbs__list-item {
  padding: 8px 2px 7px 3px;
  line-height: 1em;
  font-size: 13px;
  list-style: none;
  color: $neo-dark-gray;
  position: relative;
  padding-right: 0.5em;
  display: block;
  width: max-content;
  display: inline-block;
  &:after {
    display: block;
    content: "/";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:last-child {
    color: $neo-link-hover-text-color;
    font-weight: bold;
    &:after {
      display: none;
    }
  }
}
.neo__breadcrumbs__item {
  width: max-content;
  text-decoration: none;
  line-height: 1em;
  font-size: 13px;
  list-style: none;
  color: $neo-dark-gray;
  transition: all 0.3s ease;
  display: inline-block;
  margin: 0;
  padding: 0;
  &:hover,
  &:focus {
    color: $black;
  }
  &.current-page {
    &:hover,
    &:focus {
      color: $black;
    }
  }
}
