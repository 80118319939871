.video-block-container {
  display: flex;
  width: 100%;
  position: relative;
}

.video-block {
  width: 100%;
  display: block;
  margin-right: -1px;
  height: 420px;
  border: 1px solid $lightgrey;
  position: relative;
  box-sizing: border-box;
  display: none;
  margin-bottom: 170px;
  position: relative;

  &__loader {
    height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $lightgrey;
  }

  .index {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    color: black;
    opacity: 0.5;
    font-size: 16px;
    bottom: -32px;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    line-height: 24px;
    transition: all 0.3s;
    z-index: 1;
  }

  .block-info {
    position: absolute;
    width: 100%;
    bottom: -150px;
    height: 130px;
    left: 0;
    color: black;
    padding: 0 40px;
    box-sizing: border-box;

    .title {
      margin: 0;
      font-size: 24px;
      line-height: 1.33;
    }

    p {
      color: $black;
      max-height: 0;
      overflow: hidden;
      transition: max-height 1s;
    }
  }

  &.active {
    display: inline-block;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );

    .index {
      width: 32px;
      height: 32px;
      opacity: 1;
      font-size: 24px;
      line-height: 32px;
    }

    p {
      max-height: 200px;
    }
  }

  .block-progress {
    height: 7px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    overflow: hidden;

    .scrubber {
      height: 100%;
      position: absolute;
      left: 0;
      background: $red;

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: -5px;
        background: $red;
        width: 7px;
        height: 7px;
        transform: skew(45deg);
      }
    }
  }

  @include tablet {
    flex: 1;
    display: inline-block;
    margin-bottom: 0;

    .block-info {
      bottom: 0;
      color: $white;
      height: auto;

      p {
        color: $white;
        margin: 24px 0;
      }
    }

    .index {
      top: 36px;
      bottom: auto;
      background: $lightgrey;
    }
  }
}

.mobile-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 420px;

  button {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    color: white;

    &.next {
      float: right;
    }

    .arrow-left,
    .arrow-right {
      width: 10px;
      height: 10px;
      display: block;
      transform: rotate(45deg);
    }

    .arrow-left {
      border-bottom: 2px solid white;
      border-left: 2px solid white;
    }

    .arrow-right {
      border-top: 2px solid white;
      border-right: 2px solid white;
    }
  }

  @include tablet {
    display: none;
  }
}

.mediaplayer {
  position: absolute;
  height: 420px;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
  font-size: 0;
  background-color: $black;
}

video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  &:focus {
    outline: none;
  }
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
}

// VIDEO MODAL STYLING

.video-and-image__container {
  position: relative;
  padding: 0;
  overflow: hidden;
}

.no-video-image__fallback {
  display: block;
  width: 100%;
  padding-top: 50%;
  background: linear-gradient(to top, #e20015 0%, #b00010 50%, #e20015 100%);
  background: #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.play-movie-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
}
.play-movie-btn__inner {
  display: block;
  width: 80px;
  height: 80px;
  background: transparent;
  border: 4px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-sizing: border-box;
  box-shadow: -3px 4px 25px -5px rgba(0, 0, 0, 0.72);
}

.right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 40px solid #fff;
  border-bottom: 20px solid transparent;
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 5px;
}

.video_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.video_modal__inner {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 40px);
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: -3px 4px 25px -5px rgba(0, 0, 0, 0.72);
  overflow: hidden;
  @include tablet {
    max-width: 80%;
  }
}

.video_modal__header {
  background: #fff;
  display: block;
  height: 40px;
  position: relative;
  display: block;

  .video_modal__icon {
    display: block;
    position: absolute;
    padding: 20px;
    z-index: 100;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
    }
  }
  .video_modal__icon__cross-bar {
    width: 3px;
    height: 20px;
    border-radius: 5px;
    background: red;
    display: block;
    &:nth-of-type(1) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
    &:nth-of-type(2) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}
.video_modal__content {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  video {
    height: 100%;
    box-sizing: border-box;
    margin-bottom: -6px;
  }
}
