.neo__search-page-block__outer {
  margin-top: 80px;
  margin-bottom: 80px;
}

// Search Page Input field
.neo__search-page-block__search-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 568px;
  margin: 0 auto;
  margin-bottom: 80px;
  @include mobile-only {
    max-width: 90%;
  }

  .neo__search-page-block__search-title {
    width: 100%;
    text-align: center;
  }

  .neo__search-page-block__search-form__outer {
    display: flex;
    justify-content: center;
    flex-direction: row;

    .neo__search-page-block__search-form {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      margin: 0;
      width: calc(100% - 112px);
    }

    .neo__cta.search {
      margin-left: 0;
      width: 112px;
      margin: 0 !important;
    }
  }
}
.neo__search-page-block__outer {
  // Search page Result field
  .neo__search-page-block__results {
    background: $neo-search-background-color;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 300px;
  }
}

// Search Loading
.search-page-block__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

// Search Results
.search-page-block__search-result-list {
  display: flex;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }

  .search-page-block__search-result-list__title {
    width: 100%;
    @include desktop {
      width: 200px;
      padding-top: 40px;
    }
  }

  .search-page-block__search-result-list__results {
    width: 100%;
    @include desktop {
      width: calc(100% - 200px);
      max-width: 658px;
      margin: 0 auto;
    }
  }

  .search-page-block__search-result-list__result__link {
    border-bottom: 1px solid $black;
    padding: 40px 0;
    display: block;
    text-decoration: none;
    color: $black;
    position: relative;
    padding-right: 30px;

    &:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-right: 2px solid $neo-search-background-color;
      border-bottom: 2px solid $neo-search-background-color;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-left: 20px;
      position: absolute;
      right: 10px;
      top: calc(50% - 4px);
    }
  }

  .search-page-block__search-result-list__result__title {
    font-weight: bold;
  }

  .search-page-block__search-result-list__result__description {
    max-height: 76px;
    overflow: hidden;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .search-page-block__search-result-list__fallback {
    margin-bottom: 80px;
  }
}

// Search Fallback
.search-page-block__search-result-fallback {
  width: 100%;

  .neo__search-page-block__fallback__intro {
    .neo__search-page-block__title {
      font-size: 32px;
    }
  }

  .neo__search-page-block__fallback__link-list {
    .neo__search-page-link-block {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto;
      margin-bottom: calc(80px - 16px);
      max-width: 90%;
      @include tablet {
        flex-wrap: wrap;
        margin-bottom: 50px;
      }
      @include desktop {
        max-width: 1000px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .neo__search-page-link-block__title {
      margin-bottom: 32px;
      width: 100%;
    }

    .neo__search-page-link-block__section {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      @include tablet {
        flex-direction: row;
      }
    }

    .neo__search-page-link-block__section__block {
      margin-bottom: 16px;

      &:nth-of-type(1n) {
        margin-right: calc((2 * 16px / 2) * 1);
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      @include desktop {
        &:nth-of-type(1n) {
          margin-right: calc(3 * 16px / 2);
        }
        &:nth-of-type(2n) {
          margin-right: calc(3 * 16px / 2);
        }
        &:nth-of-type(3n) {
          margin-right: calc(0 * 16px / 2);
        }
      }
    }

    .neo__search-page-link-block__section__block__title {
      font-size: 18px;
      font-weight: bold;
    }

    .neo__search-page-link-block__section__block__cta__list {
      flex-direction: column;
      justify-content: flex-start;

      .link {
        margin-left: 15px;
        margin-right: 0;
        margin-bottom: 9px;
        padding: 0;
        color: $black;
      }
    }
  }
}
