@keyframes slideInHighlight {
  0% {
    opacity: 0;
    position: relative;
    right: -400px;
  }

  60% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
    position: relative;
    right: 0;
  }
}

.product-highlight {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  .col-left {
    margin-bottom: 32px;

    @include tablet {
      width: 40%;
      padding-right: 32px;
      margin-bottom: 0;
    }
  }

  .col-right {
    @include tablet {
      width: 60%;
    }
  }

  .product-highlight__title {
    margin-top: 0;
  }

  .product-highlight__image {
    max-width: 100%;
    max-height: 400px;

    @include fullscreen {
      max-width: 800px;
    }

    &-container {
      width: 100%;
      position: relative;
      text-align: center;

      @include tablet {
        opacity: 0;
        right: -400px;

        &.animate {
          animation: slideInHighlight 0.8s 0.5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

.product-highlight__bullet-list {
  list-style: none;
  padding-left: 0;

  .bullet-list__item {
    padding-left: 16px;
    position: relative;

    &::before {
      position: absolute;
      content: "✓";
      color: $blue;
      left: 0;
    }
  }
}

.cta-download-link-blocks {
  display: flex;
  flex-wrap: wrap;
}

.product-highlight {
  .col-full {
    width: 100%;
    margin: 0;
    box-sizing: border-box;

    @include tablet {
      width: calc(50% - 20px);
      margin-right: 20px;
    }
  }

  .highlight-box {
    display: flex;
    flex-direction: column;

    .list-title {
      margin-bottom: 10px;
    }

    .downloads,
    .links {
      width: 100%;
      margin: 0;
      box-sizing: border-box;

      @include tablet {
        width: calc(50% - 20px);
        margin-right: 20px;
      }
    }

    .downloads-list,
    .cta-button-list {
      padding: 0;
      margin: 0;
      margin-bottom: 40px;

      li {
        list-style: none;
        margin-bottom: 10px;
        width: calc(100% - 20px);
        overflow: hidden;
      }
    }


    .downloads-list {
      li {
        a {
          height: 40px;
          padding: 0;
          width: 100%;
          font-size: 14px;
          font-weight: normal;
          color: $black;
          display: flex;
          align-items: center;
        }

        .download_icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          stroke: $black;
          fill: $black;
        }
      }
    }

    .cta-button-list {
      .cta__button {
        width: 100%;
      }
    }
  }
}