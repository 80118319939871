.block--two-columns-content{
.block__outer{
  grid-column: 2/14;
  & > *:last-child{
    margin-bottom: 0;
  }

}
  .container_inner{
    grid-column: 1/15;
    padding: 0;
  }
  .block__title{
    display: inline-block;
      @include mobile-only{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }
  }

  .header__inner,
  .block__inner{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-column: 2/14;
    justify-content: space-between;
    &:nth-of-type(even){
      @include tablet{
        flex-direction: row ;
      }
      .block__image{
        margin: 32px 0 0 0;
        @include tablet{
          margin: 32px 0 0 32px;
        }
      }
    }
    &:nth-of-type(odd){
      @include tablet{
        flex-direction: row-reverse;
      }
      .block__image{
        margin: 32px 0 0 0;
        @include tablet{
          margin: 32px 32px 0 0;
        }
      }
    }

    // Override alignment
    &.image--right{
      @include tablet{
        flex-direction: row !important;
      }
      .block__image{
        margin: 32px 0 0 0 !important;
        @include tablet{
          margin: 32px 0 0 32px !important;
        }
      }
    }
    &.image--left{
      @include tablet{
        flex-direction: row-reverse !important;
      }
      .block__image{
        margin: 32px 0 0 0 !important;
        @include tablet{
          margin: 32px 32px 0 0 !important;
        }
      }
    }
  }
  .header__inner{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 20px;
    @include tablet{
      justify-content: center;
      margin-bottom: 40px;
    }
  }
  .block__inner{
    margin-bottom: 20px;
    @include tablet{
      margin-bottom: 40px;
    }
  }

  .block__title{
    display: inline-block;
    @include mobile-only{
      flex-direction: column-reverse;
    }
  }

  .block__pre-title{
    color: $neo-blue;
    margin: 0 0 8px 0;
  }

  .block__cta__list{
    flex-direction: column;
  }

  .block__content,
  .block__image{
    width: 100%;
    display: flex;
    flex-direction: column;
    @include tablet{
      flex: 1;
    }
  }
  .block__content{
    @include tablet{
      min-height: 320px;
    }
  }
  .block__image{
    .image__container{
      @include tablet{
        height: 100%;
        position: absolute;
        max-height: 320px;
      }
    }
    .image{
      width: 100%;
      object-fit: cover;
    }
  }
}