
            @import "@/styles/__abstracts/abstracts.scss";
          

.l-section-contact-searcher {
  padding-top: 64px;
  padding-bottom: 92px;
}

.c-contact-searcher {
  @include container;

  &__content {
    grid-column: 2 / -2;
    text-align: center;
    margin-bottom: 56px;

    @include tablet {
      grid-column: 4 / -4;
    }
  }

  &__search-bar {
    grid-column: 3 / -3;
    display: flex;
    row-gap: 12px;
    flex-wrap: wrap;

    @include tablet {
      grid-column: 4 / -4;
      justify-content: center;
      column-gap: 16px;
    }

    @include desktop {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      grid-column: 4 / -4;
    }

    @include widescreen {
      grid-column: 5 / -5;
    }
  }

  &__search-bar-input {
    @include basic-reset;

    width: 100%;
    font-size: 18px;
    height: 48px;
    border-bottom: 2px solid #7a7a7a;
    background: #e0e2e5;
    padding-left: 12px;
    padding-right: 12px;

    &::placeholder {
      color: #9da2a8;
    }

    @include tablet {
      flex: 1 1 auto;
      height: 56px;
    }
  }

  &__search-bar-button {
    width: 100%;
    height: 48px;
    @include basic-reset;
    display: flex;
    justify-content: center;

    @include tablet {
      flex: 0 0 320px;
      cursor: pointer;
    }
  }
}
