.link-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  min-width: calc(33.333% - 12px);

  &:nth-of-type(1) {
    margin-right: 12px;
  }

  &:nth-of-type(2) {
    margin: 0 6px;
  }

  &:nth-of-type(3) {
    margin-left: 12px;
  }
}

.link-block__image-container {
  overflow: hidden;
  position: relative;
  width: 100%;

  .link-block__image {
    margin-right: 12px;
    transition: 200ms ease-in-out all;

    @include tablet {
      width: 100%;
    }
  }
}

.link-block__container {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.link-block__link {
  font-size: 24px;
  text-decoration: none;
  position: relative;
  color: $black;
  transition: 0.1s ease-in-out all;
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  // @include breakpoint(phoneSmall) {
  //   width: 50%;
  // }

  .link-block__image {
    width: 100%;
    display: block;
    height: 45vw;
    max-width: 368px;
    margin: 0 auto;

    background: {
      size: auto 200px;
      position: center center;
    }

    background-size: contain;
    object-fit: cover;
    background-repeat: no-repeat;
    object-fit: cover;

    @include tablet {
      max-height: 200px;
    }
  }

  .link-block__link-text {
    display: block;
    margin-top: 12px;
  }

  &:hover {
    color: $grey;
    transition: 0.1s ease-in-out all;

    .link-block__image {
      transition: 200ms ease-in-out all;
      transform: scale(1.07);
      position: relative;
    }
  }

  &-text {
    &::after {
      content: ">";
      color: $red;
      margin-left: 8px;
    }
  }
}
