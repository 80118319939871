.block--neo-steps-block {
  .block--header__container,
  .block--content {
    position: relative;
    grid-column: 2/14;
  }
  .block--content {
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 40px;
      z-index: -1;
      background: $neo-light-gray;
      width: 100vw;
      height: calc(100% - 40px);
      transform: translate(-50%);
    }
  }

  .block--header {
    max-width: 568px;
    margin: 0 auto;
    text-align: center;

    * {
      text-align: center;
    }

    .block--title {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      @include tablet {
        flex-direction: row;
        justify-content: center;
      }
    }

    .block--text {
      line-height: 1.75;
      font-size: 14px;
      padding-bottom: 64px;
    }
  }

  .block--content {
    position: relative;

    // Layout
    .block--content--inner {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      @include until-desktop {
        width: calc(100%);
      }

      &.col-3 {
        .block__item {
          @include desktop {
            flex: 1 1 calc(33% - 32px);
            max-width: calc(33% - 32px);
          }
        }
      }

      &.col-4 {
        .block__item {
          @include tablet {
            flex: 1 1 calc(50% - 32px);
            margin: 0 16px;
          }
          @include desktop {
            flex: 1 1 calc(25% - 32px);
            max-width: calc(25% - 32px);
          }
        }
      }
    }

    // Block items
    .block__item {
      width: 100%;
      background-color: $white;
      padding: 0;
      margin: 0;
      border: 2px solid $neo-dark-gray;
      background-image: linear-gradient($neo-blue, $neo-blue);
      background-repeat: no-repeat;
      background-position: center 100%;
      background-size: 100% 8px;
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      margin: 0;
      margin-bottom: 32px;
      @include desktop {
        margin: 0 16px;
        padding-bottom: 32px;
      }
      .block__item-image {
        height: 80px;
        margin: 16px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
          object-fit: contain;
        }
      }
      .block__item-content {
        display: flex;
        flex-direction: column;
        padding: 0 48px 42px;
        height: 100%;

        h3 {
          text-align: center;
          span {
            color: $neo-blue;
          }
        }

        div {
          text-align: center;
          * {
            text-align: center;
          }
        }
      }
    }

    // CTA list
    .block__links__bottom,
    .block__cta__list {
      list-style: none;
      margin: 0 -4px;
      width: calc(100% + 8px);
      max-width: calc(100% + 8px);
      display: flex;
      padding: 0 0 16px;
      margin-top: auto;

      li {
        width: max-content;
        max-width: 100%;
        padding: 0;
        margin: 16px auto 0;
        text-align: center;

        a {
          width: 100%;
          max-width: none;
          margin: 0;
          color: white;
          @include on-event {
            color: $black;
          }
        }
      }
    }
    .block__links__bottom{
      color: $neo-blue;
      padding: 0;
      *{
      color: $neo-blue;
      }
      @include on-event {
        color: $black;
      }
    }
  }
}
