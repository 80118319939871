.job-position-post {
    padding: 32px 0;

    .job-position-post-title {
        text-align: left;
    }

    .job-position-post-section {
        @include desktop {
            column-count: 2;
        }
    }
}