.news-overview-page,
.blog-overview-page {
  .neo__content-block {
    @include until-tablet {
      padding-bottom: 32px;
    }
  }
}

.neo__article-list-block__outer {
  position: relative;

  &:before {
    content: '';
    background: $neo-light-gray;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: -99;
  }

  .neo__article-list-block__blog-navigator__outer {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    border: solid 1px gray;

    .neo__article-list-block__blog-navigator__title {
      flex: 50 1 250px;
      font-size: 16px;
      font-weight: normal;
      color: $white;
      background: $neo-blue;
      padding: 20px 24px;

      h3 {
        color: inherit;
        font-size: inherit;
      }
    }

    .neo__article-list-block__blog-navigator__selector {
      flex: 1 1 200px;
      display: flex;
      align-items: center;
      justify-content: stretch;

      .neo__article-list-block__blog-navigator__date-toggle {
        padding: 20px 24px;
        cursor: pointer;
        flex: 1;
        background: $white;
        text-align: end;

        span {
          font-size: 16px;
          position: relative;
          display: inline-block;
          padding-right: 2em;

          .neo-epsilon {
            right: 5px;
            top: 50%;
            transform: translateY(calc(-50% - 2px)) rotate(135deg);
            position: absolute;
            display: inline-block;
            transition: transform .2s ease-in-out;

            &.active {
              transform: translateY(calc(-50% + 2px)) rotate(-45deg);
            }
          }
        }
      }
    }
  }

  .neo__article-list-block-items__outer {
    a {
      width: 100%;
      display: flex;
      text-decoration: none;
      overflow: hidden;
      margin: 16px 0;
      border: solid 1px $neo-dark-gray;
      flex-direction: column;
      @include tablet {
        flex-direction: row;
      }

      .neo__article-list-block-item__preview {
        position: relative;
        background-color: $neo-light-gray;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        min-width: 180px;
        height: 250px;
        @include tablet {
          flex: 0 0 18.3%;
        }
        @include desktop {
          flex: 0 0 22.5%;
        }
      }

      .neo__article-list-block-item__content {
        flex: 1;
        padding: 32px;
        background: $white;
        position: relative;

        .neo__article-list-block-item__title {
          font-size: 18px;
          font-weight: bold;
          color: $black;
        }

        .neo__article-list-block-item__boilerplate {
          margin: 8px 0;
          padding-bottom: 2em;
          color: $black;
          max-height: 80px;
          @include limit-lines(5);
        }

        .neo__article-list-block-item__date {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 32px;
          margin: 0;
          color: $neo-dark-gray;
        }
      }
    }
  }

  .neo__article-list-block-items__loadmore {
    display: flex;
    justify-content: center;

    .neo__article-list-block-items__loadmore-text {
      &::after {
        transform: translateY(-25%) rotate(90deg);
      }

      &:hover, &:focus {
        &::after {
          right: 10px;
          transform: translateY(-5%) rotate(90deg);
        }
      }
    }
  }

}