.block--neo-article-text {
  .block--outer {
    display: block;
    width: 100%;
    box-sizing: border-box;
    grid-column: 2/14;

    .block--content {
      flex-direction: column;
      &.align-center {
        text-align: center;
        margin: 0 auto;
        @include tablet {
          max-width: 80%;
        }

        li,
        blockquote {
          width: max-content;
          margin: 0 auto;
          max-width: 760px;
        }
      }

      .block--content--intro {
        .block--title {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          margin-bottom: 8px;
          @include tablet {
            flex-direction: row;
          }
        }
      }
    }

    .block__cta__list {
      list-style: none;
      margin-top: 32px;
      justify-content: flex-start;
      margin-left: 0;
      padding-left: 0;
    }
  }
}
