.full-width-section {
    width: 100%;
    padding: 32px 0;

    &--background {
        background-color: $lightgrey;
    }

    &--image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}