.go-back__btn {
	font-size: 14px;
	cursor: pointer;
	position: relative;
	display: block;
	padding-left: 30px;
	font-weight: normal;
	font-family: "BoschSans", $ff-sans-serif-fallback;
	color: $black;
	text-decoration: none;
	padding: 10px 30px;
	margin-bottom: 40px;

	&:after {
		position: absolute;
		left: -15px;
		top: 15px;
		content: "";
		display: inline-block;
		width: 8px;
		height: 8px;
		border-left: 2px solid $darkred;
		border-bottom: 2px solid $darkred;
		transform: rotate(45deg);
		margin-left: 20px;
	}
}

.text-content__header {
	margin-bottom: 20px;
	.title__details a {
		color: $black;
	}
}

.download-list-small {
	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
		margin-bottom: 8px;
	}

	.download-link {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		cursor: pointer;
		position: relative;
		font-weight: normal;
		font-family: "BoschSans", $ff-sans-serif-fallback;
		color: $black;
	}

	.download-preview {
		max-width: 30px;
		width: auto;
		height: 30px;
		margin-right: 10px;
		display: inline-block;
		overflow: hidden;

		img {
			margin: 0;
			padding: 0;
			height: 100%;
			width: auto;
			overflow: hidden;
		}
	}
}

.newsArticlePage {
	.go-back__btn {
		margin-top: 20px;
	}

	.product-properties__image {
		height: 100px;
		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;

		@include tablet {
			width: 100%;
			height: 315px;
		}
	}

	.news-author {
		&__outer {
			display: flex;
			margin: 60px 0;
			flex-direction: column-reverse;

			@include tablet {
				flex-direction: row;
				min-height: 250px;
			}
		}

		&__data {
			padding: 10px 20px;
			border-left: 5px solid #245e86;
			width: calc(100% - 25px);

			@include tablet {
				width: 50%;
			}
		}

		&__name {
			margin-bottom: 0;
			margin-top: 0;
			font-weight: 800;
			font-size: 1.4em;
		}

		&__title {
			color: grey;
			margin-top: 0;
		}

		&__image {
			width: 100%;
			display: flex;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			height: 300px;
			margin-bottom: 10px;

			@include tablet {
				width: 50%;
				height: auto;
				background-size: cover;
			}
		}
	}

	.downloads {
		margin-bottom: 50px;
	}

	.download-list {
		&__title {
			margin-bottom: 10px;
		}
	}

	.news-page-documents {
		&__outer {
			margin: 60px 0;
		}
	}

	.news-page-table {
		&__outer {
			background: #f1f1f1;
			overflow: scroll;

			p {
				margin: 0;
			}
		}
	}

	.news-page-table-row {
		&__outer {
			display: flex;
			padding: 0 10px;
			margin-top: 0;
			margin-bottom: 0;
			border-bottom: 1px solid white;
			width: 100%;

			&--head {
				background: #245e86;
				border-bottom: 0;

				p {
					color: white;
				}

				.news-page-table-row__item {
					background: #245e86;
				}
			}
		}

		&__downloads {
			display: flex;
			flex-direction: column;
		}

		&__download-icon {
			width: 15px;
			height: 15px;
			margin-right: 5px;
		}

		&__download {
			display: flex;
			align-items: center;
			margin-bottom: 7px;
			color: black;
		}

		&__item {
			min-width: 100px;
			padding: 10px;

			&--title {
				width: 60%;
				min-width: 200px;
			}

			&--date {
				width: 20%;
			}

			&--downloads {
				width: 20%;
			}
		}
	}
}
