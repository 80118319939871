.savings-product {
 .product-info {
    &__content {
        @include tablet {
            width: 60%;
        }
    }

    &__product {
        display: flex;

        @include mobile-only {
            flex-direction: column;
            background-image: url("#{ $base-url }gray-light-full-lg.svg");
            background-size: cover;
        }

        @include tablet {
            padding: 32px 40px;
        }

        .product-image {
            display: flex;
            align-items:center;
            justify-content: center;
            min-height: 170px;
            width: 100%;

            @include tablet {
                width: 170px;
                background-image: url("#{ $base-url }gray-light-full-lg.svg");
                background-size: cover;
            }
        }

        img{
            width: 100%;
            max-width: 120px;
        }

        .title {
            margin: 0;
            font-weight: normal;

            @include mobile-only {
                padding: 32px 24px;
            }

            @include tablet {
                margin: 4px 0 0 20px;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        padding: 24px;

        @include tablet {
            padding: 24px 40px;
            border-top: 3px solid $savingsBackground;
        }

        p {
            margin: 0;
        }

        .price {
            color: $hoverblue;
        }
    }
}
}
