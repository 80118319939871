
            @import "@/styles/__abstracts/abstracts.scss";
          

.c-dealersearch {
  grid-column: 2/ -2;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  iframe {
    width: 100%;
  }
}
