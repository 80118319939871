// Mobile menu
html,
* {
  position: relative;
  word-break: break-word;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: overlay;
  max-width: 100vw;

  display: grid;
  height: inherit;

  &.menu--open {
    height: 100vh;
    overflow: hidden;
  }

  .neo__body--content {
    padding-top: 80px;
    @include tablet{
      padding-top: 100px;
    }
    @include desktop {
      padding-top: 180px;
    }
  }
}

// SEO
.seo-only,
.just-for-seo {
  display: none;
}

// PRO - Label
.pro-label {
  color: #2a81b6;
  background: #d1e4ff;
  padding: 0.25em 0.75em;
  margin: 0.5em;
  font-size: 0.8em;
  font-size: 0.7em;
  text-shadow: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
}

// Iframe login ( ? )
.iframe-login {
  margin-bottom: 80px;
  height: 360px;
  @include tablet {
    height: 220px;
  }
}

// scroll anchor product pages
#algemene_informatie,
#details,
#technische_specificaties,
#downloads,
#voorbeelden {
  scroll-margin-top: 170px;
}
