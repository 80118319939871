.pos-rel {
	position: relative;
}

.text-center {
	text-align: center;

	@include tablet {
		text-align: inherit;
	}
}

.text-left {
	text-align: left;
}

.m-b {
	&-32 {
		margin-bottom: 32px !important;
	}

	&-64 {
		margin-bottom: 64px !important;
	}
}

.m-y {
	&-0 {
		margin: 0 !important;
	}

	&-16 {
		margin: 16px 0;
	}

	&64 {
		margin: auto;

		@include tablet {
			margin: 32px 0;
		}
	}

	&-48 {
		margin: auto;

		@include tablet {
			margin: 48px 0;
		}
	}

	&-64 {
		margin: auto;

		@include tablet {
			margin: 64px 0;
		}
	}
}

.m-x-2 {
	margin: 0 32px;
}

.p-all {
	&-0 {
		padding: 0 !important;
	}

	&-8 {
		padding: 8px !important;
	}

	&-16 {
		padding: 16px !important;
	}

	&-24 {
		padding: 24px !important;
	}
}

.p-t {
	&-0 {
		padding: auto;

		@include tablet {
			padding-right: 0px !important;
		}
	}

	&-16 {
		padding: auto;

		@include tablet {
			padding-right: 16px;
		}
	}

	&-32 {
		padding: auto;

		@include tablet {
			padding-right: 32px;
		}
	}

	&-48 {
		padding: auto;

		@include tablet {
			padding-right: 48px;
		}
	}

	&-64 {
		padding: auto;

		@include tablet {
			padding-right: 64px;
		}
	}
}

.p-r {
	&-0 {
		padding: auto;

		@include tablet {
			padding-right: 0px !important;
		}
	}

	&-16 {
		padding: auto;

		@include tablet {
			padding-right: 16px;
		}
	}

	&-32 {
		padding: auto;
		
		@include tablet {
			padding-right: 32px;
		}
	}

	&-48 {
		padding: auto;
		
		@include tablet {
			padding-right: 48px;
		}
	}

	&-64 {
		padding: auto;

		@include tablet {
			padding-right: 64px;
		}
	}
}

.p-y {
	&-16 {
		padding: auto;
		
		@include tablet {
			padding: 16px 0;
		}
	}

	&-32 {
		padding: auto;
		
		@include tablet {
			padding: 32px 0;
		}
	}

	&-48 {
		padding: auto;
		
		@include tablet {
			padding: 48px 0;
		}
	}

	&-64 {
		padding: auto;
		
		@include tablet {
			padding: 64px 0;
		}
	}
}

.p-x {
	&-16 {
		padding: auto;
		
		@include tablet {
			padding: 0 16px;
		}
	}

	&-32 {
		padding: auto;
		
		@include tablet {
			padding: 0 32px;
		}
	}

	&-48 {
		padding: auto;
		
		@include tablet {
			padding: 0 48px;
		}
	}

	&-64 {
		padding: auto;
		
		@include tablet {
			padding: 0 64px;
		}
	}
}

.text-col {
	&-2 {
		@include tablet {
			column-count: 2;
		}
	}
}

.text-content {
	figure {
		margin-left: 0;
		margin-right: 0;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.text-content {
	ul {
		list-style: none;
		padding-left: 0;

		li {
			padding-left: 16px;
			position: relative;
			margin-bottom: 16px;
			color: $black;
			line-height: 1.6;

			&::before {
				position: absolute;
				left: 0;
				content: "✓";
				color: $blue;
			}
		}
	}
}

.max-width {
	&-500 {
		@include tablet {
			max-width: 500px;
		}
	}

	&-550 {
		@include tablet {
			max-width: 550px;
		}
	}

	&-600 {
		@include tablet {
			max-width: 600px;
		}
	}

	&-650 {
		@include tablet {
			max-width: 650px;
		}
	}
}

.contact-section {
	display: flex;
	justify-content: space-between;

	.contact-address {
		font-style: normal;

		strong {
			font-size: 16px;
		}
	}
}

.border {
	border: 1px solid $lightgrey;
}
