a {
  position: relative;
  color: $hoverblue;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.5s;
  text-decoration: underline;
  @include on-event {
    outline: none;
    color: $hoverblue;
  }
}

.cta,
.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 5px 10px 5px 0px;
  height: auto;
  width: auto;
  color: $black;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px 12px 0 12px;
  transition: all 0.5s;
  text-decoration: underline;
  &:after {
    content: none;
    display: none;
    transition: 200ms ease-in-out all;
  }

  .pro-label {
    text-decoration: none;
  }

  @include on-event {
    outline: none;
    color: $hoverblue;
    &:before {
      left: 6px;
    }
    &:after {
      right: 6px;
    }
  }

  // Custom Link styling
  &.blue {
    color: $hoverblue;
  }

  &.blue-hover {
    @include on-event {
      color: $neo-blue;
    }
  }

  &--full {
    display: block;
    text-align: left;
    padding: 4px;
    margin: 0 -4px;
    width: calc(100% + 8px);
  }

  &--hover-underline {
    text-decoration: none;
    @include on-event {
      text-decoration: underline;
    }
  }
}

.btn,
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  padding: 12px 18px 12px 18px;
  text-decoration: none;
  color: $neo-btn-text-color;
  background: $bosch-blue;
  cursor: pointer;
  width: auto;
  outline: none;
  font-size: 16px;
  @include desktop {
    max-width: 300px;
  }
  @include on-event {
    outline: none;
    color: white;
    background: $bosch-blue-hover;
    &:before {
      left: 6px;
    }
    &:after {
      right: 6px;
    }
  }

  // Custom: Button types
  &.full {
    width: 100%;
    max-width: none;
  }
  &.btn-alt,
  &.button-alt {
    background: $bosch-blue;
    @include on-event {
      background: $bosch-blue-hover;
    }
  }
}

// Custom: Styling
.cta,
.link,
.btn,
.button {
  &.mobile {
    @include desktop {
      display: none;
    }
  }
  &.tablet {
    display: none;
    @include tablet {
      display: inline-flex;
    }
  }
  &.desktop {
    @include until-desktop {
      display: none;
    }
  }
}

// Custom: Icons
.cta,
.link,
.btn,
.button {
  svg {
    fill: currentColor;
    margin-right: 8px;
  }
  // Icon right
  &.icon--next,
  &.icon--nextblue,
  &.icon--search {
    padding-right: 36px !important;
    &:after {
      content: "";
    }
  }
  // Icon left
  &.icon--back,
  &.icon--backblack,
  &.icon--upload,
  &.icon--download,
  &.icon--downloadblue {
    padding-left: 36px !important;
    &:before {
      content: "";
    }
  }
  // Icon overwrite
  &.no-icon {
    padding-left: 0 !important;
  }

  &.no-icon::before,
  &.no-icon::after {
    background: none;
  }
  &.icon--next::before,
  &.icon--next::after {
    background-image: url("#{ $base-url }icons/epsilon_white.svg");
  }
  &.icon--nextblue::before,
  &.icon--nextblue::after {
    background-image: url("#{ $base-url }icons/epsilon_blue.svg");
  }
  &.icon--back::before,
  &.icon--back::after {
    background-image: url("#{ $base-url }icons/back.svg");
    transform: translateY(-50%) rotate(180deg);
  }
  &.icon--backblack::before,
  &.icon--backblack::after {
    background-image: url("#{ $base-url }icons/back-black.svg");
    transform: translateY(-50%) rotate(180deg);
  }
  &.icon--upload::before,
  &.icon--upload::after {
    background-image: url("#{ $base-url }neo/icons/file-upload.svg");
  }
  &.icon--download::before,
  &.icon--download::after {
    background-image: url("#{ $base-url }icons/download_white.svg");
  }
  &.icon--downloadblue::before,
  &.icon--downloadblue::after {
    background-image: url("#{ $base-url }icons/download_blue.svg");
  }
  &.icon--search::before,
  &.icon--search::after {
    background-image: url("#{ $base-url }neo/icons/search-white.png");
  }
  &.icon--external::before,
  &.icon--external::after {
    background-image: url("#{ $base-url }neo/icons/external-link.svg");
  }

  &:after,
  &:before {
    color: inherit;
    background-image: url("#{ $base-url }icons/epsilon_white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    width: 16px;
    height: 16px;
    display: block;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out all;
  }
  &:before {
    left: 10px;
  }
  &:after {
    right: 10px;
  }
  &.icon--search::after {
    right: 16px;
  }
}

.btn {
  &--red {
    background: $red;
    transition: all 0.5s;

    @include on-event {
      background: $darkred;
    }

    &:disabled {
      background: $lightgrey;
      cursor: not-allowed;
      color: $grey;
    }
  }

  &--full {
    width: 100%;
    max-width: none;
  }
}
