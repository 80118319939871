
            @import "@/styles/__abstracts/abstracts.scss";
          

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;

  & + & {
    position: absolute;
    top: 0;
    /*left: 0;*/
  }
}

.fade-enter-active {
  transform: translate(-100%);
}

.fade-leave-active + .fade-enter-active {
  transform: translate(100%);
}

.fade-enter-to {
  transform: none !important;
}

.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  /*opacity: 0;*/
  transform: translateX(-100%);
}

.fade-enter-to + .fade-leave-to {
  transform: translate(100%);
}
