.block--instruction-block {
  .block__outer {
    grid-column: 2/14;

    .block__inner {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      @include tablet {
        max-width: 780px;
        margin: 0 auto;
        padding: 64px 14px;
      }

      .block__title {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        @include tablet {
          flex-direction: row;
          justify-content: center;
        }

        &--container {
          display: flex;
          flex-direction: row;
          align-items: center;
          @include mobile-only {
            text-align: center;
            padding: 0 5px;
          }

          &--icon {
            display: block;
            @include tablet {
              margin: 0 16px;
            }

            figure {
              padding: 0;

              svg,
              g {
                fill: black;
              }
            }
          }
        }
      }
    }
  }
}
