.download-section {
  margin-top: 32px;
}

.download-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  grid-template-columns: repeat(auto-fill, 170px);
  grid-gap: 10px;
  flex-wrap: wrap;

  .download-name {
    display: inline-block;
    font-size: 12px;
    min-height: 2.5em;
  }

  .download-preview {
    display: block;
    height: 168px;
    margin: 16px 0;
  }

  .download-link {
    color: $black;

    &:hover {
      color: $red;
    }
  }

  li {
    margin-right: 60px;
  }
}
