.neo__featured-product-category__outer {
  grid-column: 2/14;
  .neo__featured-product-category__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    padding-left: 0;
  }
}

.product-category {
  width: 100%;
  padding: 0;
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include desktop {
    margin-bottom: 120px;
  }

  &:not(:first-child) {
    padding-top: 45px;
  }

  @include desktop {
    height: 100%;
    @include flex-width(50%, 16px);
    &:not(:first-child) {
      padding-top: 0;
    }
  }

  h4 {
    padding: 24px 0;
  }

  .product-category__product__cta-list {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    padding-left: 0;
    width: 100%;
    list-style: none;

    li {
      width: calc(100% / 2 - 16px);
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      min-width: 120px;
      padding: 0;
      a {
        padding: 0;
      }
    }
  }
}

.product-category__details {
  width: 100%;
  padding: 24px;
  background: $white;
  border: solid 1px $neo-dark-gray;
  display: flex;
  justify-content: space-between;
  min-height: 336px;
  flex-direction: column;
  margin-bottom: 32px;

  @include desktop {
    height: unset;
  }
  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
    .title {
      display: block;
      width: 100%;
      text-align: left;
    }
  }

  .title {
    order: 2;
    @include desktop {
      order: 1;
    }
  }
  .product-category__details__text {
    order: 3;
    @include desktop {
      order: 2;
    }
  }
  .product-category__image {
    order: 1;
    @include desktop {
      order: 3;
    }
  }
}

.product-category__details__text {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include desktop {
    .neo__wysiwyg {
      min-height: 260px;
      height: 250px;
      max-height: 260px;
      overflow: hidden;
      @include limit-lines(8);
    }
  }
  @include desktop {
    @include flex-width(50%, 16px);
  }

  div {
    flex: 1;

    *,
    & {
      line-height: 1.75;
    }
  }

  * {
    width: 100%;
  }

  .product-category__details__text__cta-list {
    margin: 0;
    padding: 0;
    width: calc(100% + 12px);
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    margin-top: 16px;
    list-style: none;

    li {
      padding: 0;
    }
  }
}

.product-category__image {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  @include desktop {
    @include flex-width(50%, 16px);
    padding-top: 32px;
  }

  .image {
    object-fit: contain;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    height: auto;
  }
}
