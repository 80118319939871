$component: "neo-compare-table";

.block--neo-compare-table {
  .block--outer {
    grid-column: 2/14;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
  }
  .block__content {
    width: 100%;
  }
  .block__title {
    text-align: center;
  }
  .block__subtitle {
    text-align: center;
    max-width: 548px;
    margin: 0 auto 64px;
  }
}

.#{$component} {
  &__table {
    background: $lightgrey;
    font-family: $ff-bosch-sans;
  }
  &__table-wrapper {
    overflow-x: scroll;
    position: relative;
    width: 100%;
  }
  &__table-image-row {
    height: 207px;
    width: 205px;
    position: relative;
    border-left: 1px solid white;
    border-right: 1px solid white;
    &:hover {
      img {
        transform: none;
      }
    }
    &:first-of-type {
      text-align: left;
      position: sticky;
      left: 0;
      width: 410px;
      z-index: 5;
    }
    & > img {
      height: 130px;
      width: 100%;
      background: transparent;
      box-shadow: none;
      transform: none;
      transition: none;
      object-fit: contain;
      &:hover {
        transform: none;
      }
    }
  }
  &__table-header {
    background-color: #d5e3fc;
    color: $hoverblue;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    height: 88px;
    text-align: center;
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
    & > tr:first-of-type > th {
      background-color: $lightgrey;
    }
  }
  &__table-header-item {
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
    color: $hoverblue;
    padding: 24px;
    &:first-of-type {
      text-align: left;
      position: sticky;
      left: 0;
      width: 410px;
      z-index: 5;
    }
  }
  &__table-body {
    tr:nth-child(4n + 2) th,
    tr:nth-child(4n + 2) td {
      background-color: white;
    }
    tr:nth-child(4n + 4) th,
    tr:nth-child(4n + 4) td {
      background-color: $lightgrey;
    }
  }
  &__table-body-item {
    font-family: $ff-bosch-sans-bold;
    color: $hoverblue;
    font-size: 16px;
    line-height: 20px;
    padding: 24px;
    width: 205px;
    border-left: 0.5px solid #e6e6e6;
    border-right: 0.5px solid #e6e6e6;
    &:last-of-type {
      border-right: 0;
    }
  }
  &__table-body-header {
    font-family: $ff-bosch-sans;
    font-size: 16px;
    line-height: 20px;
    padding: 24px;
    font-weight: normal;
    text-align: left;
    color: inherit;
    border-left: 0;
    border-right: 0.5px solid #e6e6e6;
    position: sticky;
    left: 0;
    width: 410px;
    z-index: 5;
  }
  &__flex-item {
    display: flex;
    justify-content: center;
    &--bullet {
      gap: 8px;
    }
  }
  &__new-label-container {
    height: 25px;
    width: 100%;
    margin: 16px auto 16px;
  }
  &__new-label {
    background-color: white;
    border: 1px solid $hoverblue;
    border-radius: 24px;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 15px;
    width: fit-content;
    margin: 0 auto;
    span {
      font-weight: normal;
    }
  }
  &__table-col {
    color: $hoverblue;
    font-weight: bold;
    flex: 1 0 212px;
    &:last-of-type {
      .#{$component} {
        &__table-title-row {
          border-right: 0;
        }
        &__table-item-row {
          border-right: 0;
        }
        &__table-image-row {
          border-right: 0;
        }
      }
    }
    &--heading {
      flex: 2 1 410px;
      color: inherit;
      max-width: 410px;
      font-weight: inherit;
      .#{$component} {
        &__table-title-row {
          padding-left: 24px;
          justify-content: left;
          text-align: left;
          border-left: 0;
        }
        &__table-item-row {
          justify-content: left;
          border-left: 0;
        }
        &__table-image-row {
          border-left: 0;
        }
      }
    }
  }
  &__product-container {
    display: flex;
    overflow-x: scroll;
    flex: 1 1 auto;
  }
  &__bullet {
    display: block;
    width: 16px;
    height: 16px;
    background-color: $hoverblue;
    border-radius: 50%;
  }
  &__table-footnotes {
    display: flex;
    margin-top: 16px;
    margin-left: 24px;
    color: $grey;
    gap: 37px;
    font-size: 16px;
    line-height: 20px;
  }
  &__table-body-header-container {
    display: table-row;
    &--mobile {
      display: none;
    }
  }
  @media screen and (max-width: 1023px) {
    &__table-body {
      tr:nth-child(4n + 2) th,
      tr:nth-child(4n + 2) td {
        background-color: white;
      }
      tr:nth-child(4n + 4) th,
      tr:nth-child(4n + 4) td {
        background-color: white;
      }
    }
    &__table-header-item {
      text-align: left;
      font-size: 14px;
      border: 0px;
      padding: 16px 0 16px 32px;
      width: 240px;
      &:first-of-type {
        display: none;
      }
    }
    &__table-image-row {
      background-color: white !important;
      border: 0px;
      text-align: center;
      padding: 8px 32px;
      height: 110px;
      & > img {
        height: auto;
      }
      &:first-of-type {
        display: none;
      }
    }
    &__table-body-header-container {
      &--mobile {
        display: table-row;
        .#{$component} {
          &__table-body-header {
            display: table-cell;
            background-color: $lightgrey;
            text-align: left;
            padding: 11px 0 11px 32px;
            font-size: 14px;
            -webkit-text-size-adjust: 100%;
            span {
              position: sticky;
              left: 32px;
              width: 100%;
              z-index: 5;
              font-size: 14px;
            }
          }
        }
      }
    }
    &__table-body-header {
      display: none;
    }
    &__table-body-item {
      text-align: left;
      padding: 16px 0 16px 32px;
      border: 0;
      font-size: 14px;
    }
    &__flex-item {
      justify-content: start;
      align-items: baseline;
    }
    &__bullet {
      width: 12px;
      height: 12px;
    }
    &__new-label-container {
      position: absolute;
      bottom: 8px;
      z-index: 5;
      left: 50%;
      margin: 0;
      transform: translateX(-50%);
    }
  }
}
