.neo__matching-articles {
  .neo__matching-articles__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 4px solid #00608b;
    padding: 16px 0 16px 16px;
    margin-bottom: 32px;
  }

  .neo__matching-articles__title {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
  }

  .neo__matching-articles__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .neo__matching-articles__link {
    width: 100%;
    display: flex;
    text-decoration: none;
    border-radius: 4px;
    overflow: hidden;
    margin: 16px 0;
    border: solid 1px $neo-dark-gray;
    flex-direction: column;
    
    @include tablet {
      flex-direction: row;
    }

    &:hover, &:focus {
      .neo__matching-articles__preview {
        transform: scale(1.2);
      }
    }

    .neo__matching-articles__preview__container {
      min-width: 180px;
      height: 250px;
      overflow: hidden;

      @include tablet {
        flex: 0 0 18.3%;
      }
      @include desktop {
        flex: 0 0 22.5%;
      }
    }

    .neo__matching-articles__preview {
      position: relative;
      background-color: $neo-light-gray;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      min-width: 180px;
      height: 250px;
      transition: all 0.5s;

      @include tablet {
        flex: 0 0 18.3%;
      }
      @include desktop {
        flex: 0 0 22.5%;
      }
    }

    .neo__matching-articles__content {
      flex: 1;
      padding: 32px;
      background: $white;
      position: relative;

      .neo__matching-articles__title {
        font-size: 18px;
        font-weight: bold;
        color: $black;
      }

      .neo__matching-articles__description {
        margin: 8px 0;
        padding-bottom: 2em;
        color: $black;
      }

      .neo__matching-articles__date {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 32px;
        margin: 0;
        color: $neo-dark-gray;
      }
    }
  }
}