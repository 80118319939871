.neo__latest-three-articles__outer {
  .neo__latest-three-articles__title {
    margin-bottom: 30px;
    margin-top: 0;
    font-weight: 500;
    text-align: center;
    font-size: 48px;
    font-weight: normal;
  }

  .neo__latest-three-articles__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    li {
      border: solid 1px $neo-light-gray;
      flex: 0 0 100%;
      margin-right: 0;
      overflow: hidden;
      padding: 0;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include tablet {
        margin-bottom: 0;
        @include flex-width(calc(100% / 3), 22px);
        margin-right: 32px;
        box-sizing: border-box;
        &:nth-of-type(2) {
          margin-right: 32px;
        }
        &:nth-of-type(3) {
          display: block;
          margin-right: 0;
        }
      }
    }
  }

  .neo__latest-three-articles__list-link {
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;

    .neo__latest-three-articles__image__container {
      display: block;
      position: relative;
      height: 250px;
      overflow: hidden;
      @include tablet {
        height: 200px;
      }
      @include desktop {
        height: 250px;
      }
    }

    .neo__latest-three-articles__image {
      position: relative;
      background-color: $neo-light-gray;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all 0.5s;
      width: auto;
      height: 250px;
      overflow: hidden;
      @include tablet {
        height: 200px;
      }
      @include desktop {
        height: 250px;
      }
    }

    &:hover,
    &:focus {
      .neo__latest-three-articles__image {
        transform: scale(1.2);

        img {
          transform: scale(calc(0.8));
        }
      }
    }

    .neo__related-articles-block-image__author-bg {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 100%
      );
    }

    img {
      transition: all 0.5s;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .neo__latest-three-articles__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 32px;

    .neo__latest-three-articles__content-title {
      display: block;
      font-size: 18px;
      font-weight: bold;
      color: black;
      margin-bottom: 0;
    }

    .neo__latest-three-articles__content-description {
      margin: 8px 0;
      padding-bottom: 2em;
      color: #000;
      height: 80px;
      @include limit-lines(5);
      overflow: hidden;
    }

    .neo__latest-three-articles__content-date {
      font-size: 12px;
      margin: 0;
      padding: 8px 16px;
      color: $neo-date-text-color;
      text-align: right;
      padding: 0;
      @include desktop {
        text-align: left;
      }
    }
  }
}
