.block--neo-video-embed {
  .block__header,
  .block__content {
    grid-column: 2/14;
    display: flex;
    flex-direction: column;
    align-items: center;
    .block--description {
      margin: 0;
    }
  }

  .block--title {
    font-weight: normal;
    display: inline-block;
    font-family: $ff-bosch-sans;
    @include mobile-only {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }
  }
  .block--links {
    list-style: none;
  }

  .iframe__container,
  .video__container {
    margin-top: 16px;
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 16px auto 0;
    height: 0;
    padding-top: 56.5%;
    position: relative;
    @include desktop {
      width: 800px;
      padding-top: 0;
      height: calc(800px * 0.565);
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
