.ui__content-tabs {
  .ui__header {
  }

  .ui__controls__container {
    background: $neo-light-gray;
    margin-bottom: -1px;
    padding: 0;
  }
  .ui__controls {
    grid-column: 2/14;
    .ui__controls__btn {
      background: rgba(255, 255, 255, 0);
      padding: 24px;
      border: none;
      font-size: 16px;
      color: black;
      cursor: pointer;
      transition: all 0.3s;
      font-family: $ff-bosch-sans;
      @include on-event {
        background: rgba(255, 255, 255, 0.9);
      }
      &.control--active {
        background: white;
      }
    }
  }
  .ui__content {
  }
}
