.block--neo-product-details-block{
  .block__outer{
    display: flex;
    flex-direction: column;
    grid-column: 2/14;
    width: 100%;
    @include desktop{
      flex-direction: row;
      justify-content: space-between;
    }

    .block__content{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      @include desktop{
        flex: 1;
        margin-bottom: 0;
      }
    }
    .partials__neo-product-details{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include desktop{
        flex: 1;
        margin-left: 30px;
        flex-direction: row;
        max-height: 768px;
      }
      @include widescreen{
        flex: 2;
      }
    }
    .product-details__image--big{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f1f1f1;
      margin-bottom: 32px;
      padding: 20px;
      height: 100%;
      @include desktop{
        margin-bottom: 0;
        margin-right: 32px;
      }
      .image{
        object-fit: contain;
      }
    }
    .product-details__preview{
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0;
      list-style: none;
      flex-wrap: wrap;
      @include desktop{
        flex-wrap: nowrap;
        flex-direction: column;
        width: auto;
      }
      .preview__item{
        height: 76px;
        background-color: #f1f1f1;
        width: 25%;
        transition: all 0.3s;
        @include desktop{
          height: 168px;
          width: 168px;
          flex: 1;
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
        &.item--active{
          border: $black solid 1px;
        }
        button{
          padding: 15px;
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          border-radius: 0;
        }
        &.item--hidden{
          display: none;
          flex: 0;
          opacity: 0;
          height: 0;
          margin: 0;
        }
        &.preview__controlls{
          height: calc(76px / 2);
          display: none;
          @include desktop{ 
            display: block;
            flex: 1;
          }
        }
      }
    }
    .vind-a-dealer-link{
      margin-top: 16px;
    }
  }
}