.leaflet-marker-icon {
  z-index: unset !important;
}

.tooltip-content {
  font-size: 18px;

  .seperator {
    margin: 0 6px;
  }

  .tooltip-counter {
    color: $neo-cherry-red;
    font-weight: bold;
  }
}