.neo_banner {
  grid-column: 2/14;
  &.neo_banner-big {
    margin-top: 80px;

    @include tablet {
      margin-bottom: 80px;
    }
  }

  .neo__content-block {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .neo_banner-content-above {
    margin-bottom: 18px;
    .neo_banner-content-above-title {
      padding: 24px 0;
      font-size: 32px;
      line-height: 1.25;

      + .neo_banner-content-above-text {
        margin-top: -32px;
      }
    }
  }

  .neo_banner-inner {
    min-height: 450px;
    height: max-content;
    overflow: hidden;
    width: 100%;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      background-color: transparent;
    }

    &.neo_banner_inner_has_content {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:after {
        background-image: linear-gradient(
          to right,
          rgba($neo-light-gray, 0.3),
          transparent
        );
      }
      &.neo_banner_inner_only_button {
        justify-content: flex-end;
      }
    }

    .neo__banner_image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-position: center;
      background-size: cover;
    }

    .neo_banner-inner-content {
      padding: 64px 0;
      z-index: 1;
      width: calc(100% - 64px);
      @include until-tablet {
        width: 100%;
      }

      &.neo_banner-inner-content-spaced {
        padding: 32px;
      }

      *:not(.neo__cta) {
        text-shadow: 0 0 16px rgba($neo-light-gray, 0.7),
          0 0 8px rgba($neo-light-gray, 0.5);
        @include desktop {
          max-width: 400px;
        }
      }


      h4 {
        font-weight: bold;
        font-size: 32px;
      }
      .neo__wysiwyg {
        * {
          font-size: 24px;
          line-height: 1.63;
        }
        p {
          margin-top: 0;
        }
      }

      h4 + .neo__cta,
      .neo__wysiwyg + .neo__cta {
        margin-top: 16px;
      }

      .neo__cta {
        margin: 0;
      }
    }
  }
}

.neo__header__inner {
  .neo__brandbar {
    padding: 0;
  }
}

.neo__header__banner-block__banner {
  .neo__banner_image {
    padding: 0 !important;
  }
}
