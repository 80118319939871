@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.hero__image {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  height: 100%;
  
  &--animated {
    animation: zoom 30s 1;
  }

  .image {
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.hero__image-single {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  
  @include list-animation(
    hero-inner,
    0.4s,
    0.5s,
    ((opacity, 0, 1), (transform, translateX(-50px), translateX(0)))
  );
}
.hero__outer {
  height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: $lightgrey;
  z-index: 0;
  
  .hero__content {
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    backface-visibility: hidden;
    
    .hero__inner {
      display: flex;
      justify-content: space-between;
      height: 100%;
      position: relative;
      
      .hero-inner {
        &__right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }
  }
}

.hero__cta-extra {
  max-width: 300px;
  max-width: 300px;
  display: none;
  margin-bottom: 32px;

  @include tablet {
    display: block;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto;
    max-height: 300px;
    max-width: 300px;
  }

  &--big {
    width: 500px;
    img {
      max-width: 500px;
      max-height: 500px;
    }
  }

  &.large {
    width: 40vw;
    max-width: 500px;
    max-height: 500px;
    img {
      max-width: 500px;
      max-height: 500px;
    }
  }
  &.center {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

.hero__cta {
  margin-top: 150px;
  color: white;

  @include tablet {
    margin-top: 64px;
  }

  .cta__header {
    color: $white;
    margin: 0;
    font-weight: bold;
    font-size: 34px;
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    max-width: 100vw;
    word-break: break-word;

    @include tablet {
      font-size: 36px;
      max-width: 60vw;
    }

    @include mq(1550px) {
      font-size: 48px;
      max-width: 46vw;
    }

    @include widescreen {
      max-width: 40vw;
    }
  }

  .cta__body {
    color: $white;
    margin-top: 12px;
    max-width: 400px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
    > * {
      color: $white;
      margin-top: 12px;
      max-width: 400px;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
      font-weight: normal;
      font-size: 24px;
      font-weight: normal;
      font-size: 24px;
    }
  }
}
.hero__spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.fade-enter-active,
.fade-leave-active {
  transition: 400ms all ease-in-out;
}

.fade-enter-active {
  transition-delay: 0s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.hero__outer {
  .mobile-source {
    display: block;
    @include tablet {
      display: none;
    }
  }
  .desktop-source {
    display: none;
    @include tablet {
      display: block;
    }
  }
}

.hero__outer {
  .mobile-source {
    display: block;
    @include tablet {
      display: none;
    }
  }
  .desktop-source {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
