.dropdown-hero {
  .dropdown-hero__outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $hero-pro-gradient;
    color: $white;
    .dropdown-navigation__inner {
      grid-column: 2/14;
    }
    .block--content {
      padding: 107px 14px 100px 14px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      max-width: 860px;
      justify-content: center;
      align-items: center;
      text-align: center;

      .hero--label {
        font-size: 12px;
        background: $white;
        color: #005691;
        padding: 3px 12px;
        width: fit-content;
        margin-bottom: 16px;
      }

      .hero--title {
        width: 100%;
        font-size: 42px;
        padding: 16px 0 21px 0;
        margin: 0;
        color: $white;
        @include tablet {
          width: 569px;
        }
      }

      .hero--subtitle {
        width: 90%;
        font-size: 18px;
        margin: 0;
        @include tablet {
          width: 656px;
        }

        * {
          color: $white;
        }
      }
    }

    .dropdown-link-navigation__outer {
      padding: 24px 32px 32px;
      background: white;
      margin-top: 48px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .dropdown-link-navigation__title {
        font-weight: bold;
        font-family: $ff-bosch-sans;
        font-size: 16px;
        margin-bottom: 18px;
        color: black;
      }
      .dropdown-link-navigation__options {
        width: 100%;
        padding: 18px 28px;
        border-radius: 0;
        border: 1px solid black;
        font-size: 16px;
        color: $neo-blue;
        background: white;
        text-align: center;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;

        background: transparent;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 0;
        background-size: 20px 100%;
      }
    }
  }
}
