.neo__usp-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column: 2/14;

  // Intro
  .neo__usp__intro {
    max-width: 568px;
    margin-bottom: 32px;
    text-align: center;
  }

  .neo__usp-title {
    text-align: center;
    flex: 0 0 100%;
    padding-bottom: 32px;
  }

  .neo__usp-subtitle {
    text-align: center;
    flex: 0 0 100%;
    padding-bottom: 80px;
    @include tablet {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .neo__usp-ups-holder {
    list-style: none;
    padding: 0;
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include until-desktop {
      padding: 0 75px;
    }
    @include until-tablet {
      padding: 0;
    }
    .neo__usp-ups-point {
      flex: 0 0 100%;
      margin: 0;
      padding: 0;
      max-width: unset;
      margin-bottom: 32px;
      overflow: hidden;
      @include tablet {
        margin: 0 16px;
        margin-bottom: 32px;
        padding-bottom: 32px;
      }
    }
    .neo__usp-ups-image {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 150px;
      height: 150px;
      position: relative;
      overflow: hidden;
      margin: 0 auto 16px auto;
      padding: 0;
      box-sizing: border-box;
      @include desktop {
        width: 210px;
        height: 210px;
      }
      .image {
        object-fit: contain;
      }
      &.rounded {
        img {
          border-radius: 50%;
        }
      }
    }
    &.col-3 {
      .neo__usp-ups-point {
        @include tablet {
          flex: 1 1 calc(33% - 32px);
          max-width: calc(33% - 32px);
        }
      }
    }
    &.col-4 {
      .neo__usp-ups-point {
        @include tablet {
          flex: 1 1 calc(50% - 32px);
          max-width: calc(50% - 32px);
        }
        @include desktop {
          flex: 1 1 calc(25% - 32px);
          max-width: calc(25% - 32px);
        }
      }
    }

    .neo__usp-ups-point {
      &.hidden {
        height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        min-width: unset;
        min-height: unset;
      }

      .neo__usp-ups-point__inner {
        max-width: 400px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;

        * {
          text-align: center;
        }
        .neo__cta {
          margin-top: 16px;
          margin-left: auto;
          margin-right: auto;
          &:first-of-type {
            margin-top: 32px;
          }
        }
      }

      .neo__usp-ups-point-title {
        font-size: 16px;
        font-weight: bold;
      }

      .neo__usp-ups-point-text {
        padding: 0 4px;
        font-size: 16px !important;
        padding-top: 0.2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        line-height: 1.7;
        * {
          width: 100%;
        }
      }
    }
  }
}
