.product-category-switch {
  text-align: center;
  margin: auto;
  display: inline-block;
  border-bottom: 1px solid #e2e2e2;
  padding: 25px 0;
  width: 100%;

  .product-category-button {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 125px;
    opacity: .5;
    font-size: 14px;
    line-height: 1.14;
    text-decoration: none;
    color: $grey;
    transition: 0.2s opacity;
    img {
      display: block;
      margin: 15px auto;
      width: 63px;
      height: 68px;
    }
    p{
      height: 48px;
      margin-bottom: 0;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;

      &:after {
        @media only screen and (min-width: 676px) {
          display: block;
          content: "";
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #808285;
          margin: 20px auto -25px;
        }

      }
    }
  }
}