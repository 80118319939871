.block--neo-feat-list-bl {
  background-color: rgba($neo-light-gray, 0.8);
  padding: 64px 0 0 0;

  .block--outer {
    grid-column: 2/14;
    @include desktop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 32px;
    }

    .productblock {
      width: 100%;
      height: unset;
      margin-bottom: 32px;
      background: white;
      flex: 1;
      display: flex;
      flex-direction: column;
      @include desktop{
        margin-bottom: 86px;
      }

      .image__container {
        padding: 16px;
        background: #e6e6e6;
        height: 204px;
      }

      .block--content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        max-height: 100%;
        padding: 24px;
        background: $white;

        .block--title {
          font-size: 20px;
          font-family: $ff-bosch-sans;
          font-weight: normal;
          display: inline-block;
          @include mobile-only{
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
          }
        }

        .block--text {
          padding-bottom: 24px;
          flex-grow: 1;
        }

        .block--list {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 16px;
          &.list--details{
            .list--title {
              font-size: 16px;
              color: $neo-blue;
              margin: 0 0 8px;
            }
          }
          .list--title {
            font-size: 20px;
            font-family: $ff-bosch-sans;
            font-weight: normal;
          }

          .list--link {
            color: $hoverblue;
            font-size: 16px;
            margin: 0;
            padding: 8px 0 0 0;
            @include on-event{
              color: $neo-cherry-red;
            }
          }
        }
        .productTypes{
          padding-top: 16px;
          margin-top: auto;
          border-top: 1px solid $lightgrey;
        }
      }
    }
  }
}
