.freeform-pages {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0 0 10px;
    list-style: none
}

.freeform-pages li {
    margin: 0 10px 0 0
}

.freeform-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px
}

.freeform-row .freeform-column {
    -ms-flex: 1 0;
    flex: 1 0;
    padding: 10px 0;
    margin: 0 15px;
    box-sizing: border-box
}

.freeform-row .freeform-column>.freeform-row:first-child {
    margin-top: -10px
}

.freeform-row .freeform-column label {
    display: block
}

.freeform-row .freeform-column .input-group-one-line {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.freeform-row .freeform-column .input-group-one-line label {
    padding-right: 10px
}

.freeform-row .freeform-column .freeform-label {
    font-weight: bold
}

.freeform-row .freeform-column .freeform-label.freeform-required:after {
    content: "*";
    margin-left: 5px;
    color: red
}

.freeform-row .freeform-column .freeform-input {
    width: 100%;
    display: block;
    box-sizing: border-box
}

.freeform-row .freeform-column .freeform-input[type=checkbox],
.freeform-row .freeform-column .freeform-input[type=radio] {
    width: auto;
    display: inline;
    margin-right: 5px
}

.freeform-row .freeform-column .freeform-input.StripeElement {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    border: 1px solid;
    padding-top: 5px;
    height: 26px
}

.freeform-row .freeform-column .freeform-input-only-label {
    font-weight: normal
}

.freeform-row .freeform-column .freeform-input-only-label>.freeform-input {
    display: inline-block;
    width: auto;
    margin-right: 5px
}

.freeform-row .freeform-column .freeform-errors,
.freeform-row .freeform-column .ff-errors {
    list-style: none;
    padding: 0;
    margin: 5px 0 0
}

.freeform-row .freeform-column .freeform-errors>li,
.freeform-row .freeform-column .ff-errors>li {
    color: red
}

.freeform-row .freeform-column .freeform-instructions {
    margin: 0 0 5px;
    font-size: 13px;
    color: #ABA7A7
}

.freeform-row .freeform-column.freeform-column-content-align-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.freeform-row .freeform-column.freeform-column-content-align-left>button:not(:first-of-type) {
    margin-left: 5px
}

.freeform-row .freeform-column.freeform-column-content-align-center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center
}

.freeform-row .freeform-column.freeform-column-content-align-center>button:not(:first-of-type) {
    margin-left: 5px
}

.freeform-row .freeform-column.freeform-column-content-align-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.freeform-row .freeform-column.freeform-column-content-align-right>button:not(:first-of-type) {
    margin-left: 5px
}

.freeform-row .freeform-column.freeform-column-content-align-spread {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.freeform-row .freeform-column.freeform-column-content-align-spread>button:not(:first-of-type) {
    margin-left: 5px
}

.ff-form-errors {
    padding: 15px;
    border: 1px solid #f5c6cb;
    background: #f8d7da;
    border-radius: 5px;
    color: #721c24
}

.ff-form-errors>p {
    margin: 0
}

.freeform-form-has-errors {
    color: #721c24
}