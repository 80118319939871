.neo__gas-usage-calculator__outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background: $neo-light-gray;
  padding: 32px 0;
  .neo__gas-usage-calculator__main-content {
    width: 100%;
    position: relative;
    @include tablet {
      padding-right: 32px;
      width: calc(100% - 300px);
    }
  }
  .neo__gas-usage-calculator__side-content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 200px;
    max-width: 250px;
    margin: 0 auto;
    position: relative;
    @include tablet {
      width: 300px;
      margin: 0;
      height: 100%;
    }
  }
}

.neo__gas-usage-calculator__title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 8px;
  max-width: 650px;
}
.neo__gas-usage-calculator__culculator__outer {
  width: 100%;
  position: relative;
  .neo__gas-usage-calculator__culculator__inner {
    max-width: 650px;
    padding: 16px;

    .divider {
      display: block;
      margin: 16px 0;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #098809;
    }

    .form-input,
    .form-answer {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 0;
      * {
        font-size: 16px;
      }
      p {
        margin: 0.5em 0;
        padding: 0.5em 0;
        width: calc(100% - 100px - 55px - 16px);
      }
      .input {
        padding: 0.5em;
        width: 100px;
        border: 1px solid black;
        border-radius: 4px;
        margin: 0 8px;
        background: white;
        overflow: auto;
        height: 44px;
      }
      .extension {
        width: 55px;
        text-align: left;
      }
    }
  }
}
.neo__gas-usage-calculator__image-container {
  width: 300px;
  height: 100%;
  min-height: 200px;
  position: relative;
  display: block;
  img{
    display: block;
    width: 100%;
  }
}

.toggle-modal-btn {
  position: absolute;
  background: #14a214;
  border: 2px solid #14a214;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  font-size: unset;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: 0;
  top: 0px;
  @include tablet{
 right: -8px;
  top: -8px;
  }
  &:hover {
    background: #fff;
    border: 2px solid #098809;
    .toggle-modal-btn__text {
      font-weight: bold;
      color: #098809;
    }
  }
  .toggle-modal-btn__text {
    color: white;
    font-size: 12px !important;
    transition: all 0.5s;
  }
}

.modal-gas {
  padding: 16px;
  border-radius: 40px;
  border: 2px solid #098809;
  text-align: center;
  background: #098809;
  .modal-gas__inner {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 18px;
    position: relative;
    p{
       color: white;
    }
    &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: -32px;
    border: 16px solid transparent;
    border-bottom-color: #098809;
     border-top: 0;
    } 
    @include desktop{
    &:after {
      left: -32px;
      top: 20px;
      border: 16px solid transparent;
      border-right-color: #098809;
      border-left: 0;
    }
   }
  }
   @include desktop{
    width: 300px;
    position: absolute;
    bottom: 0;
    left: calc(650px + 16px);
    z-index: 2;
   }
}