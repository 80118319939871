// Author page
.authors-page-content {
	margin-top: 80px;
	margin-bottom: 80px;
}

// Author articles header
.author__articles {
	.author__articles__header {
		margin-top: 48px;
		margin-bottom: 16px;
		border-left: 3px solid $blue;
		padding-left: 28px;
		padding-top: 5px;
		padding-bottom: 28px;
	}
	.author__articles__title {
		margin-bottom: 0;
		font-size: 20px;
		font-weight: bold;
	}
	.author__articles__sub-title {
	}
}

// Author articles list
.author__articles {
	ul {
		border-left: 3px solid $blue;
		padding-left: 28px;
		background: $lightgrey;
		padding: 0 28px 28px 28px;
		max-height: 350px;
		margin-top: 0;
		overflow: auto;
	}

	li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.author__article__link {
		border-bottom: 1px solid $black;
		padding: 40px 0;
		display: block;
		text-decoration: none;
		color: $black;
		position: relative;
		padding-right: 30px;
		position: relative;
		&:after {
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			border-right: 2px solid $red;
			border-bottom: 2px solid $red;
			transform: rotate(-45deg);
			margin-left: 20px;
			position: absolute;
			right: 10px;
			top: calc(50% - 4px);
		}
		&:hover,
		&:focus {
			.author__article__title {
				color: $red;
			}
		}
	}
	.author__article__title {
		font-weight: bold;
		color: $black;
		font-size: 16px;
		padding: 0;
		margin: 0;
	}
	.author__article__description {
		display: block;
		margin: 0;
		padding: 0;
		p {
			display: block;
			margin: 0;
			padding: 0;
		}
	}
}
