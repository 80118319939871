.savings-form {
  padding: 32px;
  width: 100%;
  max-width: 568px;
  background: #fff;
  opacity: 0.96;

  @include mobile-only {
    max-width: 100%;
    background-image: url("#{ $base-url }gray-light-full-lg.svg");
    background-size: cover;
    opacity: 1;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: bold;
  }

  label {
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 12px;

    @include tablet {
      color: #808285;
    }

    &.postal-code {
      @include mobile-only {
        width: 100%;
      }

      @include tablet {
        flex: 5;
      }
    }

    &.street-number {
      @include mobile-only {
        width: 60%;
      }

      @include tablet {
        flex: 4;
      }
    }

    &.street-number-suffix {
      @include mobile-only {
        width: calc(40% - 16px);
      }

      @include tablet {
        flex: 2;
      }
    }
  }

  input[type="text"] {
    padding: 0 12px;
    border-radius: 0;
    min-height: 54px;
    width: 100%;
    border: none;
    font-size: 16px;

    @include tablet {
      border: 1px solid #808285;
    }
  }

  fieldset {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    border: none;
    gap: 16px;
  }

  .form-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
    flex-direction: column;
   
    .btn {
      margin: 0;
      width: 100%;
      max-width: 100%;
      justify-content: center;
    }
  }

  .savings-toggle{
    flex: 1 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
      margin: 0 12px 0 0;
      font-size: 16px;
      color: #000;
    }

    &__check {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;
      width: 60px;
      height: 24px;
      border-radius: 25px;
      background-color: #c8c8c8;
      transition: 0.2s;

      @include mobile-only {
        margin-left: auto;
      }

      &:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 6px;
        bottom: 6px;
        border-radius: 100%;
        background-color: #fff;
        transition: 0.2s;
      }

      .on,
      .off {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 14px;
        transition: 0.2s;
      }

      .on {
        opacity: 0;
        left: 16px;
      }

      .off {
        right: 12px;
      }
    }

    input {
      display: none;
    }

    input:checked + .savings-toggle__check {
      background-color: #007bc0;

      .on {
        opacity: 1;
      }

      .off {
        opacity: 0;
      }
    }
    input:checked + .savings-toggle__check:before {
      transform: translateX(calc(100% + 24px));
    }
  }
}
