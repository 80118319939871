.promotion-section {
    display: flex;
    flex-direction: column;
    padding: 32px 0;

    @include desktop {
        flex-direction: row;
        flex-wrap: wrap;
    }

    h4 {
        width: 100%;
        margin-top: 0;
    }

    .promotion {
        margin-bottom: 16px;

        @include desktop {
            width: 48%;
            padding: 8px;
        }

        &.wide {
            @include desktop {
                height: 400px;
                width: 100%;
            }
        }

        .promotion-link {
            transition: opacity .1s ease-in-out;

            &:hover {
                transition: opacity .1s ease-in-out;
                opacity: 0.5;
            }

            img {

                width: 100%;
            }
        }
    }
}