.block--download-list {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;

  li {
    .cta,
    .link {
      padding: 0;
    }
  }
}
