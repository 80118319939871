
.locator-block {
  position: relative;
  padding: 18px;
  background: white;
  min-height: 150px;
  display: flex;
  flex-direction: column;

  .wrapper {
    justify-self: flex-start;
  }

  &--background {
    background: $white;
    padding: 16px;
  }

  @include tablet {
    margin-bottom: 0;
  }
}

.locator-block__header {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}
.locator-block__image {
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 75%;
  max-width: 50%;
  vertical-align: bottom;
}
.locator-block__title {
  margin: 0;
  z-index: 1;
  width: 90%;
  max-height: 62px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.locator-block__text {
  width: 50%;
  position: relative;
  z-index: 1;
}

.locator-block__form {
  display: flex;
  position: absolute;
  left: 16px;
  bottom: 16px;
  width: calc(100% - (2 * 18px));
  max-width: 250px;

  @include phone-big {
    max-width: 300px;
  }
}

.locator-block__label {
  display: flex;
  position: relative;
  &--textinput {
    z-index: 1;
    width: calc(100% - 40px);
    @include desktop {
      width: calc(100% - 100px);
    }
  }

  &--submit {
    z-index: 2;
    position: relative;

    &:hover {
      &:after {
        right: 2px;
        transition: 200ms ease-in-out all;
      }
    }

    &::after {
      content: "";
      // background-image: url("https://s3.eu-west-1.amazonaws.com/np-nefit/Iconen/epsilon_white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 5px;
      top: 15px;
      width: 13px;
      height: 12px;
      display: block;
      transition: 200ms ease-in-out all;
    }
  }
}

.locator-block__input {
  width: 100%;
  height: 36px;
  border-radius: 0;
  border: 1px solid $grey;
  padding-left: 12px;
}

.locator-block__submit {
  width: 116px;
  height: 40px;
  &.mobile {
    display: block;
    @include tablet {
      display: none;
    }
  }
  &.desktop {
    display: none;
    @include tablet {
      display: block;
    }
  }
}