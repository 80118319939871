.partial__advice-cta {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: white;
  @include tablet {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 24px 0px 24px 24px;
  }
  .block--content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    @include tablet {
      max-width: 65%;
      align-items: flex-start;
      justify-content: space-between;
    }

    .block__partial--title {
      font-size: 24px;
      font-weight: normal;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      @include tablet {
        flex-direction: row;
      }
    }

    .block__partial--description {
      width: 100%;
    }

    .cta {
      margin-top: 16px;
      @include mobile-only {
        width: 100%;
        margin-right: 0;
      }
      @include tablet {
        margin: 0;
      }
    }
  }

  .block--image {
    flex: 1;
    grid-column: 2/2;
    width: 100%;
    padding: 16px 0 0 0;
    @include desktop {
      max-width: 35%;
      padding: 0px 0px 0px 16px;
      align-self: flex-end;
    }

    img {
      max-height: 200px;
    }
  }
}
