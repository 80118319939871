.header--mobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;

  // {# Top bar #}
  .header__bar {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    padding: 16px 16px 24px 16px;
    background: $white;
    box-shadow: 0 0 1rem rgb(0 0 0 / 25%);

    .bar__controls {
      width: 100%;
      max-width: calc(1600px - 2 * 16px);
      margin: 0 auto;
    }

    .bar__controls,
    .header__controls {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 16px;
      width: 100%;
    }
    .header__controls {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .header__brandbar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 8px;
      z-index: 1;
    }
    .nav__logo {
      height: 40px;
      width: 230px;
      @include tablet {
        height: 60px;
        width: 340px;
      }
    }
    .header__toggle {
      display: inline-block;
      cursor: pointer;
      padding: 0;
      margin: 0;
      cursor: pointer;
      height: 16px;
      margin-top: auto;
      margin-bottom: 2px;
      background: none;
      border: none;

      .header__toggle-bar {
        width: 22px;
        height: 3px;
        background-color: $black;
        margin: 3px 0;
        transition: 0.4s;
        display: block;

        &:nth-of-type(1) {
          margin-top: 0;
        }
        &:nth-of-type(3) {
          margin-bottom: 0;
        }
      }
    }

    .icon,
    svg {
      width: 20px;
      height: 20px;
      fill: $black;
      margin: 0;
      padding: 0;

      svg {
        height: 100%;
      }
    }

    .header__search-link {
      margin: 0;
      display: block;
      list-style: none;
      color: unset;
      text-decoration: none;
      display: flex;
      align-items: flex-end;
    }
  }

  // {# Navigation levels #}
  .content__slide {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    padding: 0 16px 20px 16px;
    background: $white;
    opacity: 0;
    transform: translateX(105%);
    transition: all 0.5s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    ul,
    ol {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul + ul {
      margin-top: 30px;
    }
  }

  .menu-level--1 {
    .nav-level--1 {
      gap: 48px;
    }
    .item {
      &__label {
        text-align: center;
      }
      &__button {
        font-size: 24px;
      }
      &__button .icon {
        display: none;
      }
    }
    .links {
      gap: 20px;
      padding-top: 40px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid $header-line-color;
      }
      .item__link {
        justify-content: center;
        font-size: 16px;
      }
    }
  }
  // {# Buttons / Links #}
  .item {
    &__link,
    &__button {
      display: flex;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
      background: none;
      border: none;
      text-decoration: none;
      color: $black;
      font-size: 18px;
      cursor: pointer;
      @include tablet {
        font-size: 16px;
      }
      @include on-event {
        color: $hoverblue;
      }
    }
    &__button svg {
      height: 12px;
    }
    &__label {
      flex: 1;
      text-align: left;
    }

    &__back {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 16px;
      width: 100%;
      margin: 0 0 30px 0;
      padding: 0;
      background: none;
      border: none;
      font-size: 24px;
    }
    &__back .icon {
      transform: rotate(180deg);
      margin: 0 0 6px 0;
    }
    &__back svg {
      height: 18px;
    }
  }

  // Desktop items
  .header__menu {
    &--mobile {
      display: block;
      @include desktop {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @include desktop {
        display: block;
        width: 100%;
        background: $white;
        box-shadow: 0 0 1rem rgb(0 0 0 / 25%);
      }
    }
    &__inner {
      @include desktop {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 32px;
        width: 100%;
        max-width: calc(1600px - 2 * 16px);
        margin: 0 auto;
      }
    }
  }

  .header__bar {
    z-index: 1;
    @include desktop {
      padding-bottom: 0;
      .bar__controls {
        display: flex;
        gap: 0;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .bar__top-menu {
      display: none;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 8px;
        a {
          margin: 0;
          padding: 4px 8px 4px 8px;
          color: $black;
          text-decoration: none;
          cursor: pointer;
          font-size: 14px;
          @include on-event {
            color: $neo-blue;
          }
        }
      }
    }
    .header__controls {
      @include desktop {
        display: none;
      }
    }
    .header__links {
      display: none;
      @include desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        width: 100%;
        margin: 32px 0 0 0;
        border-bottom: 1px solid #f1f1f1;
        .nav-level--1 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;
          list-style: none;
          margin: 0;
          padding: 0;
          a,
          button {
            padding: 0 0 16px 0;
            border-bottom: solid 2px $white;
            @include on-event {
              border-bottom: solid 2px $neo-blue;
            }
            &.active {
              border-bottom: solid 2px $neo-blue;
            }
          }
        }
      }
    }
  }

  .nav-level--2 {
    @include desktop {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin: 0;
      padding: 24px 0 20px;
      list-style: none;

      a,
      button {
        font-size: 16px;
        padding: 10px 18px 10px 18px;
        margin: 0;
        background: transparent;

        &.active {
          background-color: #e0e2e5;
        }
      }

      .item__link {
        @include on-event {
          text-decoration: underline;
        }
      }

      @include large-page-height {
        padding-top: 40px;

        a,
        button {
          padding: 12px 18px 12px 18px;
        }
      }
    }
  }

  .menu-level--3 {
    @include desktop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      height: 100%;
      max-width: calc(100% - 16px - 24px);
      padding: 24px 0 0;

      @include large-page-height {
        gap: 40px;
        padding-bottom: 40px;
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 16px 0;
        list-style: none;
        border-top: 1px solid #f1f1f1;
        width: 100%;
        max-width: calc(100% - 16px - 24px);
      }
      .item__link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;

        @include on-event {
          text-decoration: underline;
        }
      }
      .column__title--link {
        @include on-event {
          text-decoration: underline;
        }
      }
    }
  }

  .menu__close {
    display: none;

    @include tablet {
      width: 16px;
      margin-left: 16px;
      padding: 24px 32px 12px 0;
      position: absolute;
      right: 0;
      top: 0;

      @include large-page-height {
        padding-top: 40px;
      }
    }

    .icon {
      cursor: pointer;
      svg {
        height: 18px;
      }
    }
  }

  .header__menu {
    @include desktop {
      .columns {
        display: grid;
        gap: 24px 16px;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
      }
      .column {
        display: flex;
        flex-direction: column;

        &--large {
          grid-row: span 2;
        }

        &__title {
          width: 100%;
          font-weight: bold;
          margin-bottom: 16px;
          color: black;

          &--link {
            text-decoration: none;
            transition: color ease-in-out 0.2s;

            @include on-event {
              color: $blue;
              text-decoration: underline;
            }
          }
        }
        &__links {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          margin: 0;
          padding: 0;
          list-style: none;

          @include large-page-height {
            gap: 16px;
          }
        }
      }
    }
  }

  .content__tab {
    display: none;
    &.active {
      display: flex;
    }
  }

  .menu__exit {
    display: none;
    @include desktop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      opacity: 0;
      z-index: -1;
    }
  }

  &.menu--open {
    @include desktop {
      .menu__exit {
        display: block;
      }
      .header__bar {
        box-shadow: none;
      }
    }
    @include tablet {
      .menu__close {
        display: flex;
      }
    }
  }

  // animations
  &[data-menu="open"] {
    .header__toggle-bar {
      &:nth-of-type(1) {
        transform: rotate(-45deg) translate(-4px, 4px);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: rotate(45deg) translate(-5px, -4px);
      }
    }
  }
  .content__slide {
    &.active {
      display: block;
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.header--mobile .item__link {
  .icon {
    width: 10px;
    height: 20px;
  }
}
