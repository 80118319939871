.neo__product-category-block__outer {
  display: flex;
  @include until-desktop {
    flex-direction: column-reverse;
  }

  .neo__product-category-block-content {
    flex: 1;
    @include until-desktop {
      flex: 0 0 100%;
    }
    @include desktop-only {
      padding-right: 16px;
    }

    h3 {
      font-size: 32px;
      line-height: 1.25;
    }

    div {
      p,
      & {
        line-height: 1.75;
      }

      ul {
        color: $black;
      }
    }
  }

  .neo__product-category-block-image-holder {
    flex: 0 0 calc((100% / 3) * 2);
    max-height: 540px;
    max-width: 540px;
    margin: 0 auto;

    @include until-desktop {
      flex: 0 0 100%;
      padding-bottom: 32px;
    }
    @include desktop-only {
      padding-left: 16px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
}
