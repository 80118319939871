.block--neo-text-with-form {
  .block--outer {
    background: $neo-light-gray;
    padding: 64px 0 !important;
    grid-column: 1/15;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      z-index: -1;
      background: $neo-light-gray;
      width: 100vw;
      height: calc(100%);
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
    }

    .block--inner {
      grid-column: 2/14;
    }

    hr {
      margin: 16px 0;
    }

    .block--inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .neo-form__colum {
      margin-bottom: 16px;
    }

    input {
      color: black;
    }

    label {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .block--title {
      width: 100%;
      margin-bottom: 0;
    }

    .block--title.mobile {
      display: none;
    }

    @include until-tablet {
      flex-wrap: wrap;
    }

    .block--text-container {
      @include flex-width(50%, 32px);
      @include until-desktop {
        @include flex-width(50%, 32px);
        @include until-tablet {
          @include flex-width(100%, 0);
        }
      }
    }

    .block--form-container {
      margin-top: 16px;
      @include flex-width(50%, 16px);
      @include until-desktop {
        @include flex-width(50%, 32px);
        @include until-tablet {
          @include flex-width(100%, 0);
        }
      }
      @include until-tablet {
        flex-wrap: wrap;
        .neo__text-with-form-block__text-container,
        .neo__text-with-form-form-container {
          flex: 0 0 100%;
        }
      }

      form {
        border-radius: 0;
        background: $white;
        border: solid 1px $neo-dark-gray;
        height: max-content;
        padding: 16px 0;
        width: 100%;

        .neo__errors {
          .neo__form__errors {
            position: relative;
            padding-left: 16px;
            color: $neo-dark-red;
            list-style: none;
            * {
              color: $neo-dark-red;
            }
            &::before {
              content: "";
              height: 100%;
              width: 2px;
              background: $neo-dark-red;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          margin-bottom: 48px;
        }

        .neo__form-row {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin: 0;
          justify-content: space-between;
          align-items: center;

          @for $i from 1 through 4 {
            &.neo__form-row-has-#{$i} {
              .neo__form-column {
                margin-right: 16px;
                margin-left: 16px;
                flex: 1 0 calc(100% / (#{$i} + 1));
                max-width: calc(100% - 32px);
                min-width: 160px;
                &.neo__form-column-html-content {
                  img {
                    max-width: 100%;
                    margin: auto;
                  }
                }
              }
            }
          }

          .neo__form-column {
            > input[type="text"],
            > input[type="tel"],
            > input[type="email"],
            > textarea,
            > select {
              resize: none;
              width: 100%;
              margin: 8px 0;
              border-radius: 0;
              border: solid 1px $neo-dark-gray;
              background-color: $white;
              color: black;
              font-size: 14px;
              padding: 14px 16px;
              transition: border-color 0.2s ease-in-out,
                box-shadow 0.2s ease-in-out;

              &::placeholder,
              &:-ms-input-placeholder,
              &::-ms-input-placeholder {
                color: $neo-dark-gray;
                opacity: 1;
              }

              &:hover,
              &:focus {
                outline: none;
                border-color: $black !important;
                box-shadow: $neo-blue 0 0 3px 1px;
              }
            }

            > select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
            }

            &.required {
              position: relative;

              &:before {
                content: "*";
                text-align: center;
                font-size: 24px;
                background-image: radial-gradient(
                  circle at center,
                  $white 62%,
                  transparent 70%
                );
                pointer-events: none;
                line-height: 28px;
                position: absolute;
                width: 16px;
                height: 16px;
                right: 0;
                top: 50%;
                transform: translate(50%, -50%);
                overflow: hidden;
                z-index: 1;
                max-height: 100%;
              }

              &.neo__form_has_errors {
                margin-top: 8px;

                &:before {
                  color: $neo-dark-red;
                }
              }
            }
            &.neo__form-column-single-checkbox.required,
            &.neo__form-column-radio-options.required{             
              &::before {
                content: none;
              }

              .neo__form__label-checkbox,
              .neo__form__label-radio_group {
                position: relative;
                display: block;

                &:after {
                  display: inline-block;
                  content: "*";
                  text-align: center;
                  font-size: 24px;
                  background-image: radial-gradient(
                    circle at center,
                    $white 62%,
                    transparent 70%
                  );
                  pointer-events: none;
                  line-height: 28px;
                  width: 16px;
                  height: 16px;
                  overflow: hidden;
                  z-index: 1;
                  max-height: 100%;
                  transform: translateY(2px);
                }
              }
            }

            .input-group-one-line  &.required {
              left: 100%;
              right: auto;
            }

            .neo__cta {
              margin: 8px 0;
              margin-right: 16px;
              width: 100%;
              max-width: none;
            }

            .neo__form__label-file {
              & + input {
                height: 0.1px;
                width: 0.1px;
                z-index: -1;
                position: absolute;
              }
            }

            .neo__form__errors {
              top: 0;
              left: 0;
              transform: translateY(-50%);
              color: $neo-dark-red;
              list-style: none;
              position: absolute;
              padding: 0;
              margin: 0;
            }
          }

          .neo_form-column-is-select {
            position: relative;
            padding-right: 0;
            width: 100%;

            select {
              background-image: linear-gradient($neo-dark-gray, $neo-dark-gray),
                linear-gradient($neo-light-gray, $neo-light-gray);
              background-repeat: no-repeat;
              background-size: 1px 100%, 42px 100%;
              background-position: calc(100% - 42px) 0, 100% 0;
              padding-right: 50px;
            }
            &:after {
              top: auto;
              bottom: 16px;
              margin-top: 0;
              transform: translateY(-100%) rotate(135deg);
            }
          }

          .neo__form-column-has-options,
          .neo__form-column-has-options .input-group-one-line {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .neo__form-column-has-options .input-group-one-line {
            flex: 0 0 100%;
          }

          .neo__form__label {
            max-width: 100% !important;
            flex: 0 0 100% !important;
          }

          .neo__form__label-radio_group {
            & ~ label,
            & + * label {
              cursor: pointer;
              position: relative;
              padding: 8px 0;
              padding-left: 24px;

              input {
                width: 16px;
                height: 16px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: $white;
                background-image: radial-gradient(
                  circle,
                  $white 15%,
                  $neo-blue calc(15% + 1px)
                );
                background-size: 670% 670%;
                background-position: center;
                border-radius: 8px;
                border: solid 1px $neo-dark-gray;
                position: absolute;
                left: 0;
                top: 50%;
                transition: all 0.2s ease-in-out;
                transform: translateY(-70%);

                &:checked {
                  background-color: $neo-blue;
                  border-color: $neo-blue;
                  background-size: 100% 100%;
                }

                &:focus {
                  outline: none;
                  border-color: $black !important;
                  box-shadow: $neo-blue 0 0 3px 1px;
                }
              }

              &:hover,
              &:focus {
                input {
                  border-color: $black !important;
                  box-shadow: $neo-blue 0 0 3px 1px;
                }
              }
            }
          }

          .neo__form-column-single-checkbox {
            padding: 16px 0;
            padding-left: 24px;
            .neo__form__label-checkbox {
              &.neo__form__label-checkbox {
                padding-left: 0;
                input {
                  left: -24px;
                }
              }
            }
          }

          .neo__form__label-checkbox,
          .neo__form__label-checkbox_group {
            ~ * label,
            ~ label,
            &.neo__form__label-checkbox {
              padding: 8px 0 8px 26px;
              flex: 0 0 100%;
              position: relative;
              display: inline-block;
              @include tablet {
                flex: 0 0 50%;
              }

              &:focus,
              &:hover {
                input {
                  border-color: $black !important;
                  box-shadow: $neo-blue 0 0 3px 1px;
                }
              }

              input {
                width: 16px;
                height: 16px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: $white;
                background-image: radial-gradient(
                    circle,
                    transparent 60%,
                    $neo-blue calc(60% + 1px)
                  ),
                  linear-gradient(
                    45deg,
                    transparent 45%,
                    $white 45%,
                    $white 55%,
                    transparent 55%
                  ),
                  linear-gradient(
                    -45deg,
                    transparent 45%,
                    $white 45%,
                    $white 55%,
                    transparent 55%
                  ),
                  linear-gradient($neo-blue, $neo-blue);
                background-size: 200% 200%, 500% 500%, 500% 500%, 100% 100%;
                background-position: center;
                border-radius: 0;
                border: solid 1px $neo-dark-gray;
                position: absolute;
                left: 0;
                top: 50%;
                transition: all 0.2s ease-in-out;
                transform: translateY(-70%);

                &:checked {
                  background-size: 100% 100%, 100% 100%, 100% 100%, 100% 100%;
                  border-color: $neo-blue;
                }

                &:focus {
                  outline: none;
                  border-color: $black !important;
                  box-shadow: $neo-blue 0 0 3px 1px;
                }
              }
            }
          }

          @for $i from 1 through 3 {
            .neo__form-columns-#{$i}-options {
              label {
                flex: 0 0 (100% / #{$i});
                max-width: calc(100% / #{$i} - 32px / #{$i} * (#{$i} - 1));
              }
            }
          }
        }
      }
    }

    .block__cta__list {
      list-style: none;
      justify-content: flex-start;
      padding-left: 0;
      margin-left: 0;
    }
  }

  .contact--outer {
    margin: 32px 0;
    display: flex;
    flex-wrap: wrap;

    .contact--column {
      width: 100%;
      @include tablet {
        width: calc((100% - 32px) / 2);
        &:nth-of-type(1) {
          margin-right: 32px;
        }
      }

      .contact--column--title {
        font-weight: bold;
        width: 100%;
        margin-bottom: 16px;
        font-size: 16px;
      }

      .contact--column--text {
        margin: 0;
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0;
        line-height: 24px;
      }

      .contact--column--icon {
        margin-right: 8px;
        display: inline-block;
        margin-right: 8px;
        padding: 8px;
        border-radius: 50%;
        background: $black;
        transition: all 0.5s;
        border: 2px solid $black;
        figure {
          width: 24px;
          height: 24px;
          margin: 0;
          padding: 0;
          fill: white;
          transition: all 0.5s;
        }
        &:hover,
        &:focus {
          background: $white;
          figure {
            fill: $black;
          }
        }
      }
    }
  }

  .neo__form__success__outer {
    border-radius: 0;
    background: $white;
    border: solid 1px $neo-dark-gray;
    height: max-content;
    padding: 24px;
    width: 100%;
    .neo__form__success {
      position: relative;
    }
    .neo__form__logo {
      margin-bottom: 16px;
      max-height: 50px;
      max-width: 100%;
    }
    .neo__form__success {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  .neo__text-with-form-block__outer {
    .neo__text-with-form-form-container {
      form {
        .neo__form-row {
          .form-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            position: relative;
            display: block;
            margin-top: 8px;
            &.required {
              content: "*";
              display: block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .neo__form-column-single-file {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            margin-top: 16px;
            .neo__form__label-file {
              min-width: unset;
              max-width: unset;
              width: 100%;
              margin-left: 0;
              margin-right: 0;
            }
            .neo__form__label-file + input {
              outline: none;
              position: relative;
              display: block;
              height: 100%;
              width: 100%;
              z-index: 1;
              margin-left: 0;
              display: flex;
              justify-content: center;
              align-self: center;
              border: 1px solid $neo-dark-gray;
              border-radius: 4px;
              padding: 12px 16px;
              background-color: rgb(255, 255, 255);
            }
            @include desktop {
              flex-wrap: nowrap;
              flex-direction: row-reverse;
              margin-top: 0;
              .neo__form__label-file {
                width: calc((100% / 2) - 16px);
                min-width: calc((100% / 2) - 16px);
                max-width: calc((100% / 2) - 16px) !important;
                margin-left: 32px;
              }
              .neo__form__label-file + input {
                width: calc((100% / 2) - 16px);
                min-width: calc((100% / 2) - 16px);
                max-width: calc((100% / 2) - 16px) !important;
              }
            }
          }
        }
      }
    }
  }

  // Custom form upload styling Valid input
  .neo__text-with-form-block__outer
    .neo__text-with-form-form-container
    form
    .neo__form-row {
    &.neo__form-row-has-2,
    &.neo__form-row-has-3 {
      .neo__form-column-single-file {
        .neo__form__label-file {
          width: 100%;
          max-width: 100% !important;
        }
        .neo__form__label-file + input {
          display: none;
        }
      }
    }
  }
}

.block--neo-text-with-form {
  // File upload styling
  .neo__form-column-single-file {
    position: relative;
    display: flex;
    flex-direction: column;
    &:not(.is-valid),
    &:not(.has-errors) {
      .file-upload__details,
      .file-upload__errors {
        margin: 0;
      }
    }

    label {
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: auto;
        right: 20px;
        width: 26px;
        height: 26px;
        border-radius: 2px;
        transform: translateY(-50%);
      }
      &:before {
        background-image: url("#{ $base-url}icons/folder-add.svg");
      }
      &:after {
        content: none;
        background-image: url("#{ $base-url}icons/delete.svg");
      }
    }

    &.has-errors,
    &.is-valid {
      label {
        &:before {
          content: none;
        }
        &::after {
          content: "";
        }
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    &.is-valid,
    &.has-errors {
      .file-upload__details,
      .file-upload__errors {
        display: flex;
        flex-direction: column;
      }
    }

    .file-upload__errors,
    .file-upload__details {
      position: relative;
      display: none;
      & > * {
        margin: 0;
        font-size: 12px;
      }
    }

    .file-upload__details {
      list-style: none;
      padding: 0;
      & > * {
        @include limit-lines(1);
      }
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("#{ $base-url}icons/file-regular.svg");
      }
      @include desktop {
        padding-left: 80px;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 60px;
          height: 60px;
          background: $component-background-grey;
          background-image: none;
          transform: translateY(-50%);
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 10px;
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("#{ $base-url}icons/file-regular.svg");
          transform: translate(0%, -50%);
        }
      }
    }

    .file-upload__errors {
      & > * {
        color: $neo-dark-red;
      }
    }
  }
  [type="submit"] {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.neo__form-column-single-checkbox.required:before {
  top: 18px !important;
  transform: translate(50%, 0%) !important;
}

.neo__form-input-checkbox {
  top: 20px !important;
  transform: 0 !important;
}
