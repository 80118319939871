.blog-link {
  color: $black;

  &:hover {
    .blog-title {
      transition: color 0.2s ease-in-out;
      color: $red;
    }
  }

  .blog-snippet {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 16px;
    position: relative;

    .blog-text-container {
      z-index: 100;
      background: white;
      position: absolute;
      top: 32px;
      left: 32px;
      padding: 16px;
      width: 240px;
      border-bottom: 4px solid $red;

      .blog-title {
        transition: color 0.2s ease-in-out;
        margin-top: 0;
      }
    }
  }
}

.blog-snippet-wide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include desktop {
    flex-direction: row;
  }

  .blog-snippet-content {
    order: 2;

    @include desktop {
      width: 50%;
      min-width: 50%;
      padding-left: 32px;
    }

    header {
      margin-bottom: 16px;

      h2 {
        margin: 0;

        @media only screen and (max-width: 770px) {
          font-size: 1.2em;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .clickthrough {
      color: black;
      display: block;
      position: relative;
      text-decoration: none;
      padding-right: 12px;
      margin: 16px 0;
      float: right;
      width: 90px;

      &:hover {
        color: $red;
      }

      &:after {
        content: ">";
        right: 0;
        color: $red;
        position: absolute;
      }
    }
  }

  .blog-snippet-img-container {
    order: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
    width: 100%;
    margin-bottom: 32px;

    @include desktop {
      width: 50%;
    }
  }
}
