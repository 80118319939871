.other-blogs__outer {
  padding: 20px 0;
  @include tablet {
    padding: 32px 0;
  }
}

.other-blogs__heading {
  font-size: 24px;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 20px;
  @include tablet {
    font-size: 32px;
    margin-bottom: 28px;
  }  
}

.other-blogs__list {
  @include tablet {
    @include flex-grid($columns: 3, $previous-columns: 0, $gap: 16px, $vertical-gap: 0);
  }
}

.other-blogs__item {
  border-bottom: 1px solid $lightgrey;
  text-decoration: none;
  color: $black;

  @include until-tablet {
    display: block;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &:hover {
    .blog__title {
      color: $red;
      transition: 0.2s ease-in-out;
    }
  }

  header {
    background: white;
    padding: 8px;
  }
}

.blog__image-container {
  overflow: hidden;
  position: relative;

  .blog__background-image {
    width: 100%;
    background-size: cover;
    transition: 200ms ease-in-out all;
    height: 50vw;

    @include tablet {
      flex: 1;
      height: 100%;
    }
  }

  .blog__foreground-image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 140px;
    height: 140px;
    background-size: cover;
  }

  &:hover {
    .blog__background-image {
      transition: 200ms ease-in-out all;
      transform: scale(1.07);
      position: relative;
    }
  }

  img {
    display: block;
    transition: 200ms ease-in-out all;
    width: 100%;
    height: 100%;
  }
}

.blog__title {
  transition: 0.2s ease-in-out;
  margin: 0 0 8px 0;
  //font-size: $postTitle;
}

.blog__date {
  color: $grey;
  font-size: smaller;
}

.fade-effect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}
