@use "sass:color";

$black: #000000;
$white: #ffffff;
$grey: #808285;
$lightgrey: #f1f1f1;
$red: #e20015;
$darkred: #b00010;
$blue: #00608b;
$hoverblue: #005691;
$visitedpurple: #551a8b;
$green: #4e9040;
$component-background-grey: #f9f8f8;
$neo-cherry-red: #e20015;
$neo-dark-red: #b00010;
$neo-light-gray: $lightgrey;
$neo-dark-gray: #808285;
$neo-blue: #00608b;
$neo-light-blue: #d1e4ff;
$bosch-blue: #007BC0;
$bosch-blue-hover: #00629a;

// Buttons
$buttonGradient: linear-gradient(90deg, $red 0%, $darkred 100%);
$neo-btn-text-color: white;
$neo-btn-background-color: linear-gradient(
  to right,
  $neo-cherry-red 0%,
  $neo-dark-red 100%
);
$neo-btn-hover-background-color: linear-gradient(
  to right,
  $neo-dark-red 100%,
  $neo-dark-red 100%
);
$neo-alt-btn-background-color: linear-gradient(
  to right,
  $neo-blue 0%,
  color.scale($neo-blue, $lightness: -10%) 97%
);
$neo-alt-btn-hover-background-color: linear-gradient(
  to right,
  color.scale($neo-blue, $lightness: -10%) 100%,
  color.scale($neo-blue, $lightness: -10%) 97%
);

// Links
$neo-link-text-color: black;
$neo-link-hover-text-color: $neo-cherry-red;
$neo-wysiwyg-link: $neo-blue;
$neo-link-border-color: $neo-light-gray;
$neo-link-light-color: $neo-dark-gray;
$neo-date-text-color: $neo-dark-gray;

// Search
$neo-search-background-color: $neo-light-gray;

// FAQ
$neo-faq-active-link-color: $neo-light-gray;
$neo-faq-link-text-color: $neo-dark-gray;
$neo-faq-arrow-color: $neo-dark-red;

// Form
$neo-form-border-color: $neo-dark-gray;
$neo-form-hover-border-color: $neo-dark-red;

// Footer
$neo-footer-bg-color: $neo-light-gray;
$neo-footer-highlight: $neo-cherry-red;
$footer-line-color: #e0e2e5;

// Header
$header-icons: $neo-cherry-red;
$header-line-color: #d8d8d8;
$header-divider-color: #565656;
$header-navlist-line-color: $lightgrey;
$neo-header-bg-color: $neo-light-gray;
$neo-text-gray-color: $neo-dark-gray;
$neo-header-icons: $neo-cherry-red;

// Hero Pro
$hero-pro-gradient: linear-gradient(to right, #018ece, #02a7b0);

// Full Link Block
$full-link-block-background-mobile: #f4f4f4;

// Info & Maxi Banner colors
$bosch-turquoise: #18837e;
$bosch-lightblue: #008ecf;
$bosch-darkgreen: #006249;
$bosch-lightgreen: #78be20;
$bosch-fuchsia: #b90276;
