.block--index-block {
  .block--outer {
    grid-column: 4/12;
    padding: 70px 0 0 0;
  }

  a {
    &:visited {
      color: $visitedpurple;
    }
  }
}
