.neo-dl-table {
  height: 30vw;
  position: relative;
  overflow: auto;
  display: block;
  text-align: left;
  border-spacing: 0;
  border-collapse: separate;

  @include until-desktop {
    height: calc(90vh - 64px);
    @include until-tablet {
      margin-bottom: 32px;
    }
  }

  td, th {
    text-align: left;
    vertical-align: top;
  }

  .neo-dl-table-head {
    th {
      background: $neo-blue;
      position: sticky;
      color: $white;
      top: 0;
      vertical-align: bottom;
    }
  }

  .neo-dl-table-body {
    background-color: $white;
    background-image: linear-gradient($neo-light-gray, $neo-light-gray);
    background-repeat: no-repeat;
    background-position: center 100%;
    background-size: calc(90% - 32px) 1px;

    .neo-dl-table-services, .neo-dl-table-distance {
      padding-top: 40px;
    }

    .neo-dl-table-installer {
      .neo-dl-table-installer-title {
        width: max-content;
        margin-right: -288px;
        height: 32px;
        line-height: 32px;
      }
    }

    .neo-dl-table-index {
      line-height: 32px;
      font-weight: bold;
      font-size: 16px;
      color: $neo-dark-red;
    }
  }

  .neo-dl-table-body, .neo-dl-table-head {
    .neo-dl-table-index {
      width: (28px + 32px + 8px);

      *, & {
        text-align: center;
      }
    }

    > * {
      h5, div, a, li {
        font-size: 14px;
        line-height: 2;
        color: $black;
        font-weight: normal;
        text-decoration: none;
        display: block;
      }

      a {
        text-decoration: underline;
      }

      .neo-dl-table-installer-phone-number {
        display: block;
        max-width: 100%;

        a {
          display: inline-block;
          padding-right: 1em;
        }
      }

      .neo-dl-table-installer-title {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .neo-dl-table-installer {
      width: 288px;
    }

    .neo-dl-table-services {
      width: 256px;
    }

    .neo-dl-table-distance {
      width: 112px;
    }

    > * {
      padding: 8px;
    }

    > *:first-child {
      padding-left: 16px;
    }

    > *:last-child {
      padding-right: 16px;
    }
  }
}