.neo__categories-nav-block__outer {
  .neo__categories-nav-block__inner {
    display: flex;
    padding: 0;
    max-width: 100%;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    border-bottom: $neo-light-gray 1px solid;
    .VueCarousel-inner {
      justify-content: center;
    }

    .VueCarousel-navigation {
      position: relative;
      width: 100%;
      top: -118px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        transition: opacity 0.2s ease-in-out;
        margin: 0 !important;

        &:first-child {
          transform: none !important;
        }

        &:last-child {
          transform: none !important;
        }
      }
    }

    .neo__categories-nav-block__item {
      max-width: 180px;
      > a:not(.active) {
        opacity: 0.5;
      }

      > a {
        padding-bottom: 16px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        p {
          width: max-content;
          margin: 0 auto;
          display: flex;
          justify-content: center;
        }

        .neo__cta.link {
          color: $neo-dark-gray;
          text-decoration: none;
        }

        position: relative;

        &.active:after {
          content: "";
          background-image: linear-gradient(
              to top left,
              $neo-light-gray 50%,
              transparent 50%
            ),
            linear-gradient(
              to bottom left,
              transparent 50%,
              $neo-light-gray 50%
            );
          background-size: 50% 100%;
          background-position: 0 0, 100% 0;
          background-repeat: no-repeat;
          width: 16px;
          height: 8px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .neo__category__item {
        display: block;
        height: 63px;
        width: 100%;
        .neo__image-loading__container{
          width: 100%;
          height: 100%;
          justify-content: center;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
