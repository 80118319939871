.block--cta-duo-block {
  .container {
    padding: 0 24px;
  }

  .block--outer {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    @include tablet {
      grid-column: 2/14;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 33px;
      padding: 64px 16px;
    }

    .block--block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: $white;
      padding: 24px;
      margin: 0 0 24px 0;
      @include tablet {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }

      .block--category {
        font-size: 14px;
        color: $hoverblue;
      }

      .block--text {
        margin: 8px 0 24px 0;
        p {
          margin: 0;
        }
      }
    }
  }
}
