.share-buttons {
  &__outer {
    margin: 40px 0;
  }

  &__buttons {
    display: flex;
  }

  &__title {
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 10px;
  }
}

.share-button {
  &__link {
    margin-right: 5px;
  }

  &__img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}
