.hero-pro {
  .block--outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $hero-pro-gradient;
    color: $white;

    .block--content {
      padding: 107px 14px 100px 14px;
      max-width: 1600px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .hero-pro--label {
        background: $white;
        color: #005691;
        padding: 3px 12px;
        width: fit-content;
      }

      .hero-pro--title {
        width: 100%;
        font-size: 42px;
        padding: 16px 0 21px 0;
        margin: 0;
        color: $white;
        @include tablet {
          width: 569px;
        }
      }

      .hero-pro--subtitle {
        width: 90%;
        font-size: 18px;
        margin: 0;
        @include tablet {
          width: 656px;
        }

        * {
          color: $white;
        }
      }
    }
  }
}
