// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin basic-reset {
  list-style: none;
  background: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin limit-lines($amount) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin dots-divider {
  display: block;
  margin: 0 0 8px;
  height: 15px;
  width: 100%;

  background-image: radial-gradient(#fff 40%, transparent 60%);
  background-size: 3px 3px;
  background-position: 0 0;
}

@mixin gitp-arrow {
  &:after {
    content: "\f061";
    @include flex-center;
    position: absolute;
    top: 0;
    right: 0;
    font-family: $font-awesome;
    font-size: 20px;
    height: 100%;
    width: 48px;
    transform: rotate(45deg);
  }
}
@mixin dots-background {
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(
      rgba(0, 0, 0, 0.5) 30%,
      rgba(47, 142, 210, 0.1) 40%
    );
    background-position: 0 0;
    background-size: 3px 3px;
  }
}
@mixin dots-background-light {
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(
      rgba(55, 55, 55, 0.5) 20%,
      rgba(255, 255, 255, 0.1) 10%
    );
    background-position: 0 0;
    background-size: 3px 3px;
  }
}

/// Make a context based selector a little more friendly
/// @author Colin Dorr
/// @param {Number} $content-start
/// @param {Number} $image-start

@mixin textImage($content-start, $image-start) {
  // Text Left
  @if $content-start < $image-start {
    .block__header,
    .block__content {
      grid-column: #{$content-start} / #{$image-start - 1};
    }
    .block__image {
      grid-column: #{$image-start} / 15;
    }
  }
  // Text Right
  @else {
    .block__image {
      grid-column: #{$image-start} / #{$content-start - 1};
    }
    .block__header,
    .block__content {
      grid-column: #{$content-start} / 14;
    }
  }
}

// $base-url: 'https://nefit-bosch.nl/assets/' !default;
$base-url: "/assets/" !default;

@mixin limit-lines($maxLines) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $maxLines;
  -webkit-box-orient: vertical;
}

@mixin flex-width($width, $gap) {
  @if $gap != 0 {
    @if $gap > 0 {
      flex: 0 0 #{$width};
    } @else {
      flex: 0 0 calc(#{$width} - #{$gap});
    }
    max-width: calc(#{$width} - #{$gap});
    width: calc(#{$width} - #{$gap});
  } @else if $width != 100% {
    flex: 0 0 calc(#{$width});
    max-width: calc(#{$width});
    width: calc(#{$width});
  } @else {
    flex: 0 0 100%;
    width: 100%;
    max-width: none;
  }
}

@mixin calc-width($columns, $gap, $flex: false) {
  $percentage: calc(100% / #{$columns});
  $total-margin: $gap * ($columns - 1);
  width: calc(#{$percentage} - (#{$total-margin} / #{$columns}));
  @if $flex {
    flex: 0 0 calc(#{$percentage} - (#{$total-margin} / #{$columns}));
    max-width: calc(#{$percentage} - (#{$total-margin} / #{$columns}));
  }
}

@mixin flex-grid(
  $columns,
  $previous-columns,
  $gap,
  $vertical-gap: null,
  $set-vertical-margins: true,
  $uneven-grid-gap: 0
) {
  display: flex;
  flex-wrap: wrap;
  @if $uneven-grid-gap != 0 {
    padding-top: $uneven-grid-gap;
  }
  $margin-top: null;
  @if $vertical-gap {
    $margin-top: $vertical-gap;
  } @else {
    $margin-top: $gap;
  }
  > * {
    @if ($columns != 1) {
      @include calc-width($columns, $gap);
    } @else {
      width: 100%;
    }
    @if ($columns != 1) {
      &:not(:nth-child(#{$previous-columns}n)) {
        margin-right: 0;
      }
      @if $set-vertical-margins {
        &:not(:nth-child(-n + #{$previous-columns})) {
          margin-top: 0;
        }
      }
    }

    &:not(:nth-child(#{$columns}n)) {
      margin-right: $gap;
      @if $uneven-grid-gap != 0 {
        position: relative;
        top: -1 * $uneven-grid-gap;
      }
    }

    @if $set-vertical-margins {
      &:not(:nth-child(-n + #{$columns})) {
        margin-top: $margin-top;
      }
    }
  }
}

@mixin container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;

  @include tablet {
    display: grid;
    grid-template-columns: $container-padding repeat(12, 1fr) $container-padding;
    grid-column-gap: $grid-gap;
    max-width: 100%;
    padding: 0;
  }

  @include container-min {
    grid-template-columns:
      1fr repeat(12, calc(#{$grid-width} / 12 - #{$grid-gap}))
      1fr;
  }
}
