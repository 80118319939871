.neo__product-nav-block__outer {
  display: flex;

  @include until-tablet {
    flex-wrap: wrap;
  }

  a {
    align-self: flex-start;
  }

  .neo__product-nav-block__inner {
    padding: 0;
    margin: 0;
    flex: 1;
    list-style: none;
    @include until-tablet {
      @include flex-width(100%, 0);
    }
    @include tablet {
      margin-left: 16px;
      width: calc(100% - 76px);
    }

    .VueCarousel-inner {
      justify-content: center;
    }

    .neo__product-nav-product {
      padding: 0;
      display: flex;
      justify-content: center;
      margin: 0 8px;
      max-width: 180px;
      @include until-tablet {
        margin: 0;
      }

      a {
        text-decoration: none !important;
        padding: 0 !important;
        margin: 0 !important;
        width: 164px;
        display: flex;
        align-items: center;
        flex-direction: column;

        &:not(.active) {
          opacity: 0.5;
        }

        .neo__product-nav-block-image-holder {
          width: 68px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }

        p {
          text-decoration: none;
          width: max-content;
          margin: 0 auto;
          color: $neo-dark-gray;
          height: 32px;
        }
      }
    }
  }
}
