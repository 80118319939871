.short-links-section {
    display: none;

    @include tablet {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .short-links-section__title {
        margin: 0;
    }
}

.short-links__block {
    flex: 0 0 47%;

    &:nth-of-type(odd) {
        margin-right: 3%;
    }
}

.short-links__list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding-left: 0;

    .short-links__col {
        flex: 50%;
    }

    .short-links_list-item {
        margin: 16px 0;

        a {
            color: $black;
        }
    }
}

.handy-links__list {
    list-style: none;
    padding-left: 0;

    li {
        margin: 16px 0;

        .handy-links__link {
            color: $black;


            &:hover {
                color: $red;
            }
        }
    }

}