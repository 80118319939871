@use "sass:color";

.background {
  background: $lightgrey;
}

.footer {
  background: $white;
}

.footer__inner {
  display: flex;
  flex-direction: column;
  padding: 32px 0 80px;
  border-top: 1px solid $lightgrey;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.footer__top {
  display: flex;
}

.footer__logo-container {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex: 100%;
  order: 3;

  @include tablet {
    flex: initial;
    order: 1;
  }
}

.footer__logo {
  max-width: 150px;
  max-height: 60px;
  margin: 0 auto 20px;

  @include tablet {
    margin: 0 auto 20px 0px;
  }
}

.footer__list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 2;

  @include tablet {
    flex-direction: row;
    width: 40%;
    margin: 0;
  }

  @include desktop {
    width: 60%;
    margin: 0;
    justify-content: space-around;
  }
}

.footer__col {
  width: auto;

  @include fullscreen {
    width: auto;
  }
}

.footer__link-list {
  list-style: none;
  padding-left: 0;

  li {
    margin: 18px 0;

    a {
      text-decoration: none;
      color: $black;

      &:hover {
        color: $red;
        transition: color 0.1s ease-in-out;
      }
    }
  }
}

.footer__contact-info {
  margin-top: 12px;
  text-align: center;

  .contact-info__content {
    a {
      text-decoration: none;
      color: $black;

      &:hover {
        color: $red;
        transition: color 0.1s ease-in-out;
      }
    }

    @include tablet {
      text-align: left;
      border-left: 4px solid $red;
      padding-left: 18px;
      order: 1;
    }
  }
}

.contact-info__links {
  display: flex;
  flex-direction: column;
  margin: 0;

  a {
    text-decoration: none;
    color: $black;
  }
}

.footer__social-block {
  display: flex;
  flex-direction: column;
  order: 3;
  width: 50%;
  text-align: center;
  margin: 32px auto;

  @include tablet {
    margin: 0;
    width: auto;
    margin-bottom: 0;
    min-width: 190px;
    text-align: left;
  }

  .footer__title {
    margin-bottom: 0;
  }
}

.social-block__image-container {
  display: flex;
  margin: 28px 0;

  a {
    margin-right: 10px;

    img {
      height: 40px;
      width: 40px;
      margin-right: 12px;
    }
  }
}

.newsletter__button {
  font-size: 14px;
  font-weight: lighter;
  padding-left: 18px;
  text-decoration: none;
  height: 40px;
  border: none;
  background: $buttonGradient;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background: color.scale($red, $lightness: -10%);
  }

  &::after {
    content: url("#{ $base-url }img/icons/epsilon_right.svg'}");
    position: absolute;
    right: 12px;
    top: 13px;
  }
}

.footer__copyright {
  display: inline-flex;
  flex-direction: column;

  width: 100%;
  justify-content: space-between;
  padding: 16px 0;

  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer__copyright__text {
    margin-bottom: 0;
    text-align: center;
    width: 100%;

    @include desktop {
      width: auto;
      text-align: left;
    }
  }

  .footer__bottom-list {
    text-align: center;
    list-style: none;
    padding-left: 0;

    @include tablet {
      display: flex;
      width: 60%;
      text-align: left;
    }

    li {
      margin: 16px 0;

      @include tablet {
        margin: 0;
      }
    }
    
    a {
      color: $black;
      margin-right: 32px;
      margin-bottom: 16px;
    }
  }
}
