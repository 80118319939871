.neo__featuredArticles {
  .block__inner{
    grid-column: 2/14;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: -16px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      width: auto;
      height: 100px;
    }

    li {
      padding: 0;
      margin: 16px 0;
      overflow: hidden;
    }

    .neo__featured-article-full-layout {
      flex: 0 0 100%;
      min-height: 300px;
      height: 300px;
      border: solid 1px $neo-light-gray;
      
      @include until-desktop {
        height: 100%;
        min-height: 220px;
      }

      a {
        height: 100%;
        text-decoration: none;
        width: 100%;
        min-height: 100%;
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .neo__featured-article-image-block__item__image {
          overflow: hidden;
          width: calc(100%);
          
          @include desktop {
            width: calc(100% / 2 - 16px);
            margin-right: 32px;
          }

          .neo__related-articles-block-image {
            width: 100%;
            height: 100%;
            transition: transform 0.3s;
            background-color: $neo-dark-gray;
            background-size: cover;
          }
        }

        .neo__featured-article-image-block__item__text {
          padding: 16px;
          @include flex-width(50%, 16px);
          background: $white;
          position: relative;

          @include until-desktop {
            flex: unset !important;
            width: 244px !important;
            max-width: calc(100% - 65px) !important;
          }

          .neo__featured-date {
            color: $neo-dark-gray;
            font-size: 12px;
            line-height: 16px;
            padding: 8px 0;
            padding-bottom: 24px;
          }

          .neo__featured-full-text {
            line-height: 1.75;
            color: $black;
            margin-bottom: 1em;
            @include limit-lines(6);
          }

          .neo-epsilon {
            position: absolute;
            right: 24px;
            bottom: 26px;
          }
        }

        &:hover,
        &:focus {
          .neo__featured-article-image-block__item__image {
            .neo__related-articles-block-image {
              transform: scale(1.2);
            }
          }
        }
      }

      @include until-desktop {
        margin: 8px 0;
        a {
          position: relative;
          padding: 16px;

          .neo__featured-article-image-block__item__image {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            display: block;
          }

          .neo__featured-article-image-block__item__text {
            flex: 1;
            width: 100%;
            height: 100%;
            box-shadow: inset $neo-cherry-red 0 -4px;
          }
        }
      }
    }
  }
}

.neo__featured-article-full-layout .neo__related-articles-block-image {
  width: 100%;
}

// Half layout in text
.neo__text-with-articles--articles {
  .neo__featured-article-half-layout {
    width: 100%;
  }
}

.neo__featured-article-half-layout {
  width: 100%;
  height: 100%;
  min-height: 220px;
  margin: 8px 0;
  list-style: none;
  position: relative;

  @include tablet {
    margin: 0;
    height: 300px;
    min-height: 300px;
    width: calc(50% - 16px);
  }

  a {
    text-decoration: none;
  }

  &:focus,
  &:hover {
    .neo__featured-article-image-block__item__image {
      overflow: hidden;

      .neo__related-articles-block-image {
        transform: scale(1.2);
      }
    }
  }

  .neo__featured-article-image-block__item__image {
    height: 100%;
  }

  .neo__related-articles-block-image {
    pointer-events: none;
    transition: transform 0.3s;
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 100%;
    background-color: $neo-dark-gray;
    background-size: cover;
    display: block;
  }

  .neo__related-articles-block-content {
    width: 244px;
    max-width: calc(100% - 32px);
    height: max-content;
    background: $white;
    margin: 0 16px;
    transform: translateY(16px);
    padding: 16px;
    box-shadow: $neo-cherry-red 0 4px 0;
    min-height: 160px;
    h4 {
      font-size: 22px;
      @include tablet {
        font-size: 26px;
      }
    }

    @include until-desktop {
      flex: unset !important;
      width: 244px !important;
      max-width: calc(100% - 32px) !important;
    }

    span {
      display: block;
      padding-top: 24px;
      font-size: 12px;
      color: $neo-dark-gray;

      .neo-epsilon {
        float: right;
        margin-right: 4px;
        display: inline-block;
      }
    }
  }
}


.neo__featured-article-image-block__item 
.neo__featured-article-image-block__item__image{
  .neo__image-loading__container {
    width: 100%;
    height: 100%;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
