.block--neo-video-block {
  .block__content {
    grid-column: 2/14;
  }

  .partial__container {
    display: flex;
    width: 100%;
    position: relative;
    .partial__content {
      position: absolute;
      height: 420px;
      width: 100%;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: -1;
      font-size: 0;
      background-color: $black;
      .video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        left: 0;
        top: 0;

        &:focus {
          outline: none;
        }
      }
      .video--placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: center center no-repeat;
        background-size: cover;
      }
    }

    .video__list__item {
      width: 100%;
      display: block;
      margin-right: -1px;
      height: 420px;
      border: 1px solid $neo-light-gray;
      position: relative;
      box-sizing: border-box;
      display: none;
      margin-bottom: 170px;
      position: relative;
      @include tablet {
        flex: 1;
        display: inline-block;
        margin-bottom: 0;
      }
      &.active {
        display: inline-block;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0)
        );
        .item--index {
          width: 32px;
          height: 32px;
          opacity: 1;
          font-size: 24px;
          line-height: 32px;
        }
        .item--info {
          .item--description {
            max-height: 200px;
          }
        }
      }

      .item--index {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: white;
        color: black;
        opacity: 0.5;
        font-size: 16px;
        bottom: -32px;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
        line-height: 24px;
        transition: all 0.3s;
        z-index: 1;
        @include tablet {
          top: 36px;
          bottom: auto;
          background: $neo-light-gray;
        }
      }
      .item--info {
        position: absolute;
        width: 100%;
        bottom: -150px;
        height: 130px;
        left: 0;
        color: black;
        padding: 0 24px;
        box-sizing: border-box;
        @include tablet {
          bottom: 0;
          color: $white;
          height: auto;
        }
        .item--title {
          margin: 0;
          font-size: 24px;
          line-height: 1.33;
        }
        .item--description {
          color: $black;
          max-height: 0;
          overflow: hidden;
          transition: max-height 1s;
          @include tablet {
            color: $white;
            margin: 24px 0;
          }
        }
      }
      .item--progress {
        height: 7px;
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        overflow: hidden;

        .scrubber {
          height: 100%;
          position: absolute;
          left: 0;
          background: $neo-cherry-red;

          &:after {
            content: "";
            display: block;
            position: absolute;
            right: -5px;
            background: $neo-cherry-red;
            width: 7px;
            height: 7px;
            transform: skew(45deg);
          }
        }
      }
    }

    .patial__controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 420px;
      @include tablet {
        display: none;
      }
      button {
        background: rgba(0, 0, 0, 0.3);
        height: 100%;
        color: white;
        &.next {
          float: right;
        }
        .arrow-left,
        .arrow-right {
          width: 10px;
          height: 10px;
          display: block;
          transform: rotate(45deg);
        }
        .arrow-left {
          border-bottom: 2px solid white;
          border-left: 2px solid white;
        }
        .arrow-right {
          border-top: 2px solid white;
          border-right: 2px solid white;
        }
      }
    }

    .partial__content--fallback {
      margin-right: -1px;
      height: 420px;
      border: 1px solid $neo-light-gray;
      box-sizing: border-box;
      margin-bottom: 170px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: $neo-light-gray;
    }
  }
}
