.form-input {
  margin: 5px 0 5px 10px;

  &[type='text'] {
    border-radius: 4px;
    border: 1px solid $neo-form-border-color;
    color: $neo-form-border-color;
    padding: 12px 16px;
    transition: all 0.5s;

    &:hover, &:focus {
      outline: none;
      border: 1px solid $bosch-blue;
    }
  }
}
