.neo__sales-block__outer {
  .neo__sales-block__content {
    max-width: 568px;
    margin: 0 auto;
    text-align: center;

    * {
      text-align: center;
    }

    .neo__sales-block-content-text {
      line-height: 1.75;
      font-size: 14px;
      padding-bottom: 32px;
    }
  }

  .neo__sales-block-sales__outer {
    .neo__sales-block-sales {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      width: calc(100% - 32px);
      margin: 0 16px;
      @include desktop {
        margin: 0;
        flex-wrap: wrap;
        width: 100%;
      }
      .neo__sales-block__item {
        flex: 0 0 100%;
        margin: 0;
        margin-bottom: 32px;
        @include desktop {
          margin: 0 16px;
          margin-bottom: 32px;
          padding-bottom: 32px;
        }
      }
      &.col-3 {
        .neo__sales-block__item {
          @include desktop {
            flex: 1 1 calc(33% - 32px);
            max-width: calc(33% - 32px);
          }
        }
      }
      &.col-4 {
        .neo__sales-block__item {
          @include tablet {
            flex: 1 1 calc(50% - 32px);
            margin: 0 16px;
            margin-bottom: 32px;
            padding-bottom: 32px;
          }
          @include desktop {
            flex: 1 1 calc(25% - 32px);
            max-width: calc(25% - 32px);
          }
        }
      }

      .neo__sales-block__item {
        .neo__sales-block__item-inner {
          width: 100%;
          max-width: 576px;
          border: solid 2px $neo-dark-gray;
          min-height: 352px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .neo__sales-block__item-content {
            position: relative;
            padding: 24px 24px 42px;
            display: flex;
            height: 100%;
            flex-direction: column;

            .neo__sales-block__item-content-title {
              font-size: 24px;
              padding: 12px 0;
              text-align: center;
              @include desktop {
                font-size: 28px;
              }
            }

            .neo__sales-block__item-content-sub-sticker-line {
              width: 100%;
              padding: 4px 0;
              position: relative;

              .neo__sales-block__item-content-subtitle {
                text-align: center;
                width: 100%;
                padding: 0 32px 0 0;
                @include desktop {
                  padding: 0 42px;
                }
              }

              .neo__sales-block__item-content-sticker {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(50%, -50%);
                background-color: #4e9040;
                width: 72px;
                border-radius: 36px;
                height: 72px;
                line-height: 72px;
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                color: $white;
              }
            }

            .neo__sales-block__item-content-content {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              .neo__sales-block__item-content-content-inner {
                border-top: solid 1px $neo-light-gray;
                width: 100%;
                padding: 8px 0 16px;
                height: max-content;
              }
            }
          }
          .neo__sales-btn {
            width: 100%;
            max-width: none;
            margin: 0;
          }
        }
      }
    }
  }
}
