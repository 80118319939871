// HEADER
.sales-highlight__container {
  .sales-highlight__header {
    max-width: 568px;
    text-align: center;
    margin: 32px auto 64px auto;
  }
  .sales-highlight__header-title {
    font-size: 36px;
    margin: 0;
    margin-bottom: 24px;
    @include tablet {
      font-size: 48px;
    }
  }
}

// SALES GENERAL
.sales-highlight__container {
  .sales-highlight__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    justify-content: center;
  }
  .sales-highlight__item {
    position: relative;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: inset 0 -8px 0 0 $blue;
    border: solid 1px $grey;
    background-color: $white;
    padding: 32px 24px 0 24px;
    width: 100%;
    margin-bottom: 32px;
    box-sizing: border-box;
    position: relative;
    @include desktop {
      width: calc(((100% - 64px) / 3));
      margin-right: 32px;
    }
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
}

.sales-highlight__item-price {
  width: 64px;
  height: 64px;
  display: block;
  position: absolute;
  top: 20%;
  right: 0;
  border-radius: 50%;
  transform: translate(25%, -0px);
  background: $green;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  @include tablet {
    width: 72px;
    height: 72px;
  }
  span {
    text-align: center;
    font-size: 16px;
    line-height: 1.33;
    font-weight: bold;
    color: $white;
  }
}

// SALES CONTENT
.sales-highlight__container {
  .sales-highlight__item__inner {
    margin: 0 auto;
    max-width: 368px;
  }
  .sales-highlight__item-header {
    @include desktop {
      height: 100px;
    }
    @include mq(1200px) {
      height: 120px;
    }
  }
}

// SALES HEADER
.sales-highlight__container {
  .sales-highlight__item-header {
    position: relative;
    display: block;
    overflow: visible;
    margin-bottom: 32px;
  }
  .sales-highlight__item-title {
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    @include mq(1200px) {
      font-size: 32px;
    }
  }
  .sales-highlight__item-sub-title {
    text-align: center;
    margin: 16px 0;
  }
}

// SALES FOOTER
.sales-highlight__item {
  .sales-highlight__item-footer {
    padding-bottom: 60px;
    border-top: 1px solid $lightgrey;
    text-align: left;
    @include desktop {
      .full__arrow {
        width: 100%;
        margin: 20px auto 0 auto;
      }
    }
  }
}

// FOOTER LINK
.sales-highlight__item {
  .sales-highlight__link {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 24px;
    background: $blue;
    color: $white;
    width: 100%;
    padding: 8px 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    svg {
      margin: 0 16px;
    }
  }
}
