.savings-card-info {
  &__content {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;

    @include tablet {
      width: 60%;
    }
  }
}

.savings-item {
  display: flex;
  align-items: center;
  padding: 24px 32px;

  @include tablet {
    padding: 24px 40px;
  }

  &:not(:first-child) {
    border-top: 3px solid $savingsBackground;
  }

  .metric-value {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-right: auto;

    span {
      width: 100%;
      opacity: 0.4;
    }
  }

  .savings-percentage {
    font-weight: 700;
    background: $lightgrey;
    padding: 4px 12px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    i {
      position: relative;
      display: inline-block;
      margin-right: 12px;
    }
    &--down {
      i {
        color: $green;
      }
    }
    &--up {
      i {
        color: $red;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-right: 24px;
    flex: 0 0 42px;

    @include mobile-only {
      display: none;
    }

    &--gas {
      &:before {
        content: url('data:image/svg+xml, <svg viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.274 27.928V14.082c0-3.304-2.16-6.105-5.12-7.018V.25H7.21v6.815c-2.96.912-5.12 3.712-5.12 7.018v13.845c-.975.212-1.706 1.043-1.706 2.035v.699c0 1.152.983 2.088 2.193 2.088h21.177c1.228 0 2.227-.951 2.227-2.12v-.667c0-.992-.73-1.823-1.706-2.035ZM8.915 1.875h8.533V6.76a7.37 7.37 0 0 0-.38-.01h-3.033V3.5h-1.707v3.25H9.295c-.127 0-.254.004-.38.01V1.875Zm.38 6.5h7.773c3.032 0 5.5 2.56 5.5 5.707v13.793H3.795V14.082c0-3.147 2.467-5.707 5.5-5.707Zm14.979 22.254a.484.484 0 0 1-.153.35.535.535 0 0 1-.368.146H2.576a.5.5 0 0 1-.344-.136.453.453 0 0 1-.143-.327v-.699c0-.123.052-.24.143-.327a.5.5 0 0 1 .344-.136h21.212a.5.5 0 0 1 .343.136.453.453 0 0 1 .143.327v.666Zm-11.092-3.16c3.46 0 5.972-2.392 5.972-5.688 0-2.123-.959-4.46-1.85-5.732l-.473-.676-1.134.72a23.877 23.877 0 0 1-.552-1.701c-.583-1.96-1.186-3.986-3.243-3.986h-.853v.813c0 1.764-.844 3.145-1.736 4.608-1.035 1.695-2.104 3.448-2.104 5.954 0 3.296 2.512 5.688 5.973 5.688Zm.322-12.634c.285.958.58 1.949 1.048 2.841l.432.823 1.329-.843c.594 1.114 1.135 2.716 1.135 4.125 0 .992-.303 1.88-.856 2.571.002-.044.003-.089.003-.133 0-1.362-.846-2.47-1.664-3.542-.607-.795-1.18-1.545-1.34-2.305l-.312-1.488-1.127 1.073c-1.648 1.57-2.383 3.25-2.383 5.45 0 .37.034.728.102 1.068-.618-.705-.956-1.636-.956-2.694 0-2.069.952-3.629 1.873-5.137.763-1.25 1.548-2.536 1.844-4.111.316.496.58 1.325.872 2.302Zm-1.022 5.264c.31.539.693 1.042 1.068 1.533.688.902 1.338 1.753 1.338 2.587 0 .896-.765 1.625-1.706 1.625-1.005 0-1.707-1.003-1.707-2.438 0-1.3.314-2.344 1.007-3.307Z" fill="%23000"/></svg>');
        width: 32px;
      }
    }

    &--electricity {
      &:before {
        content: url('data:image/svg+xml, <svg viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.049 27.145h-3.056v-1.388h1.413c-.706-4.476-4.794-7.98-9.818-8.292v1.353H16.06v-1.353c-5.023.33-9.11 3.816-9.817 8.292h1.413v1.388H4.6c0-6.124 5.482-11.102 12.224-11.102 6.743 0 12.225 4.978 12.225 11.102ZM2.308 19.512v-6.245H.016V9.104h3.056V2.859c0-1.145 1.031-2.082 2.292-2.082 1.26 0 2.292.937 2.292 2.082v6.245h4.584V2.859c0-1.145 1.032-2.082 2.292-2.082 1.26 0 2.292.937 2.292 2.082v6.245h3.056v4.163h-2.292v1.405c-.248-.017-.496-.017-.725-.017-.268 0-.535 0-.803.017V11.88h2.293v-1.387H1.543v1.387h2.293v7.633c0 .763.267 1.457.726 2.03-.249.45-.478.936-.669 1.422-.974-.885-1.585-2.099-1.585-3.452Zm11.46-10.408h1.528V2.859c0-.382-.343-.694-.764-.694-.42 0-.764.312-.764.694v6.245Zm-9.168 0h1.528V2.859c0-.382-.344-.694-.764-.694-.42 0-.764.312-.764.694v6.245Zm9.168 4.163h-7.64v1.388h7.64v-1.388Zm8.214 8.778L18.81 25.41c.325.503.42 1.145.172 1.752-.344.85-1.223 1.388-2.159 1.388-.248 0-.515-.035-.764-.122-1.184-.381-1.814-1.578-1.394-2.654.42-1.075 1.738-1.648 2.922-1.266.02 0 .039.017.039.017l3.17-3.365a.793.793 0 0 1 1.07-.07c.344.226.382.66.115.955Zm-4.91 3.747c-.076-.035-.171-.035-.248-.035-.305 0-.61.173-.725.468a.674.674 0 0 0 .458.885c.401.121.84-.052.974-.416.153-.365-.057-.764-.458-.902Z" fill="%23000"/></svg>');
        width: 42px;
      }
    }

    &--c02 {
      &:before {
        content: url('data:image/svg+xml, <svg viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.493 28.617 2.135.88c-.433 1.076-.85 1.683-1.583 2.228-.79.607-1.776.91-2.925.91-3.12 0-5.12-2.168-5.12-5.549 0-3.32 2.075-5.624 5.075-5.624 1.314 0 2.418.394 3.224 1.152.508.5.806.94 1.21 1.88l-2.12.88c-.508-1.395-1.18-1.956-2.328-1.956-1.643 0-2.568 1.304-2.568 3.623 0 2.334.94 3.639 2.627 3.639 1.18 0 1.88-.622 2.373-2.063Zm13.694-1.606c0 3.32-2.134 5.624-5.21 5.624-3.044 0-5.149-2.274-5.149-5.564 0-3.275 2.18-5.609 5.24-5.609 2.985 0 5.12 2.32 5.12 5.549Zm-2.492.045c0-2.244-1.016-3.638-2.657-3.638-1.717 0-2.717 1.334-2.717 3.638 0 2.259 1.015 3.624 2.702 3.624 1.686 0 2.672-1.335 2.672-3.624Zm6.843 6.337c1.641-.758 2.448-1.743 2.448-2.956 0-1.38-1.03-2.259-2.643-2.259-1.477 0-2.388.668-2.732 2.017l1.852.439c.134-.561.343-.804.746-.804.373 0 .582.258.582.713 0 .925-.91 1.773-3.134 2.926V35H42v-1.607h-2.462Zm-31.32-8.235c-3.343 0-6.064-2.535-6.064-5.931 0-3.225 2.48-5.924 5.646-6.143l.938-.065.064-.952c.215-3.216 2.872-5.735 6.048-5.735.86 0 1.691.18 2.474.536l.87.396.492-.83c1.575-2.66 4.337-4.247 7.387-4.247 4.75 0 8.615 3.926 8.615 8.75 0 .488-.042.988-.126 1.486l-.14.836.76.347c2.18.992 3.587 3.199 3.587 5.621a6.16 6.16 0 0 1-2.046 4.612c.29.713.482 1.49.56 2.316 2.283-1.556 3.64-4.121 3.64-6.928 0-3.005-1.598-5.764-4.132-7.24.034-.353.051-.703.051-1.05C36.842 4.907 32.011 0 26.072 0c-3.508 0-6.71 1.68-8.733 4.536a8.046 8.046 0 0 0-2.489-.391c-4.002 0-7.395 2.95-8.088 6.868C2.906 11.716 0 15.163 0 19.227c0 4.603 3.687 8.118 8.219 8.118h3.9l-.004-2.19-3.896.003Z" fill="%23000"/></svg>');
        width: 42px;
      }
    }

    .savings-percentage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 100px;
      margin-left: auto;
      padding: 0 16px;
      height: 36px;
      min-width: 84px;
      font-weight: bold;
      font-size: 14px;
      background: $savingsBackground;

      &:before {
        margin-right: 6px;
        height: 20px;
        width: 14px;
        color: $hoverblue;
      }

      &--up {
        &:before {
          content: url('data:image/svg+xml, <svg aria-hidden="true" data-prefix="fas" data-icon="chevron-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-chevron-up fa-w-14"><path fill="%239E2896" d="m240.971 130.524 194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"/></svg>');
        }
      }

      &--down {
        &:before {
          content: url('data:image/svg+xml, <svg aria-hidden="true" data-prefix="fas" data-icon="chevron-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-chevron-up fa-w-14"><path fill="%23005691" d="m240.971 130.524 194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"/></svg>');
          transform: rotate(180deg) translateY(2px);
        }
      }
    }
  }
}
