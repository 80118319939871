.block--neo-quote-slider {
  .container {
    padding: 0;
  }
  .block--outer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $neo-light-gray;
    padding: 32px 16px;
    grid-column: 1/15;
    @include tablet {
      padding: 48px 32px;
    }

    &:before {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 200vw;
      transform: translateX(-50%);
    }

    .block-inner {
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 64px;
      box-sizing: border-box;
    }

    .block__partial--slider__item {
      &__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include tablet {
          width: 80%;
          margin: 0 auto;
        }
      }

      &__content {
        text-align: center;
        margin: 0;

        p {
          font-size: 24px;
          font-weight: 300;
          line-height: 1.67;
          margin-top: 0;
        }
      }

      &__author {
        text-align: center;
        font-size: 14px;

        &--name {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          max-width: 80%;
          margin: 0 auto;
          @include tablet {
            flex-direction: row;
            justify-content: center;
          }
        }
      }
    }
  }
  .VueCarousel-navigation {
    position: absolute;
    top: 50%;
    width: 100%;

    button {
      padding: 0 !important;
      margin: 0 !important;
      @include desktop {
        padding: 36px;
      }
    }
  }
}
