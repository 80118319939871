.article-overview__outer {
  // Filters
  .article-overview__filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    justify-content: center;
    margin: 32px auto 8px auto;
    grid-column: 2/14;
    max-width: 760px;

    li:last-child {
      .article__category__filter-item {
        margin-right: 0;
      }
    }
  }
  .article__category__filter-item {
    padding: 4px 16px;
    background: $neo-light-gray;
    border-radius: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 14px 14px 0;
    transition: all 0.3s;
    font-size: 12px;
    color: black;
    text-decoration: none;
    line-height: normal;
    display: block;

    @include on-event {
      color: $neo-blue;
      background: $neo-light-blue;
    }

    &.category--active {
      color: white;
      background: $neo-blue;
    }
  }

  // Article
  .article-overview__article-list {
    display: flex;
    flex-direction: column;
    grid-column: 1/15;
    list-style: none;
    padding: 0;
    margin: 0;

    .article__container {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      margin: 0;
      padding: 0;
      @include tablet {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    .article__details,
    .article__categories {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 12px;
      &:last-child {
        &:after {
          content: none;
          display: none;
        }
      }
      span {
        position: relative;
        padding-right: 8px;
        &.article__type {
          text-transform: uppercase;
        }
        &:after {
          content: "|";
          position: absolute;
          right: 2px;
          top: 0;
        }
        &:last-child {
          &:after {
            content: none;
            display: none;
          }
        }
      }
    }

    // Articles: Featured article
    .article-overview__featured-article {
      grid-column: 1/15;
      width: 100%;
      margin-bottom: 32px;
      .article__container {
        grid-column: 2/14;
      }
      .article__title {
        display: flex;
        align-items: center;
        @include mobile-only {
          font-size: 36px;
        }
      }
      .article__details {
        justify-content: center;
      }
      .article__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px;
        width: 100%;
        text-align: center;
        .wysiwyg {
          font-size: 18px;
        }
      }
      .article__content__inner {
        max-width: 760px;
        margin: 0 auto;
      }
      .featured-article__link {
        padding: 12px 70px !important;
        margin-top: 16px;
      }
    }

    // Articles: Normal list-item
    .article-overview__list-article {
      grid-column: 1/15;
      width: 100%;
      &.article--odd {
        background: $neo-light-gray;
      }
      &.article--even {
        background: white;
      }
      .article__container {
        grid-column: 2/14;
        padding: 60px 0 60px;
      }
      .article__image {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        flex: 1;
        overflow: hidden;
        width: 100%;
        max-width: 450px;
        height: 253px;
        @include mobile-only {
          height: 0;
          max-width: 100%;
          padding-top: 56.5%;
          .image__container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
          }
        }
        .image {
          object-fit: cover;
        }
      }
      .article__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 32px;
        width: 100%;
        flex: 1;
        background: white;
        .wysiwyg {
          font-size: 14px;
        }
      }
      .article__content__inner {
        max-width: 760px;
        margin: 0;
      }
    }
  }

  // Button
  .btn__container {
    grid-column: 2/14;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
