.page-section {

  .locator-block,
  .cta-block {
    min-height: 172px;
  }
}

.cta-block {
  position: relative;
  margin-bottom: 28px;
  min-height: 170px;
  max-height: 200px;
  border: 1px solid $lightgrey;
  padding: 18px;
  background: white;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;

  .wrapper {
    justify-self: flex-start;
  }

  .cta__button {
    width: calc(100% - (3 * 18px));
    max-width: 200px;
    position: absolute;
    bottom: 20px;

    @include phone-big {
      max-width: 250px;
    }
  }

  &--background {
    background: $white;
    padding: 16px;
  }

  @include tablet {
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.cta__button {
  &.desktop {
    display: none;

    @include desktop {
      display: block;
    }
  }

  &.mobile {
    display: block;
    width: 40px;
    padding: 0;

    img {
      width: 18px;
    }

    @include desktop {
      display: none;
    }
  }
}

.cta-block__header {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0;
  z-index: 1;
  width: 90%;
  max-height: 62px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cta-block__text {
  width: 50%;
  position: relative;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  z-index: 1;
}

.cta-block__form {
  display: flex;
  width: 100%;

  @include tablet {
    width: 60%;
  }
}

.cta-block__label {
  display: flex;

  &--textinput {
    flex: 70%;
  }

  &--submit {
    flex: 30%;
    position: relative;

    &::after {
      content: url("#{ $base-url }img/icons/epsilon_right.svg'}");
      position: absolute;
      right: 11px;
      top: 11px;
    }
  }
}

.cta-block__input {
  width: 100%;
  height: 36px;
  border-radius: 4px 0 0 4px;
  border: 1px solid $grey;
  padding-left: 12px;
}