.partial__gas-usage-calculator{
  grid-column: 2/14;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background: $neo-light-gray;
  padding: 16px;
  flex-direction: column;
  @include tablet{
    flex-direction: row;
  }
  .partial__block--content{
    width: 100%;
    position: relative;
    flex: 2;
    @include tablet {
      padding-right: 32px;
      width: calc(100% - 300px);
    }
  }
  .partial__block--image{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 200px;
    max-width: 250px;
    margin: 0 auto;
    position: relative;
    flex: 1;
    @include tablet {
      width: 300px;
      margin: 0;
      height: 100%;
    }
  }
  .calculator__form{
    .partial--title {
      text-align: center;
      @include tablet{
        text-align: start;
      }
    }
    .divider {
      display: block;
      margin: 16px 0;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #098809;
    }

    .form-input,
    .form-answer {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin: 0;
      @include tablet{
        justify-content: flex-start;
        text-align: left;
      }
      * {
        font-size: 16px;
      }
      p {
        margin: 0.5em 0;
        padding: 0.5em 0;
        width: 100%;
        @include tablet{
        width: calc(100% - 100px - 55px - 16px);
        }
      }
      .input {
        padding: 0.5em;
        border: 1px solid black;
        border-radius: 4px;
        margin: 0 8px;
        background: white;
        overflow: auto;
        height: 44px;
        // width: 100%;
        width: 100px;
        @include tablet{
        }
      }
      .extension {
        width: 55px;
        text-align: left;
      }
    }
  }
  
  .neo__gas-usage-calculator__outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background: $neo-light-gray;
    padding: 32px 0;
    .neo__gas-usage-calculator__main-content {
      width: 100%;
      position: relative;
      @include tablet {
        padding-right: 32px;
        width: calc(100% - 300px);
      }
    }
    .neo__gas-usage-calculator__side-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 200px;
      max-width: 250px;
      margin: 0 auto;
      position: relative;
      @include tablet {
        width: 300px;
        margin: 0;
        height: 100%;
      }
    }
  }

  .toggle-modal__btn {
    position: absolute;
    background: #14a214;
    border: 2px solid #14a214;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    font-size: unset;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 0;  
    bottom: 0;
    top: auto;
    transform: translateY(50%);

    @include tablet{
      right: -8px;
      top: -8px;
    }
    &:hover {
      background: #fff;
      border: 2px solid #098809;
      .btn--text {
        font-weight: bold;
        color: #098809;
      }
    }
    .btn--text {
      color: white;
      font-size: 12px !important;
      transition: all 0.5s;
    }
  }
  .calculator__form{
    max-width: 760px;
  }

  .calculator__modal{
    padding: 16px;
    border-radius: 40px;
    border: 2px solid #098809;
    text-align: center;
    background: #098809;
    .toggle-modal__btn{
      position: absolute;
      top: 0;
      right: -18px;
      transform: translateY(-100%);
      z-index: 1;
      margin: 0;
      @include tablet{
        top: -32px;
        right: -18px;
        transform: none;
      }
    }
    .modal--content {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 18px;
      position: relative;
      p{
        color: white;
      }
      &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      top: -32px;
      border: 16px solid transparent;
      border-bottom-color: #098809;
      border-top: 0;
      } 
      @include desktop{
      &:after {
        left: -32px;
        top: 20px;
        border: 16px solid transparent;
        border-right-color: #098809;
        border-left: 0;
      }
    }
    }
    @include desktop{
      width: 300px;
      position: absolute;
      bottom: 0;
      left: calc(650px + 16px);
      z-index: 2;
    }
  }
  .partial__block--image{
    margin-top: 32px;
    @include tablet{
      margin-top: 0;
    }
  }
}