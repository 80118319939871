.block--product-benefits {
  .block__outer{
    grid-column: 2/14;
    display: flex;
    flex-direction: column;

    .block__list{
      display: flex;
      flex-direction: column;
      list-style: none;
      @include tablet{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px 32px;
      }
      @include desktop{
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}