.position-unset {
	position: unset;
}
.position-relative {
	position: relative;
}
.product-line-section {
	width: 100%;
	z-index: 10;

	.product-line-background {
		position: absolute;
		width: 100vw;
		top: 204px;
		left: 0;
		right: 0;
		height: 648px;
		background: $lightgrey;
		z-index: -1;

		// Min-height to facilitate the background if it's there
		// Prevents overlaying the footer/other elements due to its absolute position
		& ~ .product-line-section__grid {
			min-height: 800px;
		}
	}

	.product-line-section__grid {
		display: flex;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
}

.product-line-section__product {
	border-radius: 4px;
	background: $white;
	box-shadow: 0px 8px 0 0 #00608b;
	border: 1px solid $grey;
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	margin: 0;
	margin-left: 0;
	margin-right: 0;
	box-sizing: border-box;
	margin-bottom: 16px;

	@include tablet {
		width: 100%;
		margin-bottom: 32px;

		width: calc((100% - (3 * 20px)) / 2);
		margin-left: 0;
		margin-right: 20px;

		&:nth-of-type(2n) {
			margin-right: 0;
		}
	}

	@include desktop {
		width: calc((100% - (4 * 20px)) / 3);
		margin-left: 0;
		margin-right: 20px;

		&:nth-of-type(2n) {
			margin-right: 20px;
		}

		&:nth-of-type(3n) {
			margin-right: 0;
		}
	}

	.top {
		padding: 16px;
		min-height: 465px;
	}

	.product-image-wrapper {
		height: 260px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.product__image {
			max-height: 260px;
			max-width: 250px;
			width: 100%;
			display: block;
			margin: 0 auto;
		}
	}

	.product__related-products {
		// flex: 50%;
		height: 50%;
		background: $lightgrey;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 16px;

		.related-product {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 16px 0;
			cursor: pointer;

			&:hover {
				.related-product__link {
					color: $red;
				}
			}

			.related-product__image {
				display: block;
				margin: 0 auto 8px auto;
				max-height: 120px;
			}

			.related-product__link {
				color: $black;
				position: relative;
				text-align: center;

				span {
					&::after {
						content: "";
						display: inline-block;
						width: 8px;
						height: 8px;
						border-left: 2px solid #e20015;
						border-top: 2px solid #e20015;
						position: absolute;
						transform: rotate(-225deg);
						margin: 5px 0 0 8px;
						transition: 0.3s transform;
					}
				}
			}
			.product__image {
				max-height: 260px;
				max-width: 250px;
				width: 100%;
				display: block;
				margin: 0 auto;
				background: none;
			}
		}
	}
}
