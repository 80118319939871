.block--contact-block {
  background: $neo-light-gray;
  padding: 64px 0;

  .contact-block__inner {
    grid-column: 2/14;
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .contact-block__partial {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.contact__faq__list__container {
      .partial__inner {
        padding: 16px 0;
      }
      .partial__btn-container {
        margin-top: 32px;
      }
    }
    &:nth-of-type(odd) {
      margin: 0 0 32px 0;
      @include tablet {
        margin: 0 32px 0 0;
      }
    }
    @include tablet {
      width: 50%;
      flex: 1;
    }
  }
  .partial__title {
    width: 100%;
    padding: 0 16px;
  }
  .partial__inner {
    background: white;
    padding: 16px;
    width: 100%;
    .social-icons__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 32px;
      .social-icons__item {
        .icon {
          border: 1px solid black;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 16px 16px 0;
          padding: 8px;
          transition: all 0.3s;
        }
        svg {
          width: 24px;
          height: 24px;
          transition: all 0.3s;
        }
        @include on-event {
          .icon {
            background: black;
          }
          svg {
            fill: white;
          }
        }
      }
    }
    .partial__contact--block {
      padding: 16px;
      border-bottom: 1px solid $neo-light-gray;

      .link {
        padding: 0;
      }
    }
    .contact__location {
      padding-bottom: 0;
      border-bottom: none;
    }
    .partial__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      a {
        color: $black;
      }
    }
    .block__title--icon {
      width: 1.5em;
      height: 1.5em;
    }
  }
  .partial__btn-container {
    text-align: center;
  }
}
