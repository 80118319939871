$maxWidthBlock: 1400px;
$maxHeightBlock: 900px;
$maxWidthComponent: 1024px;

.quizTool-bg {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
}
.quizTool {
  position: relative;
  display: block;
  width: 100%;
  background: white;
  padding-bottom: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  @include tablet {
    width: calc(100% - 84px);
    max-height: calc(100vh - 84px);
    top: 50%;
    left: 50%;
    max-width: $maxWidthBlock;
    transform: translate(-50%, -50%);
  }
}

// HEADER
.quizTool__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid $neo-light-gray;
  .top-border {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: url("https://s3.eu-west-1.amazonaws.com/np-nefit/Logos/brandbar.png");
    background-repeat: no-repeat;
    background-position: center center;
    height: 8px;
    background-size: cover;
  }
  .quizTool__header-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    svg {
      height: 36px;
      @include tablet {
        height: 67px;
      }
    }
  }
  .button-controls {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;

    @include until-tablet {
      position: absolute;
      top: 5px;
      right: 0;
    }

    a,
    button {
      padding: 5px;
      margin-left: 16px;
      width: 24px;
      display: flex;
      flex-wrap: nowrap;
      display: flex;
      justify-content: center;
      color: $neo-cherry-red;
      font-size: 14px;
      line-height: 16px;
      text-decoration: underline;
      cursor: pointer;
      @include desktop {
        width: auto;
        color: black;
      }
      
      .icon {
        display: block;
        @include desktop {
          display: none;
        }
      }
      .questions {
        display: none;
        @include desktop {
          display: block;
        }
      }
      .description {
          display: block;
      }
    }
  }
}

//   PROGRESS
.quizTool__progressbar {
  height: 8px;
  background: $bosch-blue;
  border-top-right-radius: 20px;
  transition: all 0.3s;
}
.quizTool__progress-indicator {
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $neo-dark-gray;
  margin: 0 auto;
  max-width: $maxWidthComponent;
  padding: 0 16px;
}
.quizTool__content, .quizTool__calculatedResult, .quizTool__alternative .title-container {
  overflow: visible;
  max-width: $maxWidthComponent;
  margin: 0 auto;
  padding: 0 16px 32px;
  position: relative;
}
// CONTROLS
.quizControls {
  display: block;
  position: absolute;
  bottom: 48px;
  right:0;
  z-index: 20;
  &.hide {
    display: none;
  }
  @include until-tablet {
    position: relative;
    bottom: 0;
    max-width: $maxWidthComponent;
    margin: 0 auto;
    padding: 0 16px 32px; 
  }
}
.quizTool__results {
  & + .quizControls {
    top:0;
  }
}

// CONTENT
.quizTool__content {


  // QUESTION
  .question__quiz-title {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
  }
  .question__title {
    margin: 24px 0 8px;
    font-size: 32px;
    width: 80%;
    font-weight: normal;
    line-height: 1.25;

    @include desktop {
      font-size: 42px;
    }
  }
  .question__description {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    margin: 0;
    margin-bottom: 24px;
    max-width: 80%;
    
  }
  .question__progress-text {
    color: $grey;
    font-size: 12px;
    span {
      font-weight: bold;
    }
  }
}

.quizTool__content {
  position: relative;
  .question__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: none;
    overflow: visible;
    &.show {
      display: block;
      z-index: 1;
      animation: showQuestion 0.3s;
    }
    &.hide {
      display: none;
    }
  }

  @keyframes showQuestion {
    from {
      opacity: 0;
      height: 0;
      transform: translatex(100%);
    }
    to {
      opacity: 1;
      height: 100%;
      transform: translateX(0);
    }
  }

  //   ANSWER
  .answer_container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    padding: 16px 0;

  }
  .answer__block {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    border: solid 2px $lightgrey;
    border-radius: 2px;
    background-color: white;
    margin-bottom: 8px;
    transition: all 0.3s;
    cursor: pointer;
    &.active {
      border-color: $bosch-blue;
      > .answer__text {
        color: $bosch-blue;
        font-weight: bold;
      }
    }
    &:hover,
    &:focus {
      border-color: grey;
    }
  }
  .answer__text {
    display: block;
    font-size: 17px;
    line-height: 1.4;
  }
  .answer__indicator {
    background-color: $bosch-blue;
    color: white;
    display: flex;
    flex: 0 0 24px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-transform: uppercase;
  }
}
.quizTool__results {
  .quizTool__back-container {
    .cta {
      padding-left: 20px !important;
      text-underline-offset: 2px;
      font-size: 16px;
      line-height: 1;
      &:before {
        height: 12px;
        width: 12px;
        left:0;
      }
    }
  }
}
.quizTool__results .quizTool__alternative {
  background-color: $lightgrey;
  padding: 40px 0;
  .subtitle {
    font-size: 24px;
    line-height: 30px;
  }
  .alternative-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 326px);
    gap: 32px;
    max-width: $maxWidthComponent;
    padding: 0 16px;
    margin: 0 auto;

    @include until-tablet {
      display: flex;
      flex-direction: column;
    }
  }
  .alternative-card {
    width: 326px;
    height: 100%;

    @include until-tablet {
      width: 100%;
    }
  }
  .heading {
    background-color: #e6e6e6;
    height: 183px;
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    margin-bottom:0;
    .image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .body {
    background-color: white;
    padding: 24px 16px calc(16px + 24px);
    height: calc(100% - 183px);
    position: relative;

    @include until-tablet {
      padding-bottom: calc(24px + 32px);
    }

    .title {
      font-size: 20px;
      line-height: 25px;
      font-weight: normal;
      margin:0 0 8px 0;
    }
    .description {
      max-width: 100%;
    }
    .button {
      position: absolute;
      bottom: 24px;
      left: 16px;
    }
  }
}
// FOOTER
.quizTool__footer {
  display: flex;
  max-width: 1024px;
  padding: 0 16px;
  margin: 0 auto;
  gap: 36px;
  &-block {
    display: flex;
    padding: 24px;
    padding-left: 0;
  }
  &-block-title {
    font-size: 24px;
    font-weight: normal;
    max-width: 300px;
    margin-bottom: 36px;
  }
  &-block-image {
    height: 180px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  @include until-tablet {
    display: block;
    &-block {
      display: block;
      padding: 24px;
    }
    &-block-image {
      transform: translateX(32px);
      height: 250px;
    }
  }

}

.reclame__block {
  margin-top: 16px;
  width: 100%;
  height: calc((650 / 1600) * 100%);
  position: relative;
  overflow: hidden;
  background: white;
}
.reclame__bg-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  min-width: 100%;
  height: auto;
  display: block;
  opacity: 0.9;
}
.reclame__content {
  z-index: 1000;
  display: block;
  position: relative;
  padding: 16px;
  max-width: $maxWidthComponent;
  margin: auto;
  .title {
    font-size: 28px;
    width: 90%;
    max-width: 500px;
    line-height: 1.63;
    margin: 0;
    color: $black;
    
    @include desktop {
      max-width: 500px;
      font-size: 32px;
    }
  }
}

// RESULT

.quizTool__results {
  .part-title {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
  }
  .title {
    margin: 24px 0 28px 0;
    font-size: 32px;
    width: 80%;
    font-weight: normal;
    line-height: 1.1;

    @include desktop {
      font-size: 42px;
    }
  }
  .description {
    font-size: 16px;
    line-height: 1.4;
    font-weight: normal;
    margin: 0;
    margin-bottom: 55px;
    max-width: 80%;
    > * {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    @include until-tablet {
      margin-bottom: 64px;
      max-width: none;
    }
  }
  
  .heading {
    font-size: 16px;
    line-height: 20px;
    color: $bosch-blue;
    margin-bottom: 16px;
  }
  .quizTool__calculatedResult {
    display: flex;
    align-items: center;
    
    @include until-tablet {
      display: block;
    }

    .product-container {
      max-width: 320px;
      max-height: 336px;
      padding: 23px 46px;
      background-color: $lightgrey;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .cta-container {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: baseline;
      gap: 16px;

      @include until-tablet {
        display: flex;
        flex-direction: column;
      }

      .button {
        max-width: none;
        width: fit-content;
        a {
          text-decoration: none;
          color: white;
        }
      }
      a {
        display: block;
      }
    }
  }
}
.result_btn {
  margin: 32px 0 32px 0;
}



.quizTool__results__cta{
  background: none !important;
  .block__content{
    grid-column: 1/15;
  }
  & > .container{
    @include desktop{
      max-width: 100%;
      grid-template-columns: 10px repeat(12, 1fr) 10px;
    }
  }
}
@media screen and (max-width: 412px) {
  .quizTool__header {
    .quizTool__header-content {
      justify-content: flex-start;
      svg {
        width: 180px
      }
    }
  }
}