
  .neo-co2-infographic__outer {
    display: block;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .neo-co2-infographic__inner {
      max-width: 100%;
      margin: 0 auto;
      width: 100%;
      height: auto;
      background: white;
      @include desktop {
        max-width: 50%;
        max-width: 750px;
      }
    }
    .infographic-part__container {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      .infographic-part__image {
        position: relative;
        display: block;
        width: 100%;
      }
      .infographic-part__links__outer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
      }
      .infographic-part__links__inner {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .infographic-part__links__inner {
      .infographic-link {
        position: absolute;
        display: block;
        background: transparent;
        width: 40px;
        height: 40px;
        border: 2px solid transparent;
        transition: all 0.3s;
        &:hover,
        &:focus {
          border: 2px solid #78bd23;
        }
        &.co2-neutraal {
          left: 68%;
          top: 8%;
          width: 21%;
          height: 45%;
          @include tablet {
            left: 71%;
            top: 15%;
            width: 16%;
            height: 42%;
          }
        }
        &.nederland {
          width: 9%;
          height: 12%;
          left: 62%;
          top: 30%;
          @include tablet {
            height: 15%;
            top: 29%;
          }
        }
        &.spanje {
          left: 41%;
          top: 57%;
          height: 30%;
          width: 24%;
          @include tablet {
            left: 45%;
            top: 56%;
            height: 36%;
            width: 20%;
          }
        }
        &.staatsbosbeheer {
          width: 16%;
          height: 27%;
          left: 8%;
          top: 63%;
          @include tablet {
            width: 13%;
            left: 11%;
            top: 60%;
          }
        }
        &.landlifecompany {
          width: 16%;
          height: 27%;
          left: 25%;
          top: 63%;
          @include tablet {
            width: 13%;
            top: 60%;
          }
        }
        &.warmtepompen {
          width: 26%;
          height: 9%;
          left: 51%;
          top: 3%;
          @include tablet {
            width: 22%;
            left: 53%;
            top: 1%;
          }
        }
        &.vestigingen {
          width: 61%;
          height: 44%;
          left: 4%;
          top: 29%;
          @include tablet {
            height: 45%;
            top: 30%;
          }
        }
        &.recyclen {
          width: 25%;
          height: 15%;
          left: 52%;
          top: 72%;
          @include tablet {
            width: 21%;
            height: 12%;
            left: 53%;
          }
        }
        &.recyclen-logo {
          left: 8%;
          height: 94%;
          top: -5px;
          width: 32%;
          @include tablet {
            left: 8%;
            height: 94%;
            top: -10px;
            width: 32%;
          }
        }
      }
    }

    .neo__image-loading__picture {
      .neo__image-loading__picture__image {
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        height: auto;
      }
    }
    @include desktop {
      .neo__image-loading__picture {
        .neo__image-loading__picture__image {
          max-width: 50%;
          margin: 0 auto;
          width: 100%;
          height: auto;
        }
      }
    }
  }
