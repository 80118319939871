// USP-STEPS__HEADER
.usp-steps__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  justify-content: center;
  .usp-steps__indication {
    color: $blue;
  }
  .usp-steps__step-header-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin-bottom: 16px;
  }
  .usp-steps__step-header-image {
    max-height: 100%;
    height: 100%;
  }

  .usp-steps__header {
    width: 100%;
    text-align: center;
    margin: 32px 0 64px 0;
    .usp-steps__header-inner {
      max-width: 568px;
      margin: 0 auto 0 auto;
    }
    .wysiwyg__container {
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      a {
        color: $blue;
      }
    }
  }
  .usp-steps__header-title {
    font-size: 36px;
    font-weight: normal;
    text-align: center;
    margin: 0;
    margin-bottom: 24px;
    @include tablet {
      font-size: 48px;
    }
  }
}

// USP-STEPS__STEPS
.usp-steps__container {
  .usp-steps__steps {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    justify-content: center;
    &:after {
      display: block;
      content: "";
      background: $lightgrey;
      height: calc(100% - 48px);
      width: 100vw;
      transform: translateX(-50%);
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -1;
    }
  }

  .usp-steps__step {
    position: relative;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: inset 0 -8px 0 0 $blue;
    border: solid 1px $grey;
    background-color: $white;
    padding: 32px 24px 32px 24px;
    width: 100%;
    margin-bottom: 32px;
    box-sizing: border-box;
    position: relative;
    @include desktop {
      width: calc(((100% - 64px) / 3));
      margin-right: 32px;
      margin-bottom: 0px;
    }
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  .usp-steps__step-inner {
    max-width: 368px;
    height: 100%;
    margin: 0 auto 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    > div{
      max-width: 100%;
    }
  }

  .usp-steps__step-header {
    font-size: 24px;
    line-height: 1.33;
    margin: 0;
    margin-bottom: 16px;
    & + p {
      margin-top: 0;
    }
  }
}

// CTA/LINK
.usp-steps__steps {
  .usp-steps__step-inner {
    .cta__button {
      width: 100%;
      margin: 20px auto 0 auto;
    }
  }
  .usp-steps__cta__link {
    color: $blue;
    margin: 0 auto;
    padding: 0 24px 24px;
    @include desktop {
      padding: 24px;
    }
  }
}
