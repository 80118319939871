.l-cards-grid {
  display: grid;
  row-gap: 80px;

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
  }
}

.c-card {
  &__title {
    font-family: $ff-bosch-sans;
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  .image__container {
    position: relative;
    aspect-ratio: 21/9;
    overflow: hidden;
  }
}
