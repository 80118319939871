.block--contactperson-block {
  .container {
    padding: 0;
    @include desktop {
    }
  }

  .block--outer {
    width: 100%;
    grid-column: 1/15;
    @include desktop {
      grid-column: 2/14;
    }

    .block--title {
      text-align: center;
      margin: 40px 0 36px 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      @include desktop {
        margin: 80px 0 56px 0;
        flex-direction: row;
        justify-content: center;
      }
    }

    .block--inner {
      width: 100%;
      @include tablet {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 33px;
      }

      .block--block {
        display: flex;
        margin: 0 25px 40px 25px;
        @include desktop {
          margin: 0 0 80px 0;
        }

        .block--content {
          margin: 0;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          background: $white;
          @include desktop {
            padding: 18px 20px;
            flex-direction: row;
          }

          .image__container {
            width: 100%;
            height: 185px;
            @include desktop {
              min-width: 168px;
              width: 168px;
              height: 168px;
            }

            .image {
              object-fit: cover;
            }
          }

          .block--info {
            padding: 24px;
            flex: 1;
            @include desktop {
              padding: 0 18px 18px 18px;
            }

            .block--name {
              font-weight: 700;
              font-size: 20px;
              margin: 0 0 16px 0;
              @include desktop {
                font-size: 18px;
                margin: 0;
              }
            }

            .block--function {
              margin: 0 0 20px 0;
              font-size: 18px;
            }

            .block--contact {
              .block--email,
              .block--phone {
                display: flex;
                align-items: center;
                font-size: 16px;
                padding: 16px 0 0 0;
                @include desktop {
                  padding: 0;
                }

                a {
                  padding: 0 0 0 16px;
                  @include desktop {
                    padding: 0 0 0 10px;
                  }
                  @include on-event {
                    color: $neo-cherry-red;
                  }
                }

                figure {
                  width: 18px;
                  display: flex;
                }
              }

              .block--email {
                a {
                  color: $hoverblue;
                }
              }
            }
          }
        }
      }
    }
  }
}
