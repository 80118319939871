.product-specs {
  max-width: 100%;
  overflow-x: auto;

  .product-specs_table-container {
    width: fit-content;
    min-width: 100%;

    .product-specs_table-container_header {
      background: $blue;
      color: white;

      font-weight: bold;

      div {
        padding: 8px 0 8px 16px;
        display: inline-block;
        text-align: right;

        &:first-of-type {
          text-align: left;
        }
      }
    }

    background: $lightgrey;

    table {
      padding: 0 20px 20px 20px;
      width: 100%;

      tr {
        th {
          text-align: left;
          color: $black;
          padding: 16px 40px 8px 0;
          border-bottom: 1px solid white;
          white-space: nowrap;
          min-height: 35px;
        }

        td {
          padding: 8px 40px 8px 0;
          color: $grey;
          border-bottom: 1px solid white;

          &:first-of-type {
            color: $black;
          }
        }

        .table-data {
          min-height: 35px;
          a {
            color: $black;

            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }

  .product-specs_disclaimer {
    font-size: 12px;
    color: $grey;
    padding: 8px 24px;
  }
}
