.block--grid-blocks {
  .block--outer {
    width: 100%;
    padding: 20px 0 0 0;
    grid-column: 2/14;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 33px;
      max-width: 966px;
      margin: 0 auto;
    }

    .block--block {
      display: flex;
      flex-direction: column;
      margin: 0 0 60px 0;
      height: unset;

      .image__container {
        width: 100%;
        height: 183px;
        @include tablet {
          height: 262px;
        }

        .image {
          object-fit: cover;
        }
      }

      .block--content {
        padding: 15px 25px 33px 25px;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: $white;
        height: 100%;
        flex: 1;

        .block--title {
          margin-bottom: 22px;
          display: flex;
          align-items: center;
          height: 55px;
        }

        .block--text {
          flex: 1;
        }
      }
    }
  }
}
