.neo-epsilon {
  border-right: 2px #000 solid;
  border-top: 2px #000 solid;

  &.neo-epsilon-blue {
    border-right: 2px $neo-blue solid;
    border-top: 2px $neo-blue solid;
  }

  &.neo-epsilon-white {
    border-right: 2px $white solid;
    border-top: 2px $white solid;
  }

  &.neo-epsilon-red {
    border-right: 2px $neo-dark-red solid;
    border-top: 2px $neo-dark-red solid;
  }

  &.neo-epsilon-gray {
    border-right: 2px $neo-dark-gray solid;
    border-top: 2px $neo-dark-gray solid;
  }
  width: 10px;
  height: 10px;

  transform: rotate(45deg);

  &.neo-epsilon-left {
    transform: rotate(-45deg) scaleX(-1);
  }

  &.neo-epsilon-up {
    transform: rotate(-45deg);
  }
}

.neo-epsilon-pseudo-after {
  position: relative;

  &:after {
    pointer-events: none;
    right: 16px;
    top: calc(50% - 6px);
    position: absolute;
    content: "";
    border-right: 1px #000 solid;
    border-top: 1px #000 solid;
  }

  &.neo-epsilon-blue:after {
    border-right: 1px $neo-blue solid;
    border-top: 1px $neo-blue solid;
  }

  &.neo-epsilon-white:after {
    border-right: 1px $white solid;
    border-top: 1px $white solid;
  }

  &.neo-epsilon-red:after {
    border-right: 1px $neo-dark-red solid;
    border-top: 1px $neo-dark-red solid;
  }

  &:after {
    width: 10px;
    height: 10px;

    transform: translateY(-25%) rotate(135deg);
  }

  &.neo-epsilon-up:after {
    transform: translateY(-25%) rotate(-45deg);
  }

  .neo-epsilon-pseudo-after-padding {
    padding-right: 32px;
    margin-right: 0px !important;
  }
}
