.neo__loading {
  $neo-loader-size: 92px;
  $neo-dot-size: 12px;
  width: $neo-loader-size;
  height: $neo-loader-size;
  
  @include until-desktop {
    width: $neo-loader-size * 0.8;
    height: $neo-loader-size * 0.8;
  }

  div:after {
    position: absolute;
    content: "";
    width: $neo-dot-size;
    height: $neo-dot-size;

    background: $black;
    top: 0;
    right: 0;
    border-radius: 99%;
    transform: translateY(-50%) scale(0);

    @include until-desktop {
      width: $neo-dot-size * 0.8;
      height: $neo-dot-size * 0.8;
    }
  }

  @for $i from 1 through 12 {
    div:nth-child(#{$i}) {
      width: 100%;
      height: 0;
      position: relative;
      top: calc($neo-loader-size/2);
      transform: rotate(calc((360 / 12) * $i) deg);

      &:after {
        animation: neo-pulse 1s calc(1 / 12 * $i) s infinite;
      }
    }
  }
}

@keyframes neo-pulse {
  0%,
  100% {
    transform: translateY(-50%) scale(0);
  }
  50% {
    transform: translateY(-50%) scale(1);
  }
}
