$svg-size-dt: 16px;
$svg-size-mob: 18px;

.block--quick-links {
  .container {
    padding: 0;
  }

  .block--outer {
    grid-column: 1/15;
    width: 100%;
    padding: 20px 0;
    @include tablet {
      grid-column: 2/14;
      background: none;
      padding: 0;
    }

    .block--inner {
      display: flex;
      flex-direction: column;
      @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        flex-wrap: wrap;
      }

      .block--cta--container {
        display: flex;
        background: $white;
        margin: 0 0 8px 0;
        padding: 20px 18px 20px 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        border: none;
        cursor: pointer;
        color: $black;
        text-decoration: none;
        width: 100%;
        @include tablet {
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          width: 165px;
          margin: 20px 0 0 0;
          padding: 0;
          background: none;
        }

        &:last-child {
          margin: 0;
          @include tablet {
            margin: 20px 0 0 0;
          }
        }

        .image__wrapper {
          height: 30px;
          width: 50px;
          margin: 0 12px 0 0;
          @include tablet {
            height: 50px;
            width: 215px;
            margin: 0 0 13px 0;
            display: flex;
            justify-content: center;
          }
        }
        .image__container {
          width: 50px;
          height: 50px;
        }

        .block--cta {
          display: flex;
          align-items: center;
          justify-content: space-between;
          word-break: unset;
          cursor: pointer;
          color: $black;
          text-decoration: none;
          width: 100%;
          padding: 0;
          margin: 0;
          font-size: 14px;
          @include tablet {
            flex-direction: column;
            align-items: center;
            width: 215px;
          }

          span {
            @include tablet {
              margin-bottom: 13px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }

          .icon {
            width: $svg-size-mob;
            height: $svg-size-mob;
            @include tablet {
              width: $svg-size-dt;
              height: $svg-size-dt;
            }

            svg {
              fill: $black;
              width: $svg-size-mob;
              height: $svg-size-mob;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              transition: all 0.2s;
              margin: 0;
              @include tablet {
                width: $svg-size-dt;
                height: $svg-size-dt;
              }
            }
          }

          &:hover {
            color: $blue;
            svg {
              fill: $blue;
            }
          }
        }
      }
    }
  }
}
