.faq-block__outer {
  &.full-width {
    .faq-block__aside,
    .faq-block__main {
      width: 100%;
    }
  }
  .faq-block__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .faq-block__aside {
    width: 100%;
    @include tablet {
      width: calc((100% / 3) - 32px);
    }

    .faq-block__aside__buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .link {
        font-size: 16px;
        padding: 15px;
        text-decoration: none;
        margin: 0;
        text-align: left;
        color: $neo-faq-link-text-color;
        width: 100%;
        // @include mobile-only {
        //   width: 100%;
        // }

        &:hover,
        &:focus {
          color: $black;
        }

        &.active {
          color: $black;

          &:after {
            display: block;
            content: "";
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid $neo-faq-active-link-color;
            position: absolute;
            left: 0;
            top: calc(50% - 5px);
          }
        }
      }
    }
  }

  .faq-block__main {
    width: 100%;
    @include tablet {
      width: calc((100% / 3) * 2);
    }

    .faq-block__main__title {
      margin: 18px 0;
      display: block;
      font-weight: bold;
    }

    .faq-block__main__question__outer {
      border-bottom: 1px solid $neo-faq-active-link-color;

      &.open {
        .faq-block__main__question {
          color: $neo-blue;
          &:after {
            border-top: 3px solid $neo-blue;
            border-right: 3px solid $neo-blue;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
        .faq-block__main__answer {
          color: $neo-blue;
        }

        .faq-block__main__answer {
          max-height: 600px;
          padding: 0 24px 24px;
        }
      }
    }

    .faq-block__main__question {
      padding: 20px 60px 20px 24px;
      background: white;
      position: relative;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 16px;
      font-weight: 700;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-top: 3px solid $black;
        border-right: 3px solid $black;
        display: block;
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        transform: rotate(135deg);
        transition: all 0.3s;
      }
    }

    .faq-block__main__answer {
      max-height: 0;
      overflow: hidden;
      padding: 0 24px;
      transition: all 0.3s;
    }
  }
}
