.vakmanschaps-header {
  height: 1350px;
  margin: 0;
  position: relative;
  background: center center no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 80px;
  padding-top: 20px;

  @include tablet {
    padding-top: 120px;
  }

  .header-text {
    max-width: 500px;

    h2 {
      color: white;
      font-size: 36px;
      text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
      word-break: break-word;

      @include tablet {
        font-size: 48px;
      }
    }

    p {
      text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
      font-size: 24px;
      line-height: 1.33;
      color: white;
    }
  }

  .scroll-que {
    background: url("https://s3.eu-west-1.amazonaws.com/np-nefit/Iconen/scroll-que.svg") center center no-repeat;
    width: 16px;
    height: 160px;
    position: absolute;
    bottom: -80px;
    left: calc(50% - 8px);
  }
}