.block--neo-social-share-bl {
  .block--outer {
    margin-top: 16px;
    grid-column: 2/14;

    .block--header {
      .block--text {
        font-size: 16px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        @include tablet {
          flex-direction: row;
        }
      }
    }

    .block--buttons {
      display: flex;
      justify-content: center;
      @include tablet {
        justify-content: flex-start;
      }

      &--link {
        margin: 4px;
        height: 40px;
        width: 40px;
        border-radius: 99%;
        background-color: $white;
        transition: background-color 0.5s;
        border: 2px solid $black;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        figure {
          width: 22px;
          height: 22px;
          position: relative;

          svg {
            position: absolute;
            width: 100%;
            height: 100%;
            fill: $black;
            left: 0;
            transition: fill 0.5s;
          }
        }

        &:hover,
        &:focus {
          background-color: $black;

          figure {
            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }
}
