.neo__related-articles-block__outer {
  background-color: $neo-light-gray;

  .neo__related-articles-block__inner {
    grid-column: 2/14;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-bottom: 0;

    .neo__related-articles-block__title {
      margin-bottom: 30px;
      margin-top: 0;
      text-align: center;
      font-size: 36px;
      font-weight: normal;
      @include desktop {
        font-size: 48px;
      }
    }

    .neo__related-articles-block__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0;
      padding: 0;

      li {
        background: white;
        flex: 0 0 100%;
        margin-right: 0;
        overflow: hidden;
        padding: 0;
        margin-bottom: 16px;
        @include tablet {
          margin-bottom: 0;
          @include flex-width(calc(100% / 3), 22px);
          margin-right: 32px;
          box-sizing: border-box;
          &:nth-of-type(2) {
            margin-right: 32px;
          }
          &:nth-of-type(3) {
            display: block;
            margin-right: 0;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .neo__related-articles-block__list-link {
      width: 100%;
      height: 100%;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      &:hover,
      &:focus {
        .image__container .image {
          transform: scale(1.2);
        }
      }

      .image__container {
        width: 100%;
        min-height: 250px;
        background: #e6e6e6;
        @include desktop {
          min-height: 250px;
        }
        .image {
          object-fit: cover;
          transition: all 0.3s;
        }
      }

      .neo__related-articles-block-image__author-bg {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 100%
        );
      }

      img {
        position: absolute;
        right: 0;
        bottom: 0;
        transition: all 0.5s;
      }
    }

    .neo__related-articles-block__content {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 32px 32px 64px 32px;
      height: 100%;
      box-sizing: border-box;
      background: white;

      .neo__related-articles-block__content-title {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: black;
        margin-bottom: 0;
      }

      .neo__related-articles-block__content-description {
        margin: 8px 0;
        padding-bottom: 2em;
        color: #000;
        height: 92px;
        @include limit-lines(5);
        overflow: hidden;
      }

      .neo__related-articles-block__content-date {
        font-size: 12px;
        margin: 0;
        padding: 8px 16px;
        color: $neo-date-text-color;
        text-align: right;
        padding: 0;
        position: absolute;
        bottom: 32px;
        right: 32px;
      }
    }
  }
}
