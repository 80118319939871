.neo__article-text-block__outer {
  display: block;
  width: 100%;
  box-sizing: border-box;

  .neo__article-text-block__content {
    grid-column: 2/14;

    &.align-center {
      text-align: center;
      margin: 0 auto;
      @include tablet {
        max-width: 80%;
      }
    }
  }

  .neo__article-text-block__content-intro {
    .neo__article-text-block__content__title {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .neo__article-text-block__content__date {
      margin-bottom: 0;
      font-size: 12px;
      color: $neo-dark-gray;
    }
  }
  .neo__article-text-block__cta__list {
    margin-top: 32px;
    justify-content: flex-start;
    margin-left: 0;
    padding-left: 0;
  }
}

.neo__content-block {
  .neo__article-text-block__content {
    grid-column: 2/14;
  }
}
