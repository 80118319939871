@use "sass:color";

.neo__tabs_outer {
  position: relative;
  grid-column: 2/14;
  padding: 0 24px;

  .neo__tabs-tab-holder {
    display: flex;
    justify-content: stretch;
    background-color: $white;
    @include until-tablet {
      flex-wrap: wrap;
      & > div {
        width: 100%;
      }
    }

    .neo__tabs-tab-button {
      flex: 1;
      line-height: 24px;
      font-size: 16px;
      padding: 16px 32px;
      color: $black;
      border: solid 1px $neo-dark-gray;
      position: relative;
      @include until-tablet {
        @include flex-width(100%, 0);
      }

      &:not(.active) {
        &:hover,
        &:focus {
          cursor: pointer;
          background-color: rgba($neo-blue, 0.2);
        }
      }

      &:not(:first-child) {
        @include tablet {
          border-left: unset;
        }
        @include until-tablet {
          border-top: unset;
        }
      }

      &:not(:last-child) {
        @include tablet {
          border-right: unset;
        }
        @include until-tablet {
          border-bottom: unset;
        }
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        border-radius: 1px;
        background-color: $neo-dark-gray;
        transform: translate(-50%, -50%);
        @include tablet {
          height: 80%;
          width: 1px;
          right: -1px;
          top: 50%;
          transition: opacity 0.2s, height 0.4s;
        }
        @include until-tablet {
          width: 90%;
          height: 1px;
          bottom: -1px;
          left: 50%;
          transition: opacity 0.2s, width 0.4s;
        }
      }

      &:before {
        @include tablet {
          left: 0;
          right: unset;
        }
        @include until-tablet {
          top: 0;
          bottom: unset;
        }
      }

      &:first-child:before,
      &:last-child:after,
      &.active:before,
      &.active:after,
      &.active-left:before,
      &.active-right:after {
        opacity: 0;
        transition: none;
        @include until-tablet {
          width: 75%;
        }
        @include tablet {
          height: 55%;
        }
      }

      &.active {
        background-color: $neo-blue;
        border-color: $neo-blue;
        color: $white;

        &:hover,
        &:focus {
          background: color.scale($red, $lightness: -5%);
          border-color: color.scale($neo-blue, $lightness: -5%);
        }
      }
    }
  }

  .neo__tab {
    width: 100%;
    position: relative;
    margin-top: 64px;

    .neo__tab-content-holder {
      width: 100%;

      .neo__tab-content {
        padding: 64px 0;

        &:first-child {
          padding-top: 0;
        }

        display: flex;
        justify-content: space-between;
        @include until-tablet {
          flex-wrap: wrap;
        }

        &:not(:last-child) {
          border-bottom: solid 1px $neo-dark-gray;
        }

        .neo__tab-content-content {
          @include flex-width(50%, 16px);
          @include until-tablet {
            @include flex-width(100%, 0);
          }

          &.neo__tab-content-content-full {
            @include flex-width(100%, 0);
          }

          .neo__tab-content-date {
            font-size: 16px;
            font-weight: bold;
            line-height: 2;
            color: $neo-blue;
          }

          .neo__tab-content-title {
            padding-bottom: 24px;
          }

          .neo__tab-content-text {
            padding-bottom: 16px;
          }

          .neo__tab-content-ctas {
            &.neo__cta-list {
              margin: 24px 0;
              flex-direction: column;
              align-items: flex-start;
            }
            .neo__cta {
              margin: 12px 0;
              display: block;
              width: max-content;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .neo__tab-content-media {
          display: flex;
          justify-content: center;
          align-items: center;

          @include flex-width(50%, 116px);
          @include until-tablet {
            @include flex-width(100%, 0);
            min-height: 250px;
            margin-top: 48px;
          }

          .neo__tabs-image-holder {
            position: relative;
            width: 100%;
            height: 100%;

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              max-width: 100%;
              max-height: 100%;
            }
          }
          &.neo__tab-content-media-youtube {
            > div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .neo__tabs_background {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 32px;
    z-index: -1;
    background-color: $neo-light-gray;
  }

  .neo__faq-page-block__outer {
    padding: 0 !important;
    margin-top: 0 !important;
  }
}
