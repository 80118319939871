.product-line-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  padding-bottom: 8px;
  border-bottom: 1px solid $lightgrey;
  margin: 0 auto;
  @include tablet {
    display: flex;
  }

  .product-link {
    float: left;
    margin-right: 80px;
  }

  .product-link {
    width: 120px;
    margin: 8px 0;
    margin-right: 60px;
    margin-left: 60px;
    opacity: 0.5;
    text-decoration: none;
    color: $black;
    text-align: center;
    transition: opacity 0.2s ease-in-out;
    margin-bottom: 30px;

    @media only screen and (max-width: 770px) {
      margin-right: 30px;
      margin-left: 30px;
    }

    &.active {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    .product {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .product-image {
        height: 88px;
        margin-bottom: 8px;
        align-self: center;
      }
    }
  }
}

.go-back-link {
  position: relative;
  left: 0px;
  text-decoration: none;
  color: $black;
  display: block;
  margin-bottom: 20px;

  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #e20015;
    border-bottom: 2px solid #e20015;
    transform: rotate(-225deg);
    margin-right: 10px;
    transition: 0.3s transform;
  }

  &:hover {
    color: $red;
  }
}
