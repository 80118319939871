.text-block-w-image {
  .downloads {

    .downloads-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 32px;

      li {
        a {
          color: $black;
          transition: color 0.1s ease-in-out;

          &:hover {
            color: $red;
            transition: color 0.1s ease-in-out;
          }
        }
      }
    }
  }

  .text-block-title {
    font-size: 24px;
    margin-bottom: 16px;

    @include tablet {
      font-size: 48px;
      margin-top: 0;
    }

    &.reverse {
      text-align: right;
    }
  }

  .text-content {
    p {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .text-block-image {
    max-height: 400px;
    max-width: 100%;
    margin-bottom: 16px;
    margin-top: 32px;
    width: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
      object-position: top;
    }

    @include tablet {
      margin-top: initial;
      object-fit: contain;

      &.large {
        max-height: initial;
      }
    }
  }

  .cta-list {
    display: flex;
    flex-direction: column;

    .cta__button {
      margin-bottom: 16px;
    }
  }

  .lazy-load-container {
    width: 100%;
  }

  .list-title {
    margin-bottom: 10px;
  }

  .downloads-list {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 10px;
      width: calc(100% - 20px);
      overflow: hidden;
    }
  }


  .downloads-list {
    li {
      a {
        height: 40px;
        padding: 0;
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        color: $black;
        display: flex;
        align-items: center;
      }

      .download_icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        stroke: $black;
        fill: $black;
      }
    }
  }
}