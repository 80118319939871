.block--neo-banner-block {
  .container {
    padding: 0;
  }
  .block__header,
  .block__content {
    grid-column: 2/14;
  }
  &.block--fullscreen {
    .block__content {
      grid-column: 1/15;
      width: 100%;
      @include tablet {
        display: grid;
        grid-template-columns: $container-padding repeat(12, 1fr) $container-padding;
        grid-column-gap: $grid-gap;
        max-width: 100%;
        padding: 0;
      }
      @include container-min {
        grid-template-columns:
          1fr repeat(12, calc((#{$grid-width} / 12) - #{$grid-gap}))
          1fr;
      }

      .block__partial {
        grid-column: 2/14;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .block__header {
    .block--title {
      text-align: center;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      @include desktop {
        flex-direction: row;
      }
    }

    .block--description {
      @include mobile-only {
        padding: 0 24px;
      }
    }
  }

  .block__content {
    position: relative;
    display: flex;
    flex-direction: column;

    .banner__overlay {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to right,
        rgba($neo-light-gray, 0.3),
        transparent
      );
      z-index: 1;
    }

    .banner__image {
      position: absolute;
      width: 100%;
      height: 100%;

      &.relative {
        position: relative;
      }

      .image {
        position: relative;
        object-fit: cover;
      }
    }

    .block__partial {
      padding: 64px 24px;
      z-index: 2;
      max-width: 600px;
    }

    .block__partial--title {
      text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $white;
    }

    .block__partial--description p {
      font-size: 24px;
      line-height: 1.63;
      color: $white;
      text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    }

    .block__partial--cta {
      margin-top: 25px;
    }
  }
}
