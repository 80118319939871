.block--neo-iframe-block {
  .block__content {
    width: 100%;
    grid-column: 2/14;
    display: flex;
    justify-content: center;
  }
  .iframe__container {
    background: white;
    border: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    iframe {
      border: none;
    }
  }
}



