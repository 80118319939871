.blog-latest {
  &__outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    padding-bottom: 110px;
  }

  &__items {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  &__item {
    border: 1px solid #f1f1f1;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 400px;
    width: 100%;

    @include tablet {
      width: calc(33.3% - 20px);
      max-width: none;
      margin-right: 30px;
    }

    &:last-of-type {
      @include tablet {
        margin-right: 0;
      }
    }
  }

  &__preview {
    height: 180px;
    width: 400px;
    min-width: 100%;
    position: relative;
    overflow: hidden;

    img {
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &__content {
    padding: 10px;
    height: 60px;
    overflow: hidden;
    position: relative;
  }

  &__title {
    margin-bottom: 30px;
    margin-top: 0;
    font-weight: 500;
    text-align: center;
  }

  &__subtitle {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    max-height: 40px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__date {
    font-size: 12px;
    color: grey;
    font-weight: 400;
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 10px;
  }
}

.blog-all {
  &__outer {
    background: #f1f1f1;
  }
}

.blog-navigator {
  &__outer {
    display: flex;
    border-radius: 5px;
    width: 100%;
    border: 1px solid grey;
    position: relative;
    flex-direction: column;
    height: 100px;
    top: -50px;

    @include tablet {
      flex-direction: row;
      height: 60px;
      top: -30px;
    }
  }

  &__title {
    background: #235e87;
    padding: 15px 10px;
    width: calc(100% - 40px);
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 5px 0 0 5px;
    padding-left: 30px;
    justify-content: center;

    @include tablet {
      justify-content: flex-start;
    }

    h3 {
      font-size: 20px;
      color: white;
      margin: 0;

      @include tablet {
        font-size: 25px;
      }
    }
  }

  &__selector {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 200px;
    background: white;
    justify-content: center;
    text-align: center;
    border-radius: 0 0 5px 5px;

    @include tablet {
      width: auto;
      text-align: right;
      padding: 0 50px;
      justify-content: flex-end;
      border-radius: 0 5px 5px 0;
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: 15px;

      @include tablet {
        font-size: 17px;
      }
    }
  }
}

.blog-all-items {
  &__outer {
    padding: 80px 0;
    padding-bottom: 20px;
  }

  &__ruler {
    width: 100%;
    height: 1px;
    background: #b6b6b6;
  }

  &__loadmore {
    position: relative;
    width: 100%;
    padding-bottom: 80px;
    cursor: pointer;

    &-text {
      position: absolute;
      text-align: center;
      top: -25px;
      width: 100px;
      left: calc(50% - 50px);
      z-index: 100;
      background: #f1f1f1;
    }
  }
}

.blog-all-item {
  &__outer {
    display: flex;
    border-radius: 5px;
    background: white;
    border-radius: 5px;
    border: 1px solid grey;
    position: relative;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 40px;

    @include tablet {
      flex-direction: row;
      height: 225px;
      max-width: none;
    }
  }

  &__preview {
    height: 200px;
    width: 100%;
    border-radius: 5px 0 0 5px;

    @include tablet {
      width: 370px;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: cover;
      border-radius: 5px 0 0 5px;
    }
  }

  &__content {
    width: calc(100% - 60px);
    padding: 30px;
    position: relative;

    @include tablet {
      width: calc(100% - 370px);
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 800;
  }

  &__date {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: grey;
    font-size: 12px;
  }

  &__boilerplate {
    padding-bottom: 20px;
  }
}


.blog-navigator__date-toggle {
  font-size: 14px;
  cursor: pointer;
  position: relative;
  display: block;

  &:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid $grey;
    border-bottom: 2px solid $grey;
    transform: rotate(45deg);
    margin-left: 20px;
    transition: 0.3s transform;
  }

  &.active {
    &:after {
      transform: rotate(-135deg);
    }
  }
}



.blog-latest__item,
.blog-all-item__outer {
  text-decoration: none;
}

.blog-latest__items {
  display: flex;
  justify-content: center;
  align-items: center;

  .blog-latest__item {
    margin-right: 0;
    margin-left: 0;

    @include tablet {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    } 
  }
}


.blog-latest__outer {
  &.external_content {
    padding: 80px 0 80px 0;
  }
}