.block--anchor-nav{
  .container{
    @include until-tablet{
      position: relative;
      padding: 0;
      &:before,
      &:after{
        content: '';
        position: absolute;
        top: 0;
        width: 30px;
        height: 100%;
        z-index: 1;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%);
      }
      &:before{
        left: 0;
      }
      &:after{
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }
  .anchor-nav__list{
    position: relative;
    grid-column: 2/14;
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 1px 0;
    border-bottom: 1px solid #efeff0;
    @include until-tablet{
      padding: 1px 40px;
      grid-column: 1/15;
    }
  }
  .anchor-nav__list__item{
    padding: 14px 4px 14px 4px;
    border-bottom: 1px solid transparent;
    transition: all 0.3s;
    color: black;
    text-decoration: none;
    cursor: pointer;
    margin-left: 20px;
    display: inline;
    word-break: keep-all;
    white-space: nowrap;
    &:nth-of-type(1){
      margin-left: 0;
    }
    &:hover , &.anchor--active{
      color: $blue;
      border-bottom: 1px solid $blue;
    }
  }
}