.iframe__container{
  display: block;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 0;
  .iframe__video{
    width: 100%;
    height: auto;
    display: block;
    position: relative;
  }
}