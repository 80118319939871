.savings-graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 320px;
    background-image: url("#{ $base-url }gray-light-full-lg.svg");
    background-size: 101%;

    h3 {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 42px;
        color: $hoverblue;
        line-height: 1.05;

        small {
            font-size: 18px;
        }
    }

    p {
        margin-top: 32px;
        max-width: 80%;
        text-align: center;
    }
}

.graph-circle {
    position: relative;
    overflow: hidden;
    padding: 35% 0 0;
    width: 70%;

    &__grey,
    &__active {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        
        width: 100%;
        padding: 50% 0 0;
        transform-origin: bottom;
        transition: transform 1s;

        &:after{
            box-sizing: border-box;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 200%;
            width: 100%;
            border-radius: 100%;
            border: 8px solid #C8C8C8;
        }
    }

    &__active {
        &:after {
            border-color: $hoverblue;
        }
    }
}