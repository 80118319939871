.neo__text-with-graph-block__outer {
  display: flex;
  justify-content: space-between;

  .neo__text-with-graph-block__title.mobile {
    display: none;
  }
  .neo__text-with-graph-block__title {
    width: 100%;
  }

  @include until-desktop {
    flex-wrap: wrap;
  }

  .neo__text-with-graph-block__text-container,
  .neo__text-with-graph-block__graph-container {
    @include flex-width(50%, 16px);
    @include until-desktop {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  .neo__text-with-graph-block__graph-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .neo_chart-container {
      flex: 0;
      width: max-content;
      border: solid 1px $neo-light-gray;
      height: max-content;

      @include tablet-only {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 512px;
        height: 320px;
        flex-direction: column;
      }

      .neo_chart-title {
        width: 232px;
        font-size: 16px;
        line-height: 2;
        font-weight: bold;
        text-align: center;
        margin: 16px 24px;
      }

      .neo_chart {
        position: relative;
        width: 232px;
        height: 232px;
        margin: 4px 24px;
        position: relative;

        #doughnut-chart {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .neo_chart-legends {
        width: 232px;
        margin: 16px 24px;
        @include tablet-only {
          height: 100%;
          padding-top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          padding-bottom: 0;
        }

        .chart-legend {
          height: max-content;
          width: 100% !important;

          .chart-label-item {
            color: $neo-dark-gray;
            font-size: 12px;
            line-height: 1.75;
            align-self: center;

            .label-block {
              margin-right: 8px !important;
              align-self: center;
            }
          }
        }
      }
    }
  }
  .neo__text-with-graph-block__cta__list {
    justify-content: flex-start;
    padding-left: 0;
    margin-left: 0;
  }
}

.neo__text-with-graph-block__outer {
  flex-wrap: wrap;
}
