.info-banner {
  .block--outer {
    width: 100%;

    &.blue {
      background: $neo-light-blue;
    }

    &.turquoise {
      background: $bosch-turquoise;
    }

    &.lightblue {
      background: $bosch-lightblue;
    }

    &.darkgreen {
      background: $bosch-darkgreen;
    }

    &.lightgreen {
      background: $bosch-lightgreen;
    }

    &.fuchsia {
      background: $bosch-fuchsia;
    }

    .block--content {
      max-width: 1600px;
      margin: 0 auto;
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &--icon {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 16px;

        figure {
          padding: 0;

          svg {
            width: 100%;
            height: auto;
            fill: $hoverblue;
          }
        }
      }

      &--text {
        font-size: 16px;
        width: 80%;
        @include tablet {
          width: 85%;
        }

        p {
          margin: 0;
          color: $hoverblue;

          a {
            color: $hoverblue;
            text-decoration: none;
            font-weight: 900;
            @include on-event{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.maxi-banner {
  .block--outer {
    width: 100%;
    margin: 8px 0 0 0;

    &.turquoise {
      background: $bosch-turquoise;
    }

    &.lightblue {
      background: $bosch-lightblue;
    }

    &.darkgreen {
      background: $bosch-darkgreen;
    }

    &.lightgreen {
      background: $bosch-lightgreen;
    }

    &.fuchsia {
      background: $bosch-fuchsia;
    }

    .block--content {
      width: 100%;
      margin: 0 auto;
      padding: 42px 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      @include desktop {
        flex-direction: row;
        max-width: 1600px;
        padding: 40px;
      }

      &--main {
        width: 100%;
        @include desktop {
          width: 50%;
          padding: 0 34px;
        }

        .main--title {
          color: $white;
          @include mobile-only {
            font-size: 24px;
          }
        }

        .main--text {
          font-size: 16px;
          color: $white;
          margin: 16px 0;
          @include desktop {
            margin: 16px 0 34px 0;
          }
        }

        .btn-white {
          background: $white;
          color: $hoverblue;
        }
      }

      &--image {
        width: 100%;
        padding: 34px 0 0 0;
        @include desktop {
          width: 50%;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
