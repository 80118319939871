.neo__share-buttons__outer {
  margin: 16px 0 32px;
  grid-column: 2/14;

  .neo__share-buttons__title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .neo__share-buttons__buttons {
    padding: 4px 0;
    width: calc(100% + 8px);
    margin: 0 -4px;

    .neo__share-buttons__title {
      font-size: 14px;
      margin: 4px;
    }

    a {
      margin: 4px;
      display: inline-block;
      height: 32px;
      width: 32px;
      border-radius: 99%;
      background-color: $white;
      transition: background-color 0.5s;
      border: 2px solid $black;
      overflow: hidden;

      figure {
        width: 22px;
        height: 22px;
        position: relative;
        top: 3px;
        left: 3px;

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          fill: $black;
          left: 0;
          transition: fill 0.5s;
        }
      }

      &:hover,
      &:focus {
        background-color: $black;

        figure {
          svg {
            fill: $white;
          }
        }
      }
    }
  }
}
