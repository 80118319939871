// Basic layout
.neo__footer {
  align-self: flex-end;

  margin-top: 40px;
  @include tablet {
    margin-top: 80px;
  }

  .neo__footer-nav {
    padding: 20px 0 0 0;
    border-top: 1px solid #f1f1f1;
    @include tablet {
      padding: 40px 0 0 0;
    }
    @include desktop {
      padding: 97px 0 0 0;
    }

    .neo__footer-nav__inner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0 auto;
      max-width: 1600px;
      box-sizing: border-box;
      padding: 0 16px;
      @include tablet {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  .nav__social-block {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    @include desktop {
      display: inline-flex;
    }
  }

  .nav__social-block {
    @include desktop {
      width: 234px;
    }
  }

  .nav__nav-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    @include tablet {
      max-width: none;
      margin: 0 auto;
    }
    @include desktop {
      display: inline-flex;
      width: calc((100% - 240px - 240px));
      margin: 0 32px;
      max-width: 1050px;
    }
  }

  .image__container {
    width: 100%;
    height: 8px;
    .image {
      object-fit: cover;
    }
  }
}

// .nav__nav-block
.neo__footer {
  .nav__nav-block,
  .nav__nav-block__item {
    margin: 0;
    padding: 0;
    list-style: none;

    ul,
    li,
    a {
      margin: 0;
      padding: 0;
      list-style: none;
      text-decoration: none;
    }
  }

  .nav__nav-block {
    .nav__nav-block__item {
      font-size: 14px;
      margin-bottom: 12px;
      width: 100%;
      @include tablet {
        width: calc((100% - 16px) / 2);
      }
      @include desktop {
        width: auto;
      }

      &:last-child {
        margin: 0;
      }

      .nav__nav-block__title--container {
        display: flex;
        align-items: center;
        margin: 40px 0 0 0;
        @include tablet {
          margin: 0;
        }

        .icon {
          height: 32px;
          margin: 0 12px 0 0;
        }

        .nav__nav-block__title {
          margin: 0;
          padding: 4px 0 0 0;
          font-size: 16px;
          font-weight: bold;
          height: 32px;
        }
      }
    }

    > .nav__nav-block__item {
      ul {
        margin: 16px 0 0 0;
        @include tablet {
          margin: 16px 0 30px 0;
        }
        @include desktop {
          margin: 16px 0 0 0;
        }
      }
    }
  }
}

//  .nav__social-block
.neo__footer {
  .nav__social-block {
    display: flex;
    flex-direction: column;
    .neo__social-block__title {
      display: block;
      width: 100%;
      margin: 40px 0 0 0;
      font-size: 16px;
      padding: 4px 0 0 0;
      font-weight: bold;
      @include tablet {
        margin: 0;
      }
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      margin-top: 16px;
      list-style: none;
      margin-bottom: 16px;

      .neo__social-block__social-link {
        width: 100%;
        display: block;
        text-align: center;
        list-style: none;

        .neo__cta {
          margin: 0;
          margin-top: 32px;
        }
      }
    }

    .neo__social-block__social-link {
      list-style: none;
    }

    .neo__cta.link.icon {
      margin: 0 14px 0 0;
      padding: 8px;
      border-radius: 50%;
      border: 2px solid black;
      transition: all 0.5s;
      color: $black;

      &:hover,
      &:focus {
        background: black;
        border-radius: 50%;
        border: 2px solid black;

        .icon,
        svg {
          fill: white;
        }
      }

      .icon,
      .svg {
        fill: black;
        margin: 0;
        padding: 0;
        width: 24px;
        height: 24px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      svg {
        margin: 0;
      }
    }
  }
}

// Footer Bot Nav
.neo__footer {
  .neo__footer-bot-nav {
    padding: 0 16px;
    margin-top: 40px;
    border-top: 1px solid $footer-line-color;
    font-size: 14px;
    @include tablet {
      margin-top: 40px;
      font-size: 14px;
    }
    @include desktop {
      margin-top: 97px;
      font-size: 12px;
    }

    .neo__footer-bot-nav__inner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;
      @include desktop {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        max-width: 1600px;
        margin: 0 auto;
      }
    }

    .nav-list {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      margin: 22px 0 50px 0;
      @include desktop {
        display: flex;
        justify-content: flex-start;
        max-width: calc(100% - 64px - 302px);
        margin: 0;
      }
    }

    li {
      width: 100%;
      @include desktop {
        width: auto;
        display: inline-block;
        margin-right: 32px;
      }

      a,
      .footer-link {
        padding: 12px 0;
        margin: 0;
        text-decoration: none;
        font-size: 14px !important;
        text-decoration: none;
        cursor: pointer;

        @include desktop {
          font-size: 12px !important;
        }
      }
    }

    // Footer Copyright Text
    .footer__copyright__text {
      line-height: 1;
      padding: 0 0 30px 0;
      margin: 0;
      @include desktop {
        padding: 0;
      }

      .footer__copyright__text__desktop {
        display: none;
        @include tablet {
          display: inline;
        }
      }
    }
  }
}
