.savings-info {
  display: flex;
  margin-top: 80px;

  @include mobile-only {
    flex-direction: column;
    margin: 40px 0 40px $container-padding-mobile;
    width: calc(100vw - #{$container-padding-mobile * 2});
  }

  @include tablet {
    grid-column: 1 / -1;
  }

  &__image {
    flex: 1;
    line-height: 0;

    @include mobile-only {
      margin-top: 32px;
    }

    @include tablet {
      order: 1;
    }

    img {
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;

    @include tablet {
      order: 2;
      padding: 64px;
    }
  }

  .title {
    margin: 0 0 8px;
    font-size: 22px;
    color: #468949;

    @include tablet {
      font-size: 42px;
    }
  }
}
