.neo__full-link-block__outer {
  .neo__full-link-block__inner {
    border: 1px solid $neo-form-border-color;
    padding: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include tablet {
      justify-content: space-between;
      text-align: left;
    }

    .neo__full-link-block__main-content {
      max-width: 644px;
      width: 100%;
      display: flex;
      flex-direction: column;
      @include tablet {
        margin-right: 16px;
        max-width: unset;
        width: calc((100% / 2) - 16px);
      }
      @include desktop {
        width: calc(((100% / 3) * 2) - 16px);
      }
    }
    .neo__full-link-block__title {
      width: 100%;
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 1.33;
      font-weight: bold;
      text-align: center;
      @include tablet {
        text-align: left;
      }
    }

    .neo__full-link-block__text {
      width: 100%;
      p {
        margin-top: 0;
      }
      & > * {
        font-size: 24px;
        line-height: 1.33;
        text-align: center;
        @include tablet {
          text-align: left;
        }
      }
    }
    .neo__full-link-block__side-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      @include tablet {
        height: 100%;
        width: calc((100% / 2) - 16px);
      }
      @include desktop {
        width: calc(((100% / 3) * 1) - 16px);
      }
      .image{
        object-fit: contain;
      }
    }
    .neo__full-link-block__image {
      max-width: 240px;
      max-height: 240px;
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      @include tablet {
        margin-right: 0;
      }
    }

    .neo__full-link-block__cta__list {
      width: 100%;
      padding-left: 0;
      li {
        margin: 0;
        padding: 8px;
        padding-left: 0;

        a {
          margin: 0;
        }
      }
    }
  }
}
