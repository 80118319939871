.textWith2Images_container {
  display: flex;
  flex-wrap: wrap;
  .title {
    width: 100%;
    font-weight: normal;
    line-height: 1.25;
    font-size: 32px;
    margin: 0;
  }

  .text-content {
    overflow: hidden;
    position: relative;
    width: calc(100%);
    @include tablet {
      margin-right: 16px;
      width: calc(50% - 16px);
    }
  }
  .image-content {
    overflow: hidden;
    position: relative;
    width: calc(100%);
    margin-top: 32px;
    @include tablet {
      margin-left: 16px;
      width: calc(50% - 16px);
      margin-top: 0;
    }
  }
  // .side-image__container {
  //   width: 100%;
  //   max-width: 468px;
  //   max-height: 280px;
  //   height: 0;
  //   padding-top: 56.25%;
  //   display: block;
  //   position: relative;
  //   overflow: hidden;
  //   border: none;
  //   background: none;
  //   cursor: pointer;
  //   outline: none;
  //   margin-bottom: 16px;
  // }
  .side-image {
    width: 100%;
    float: right;
    display: block;
    position: relative;
    overflow: hidden;
    border: none;
    background: none;
    outline: none;
    margin-bottom: 16px;
    @include tablet {
      max-width: 468px;
      max-height: 280px;
    }
  }
}

// IFRAME

.iframe-placeholder {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  display: block;
  position: relative;
  overflow: hidden;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 16px;
  float: right;
  @include tablet {
    max-width: 468px;
    max-height: 280px;
  }

  &:hover,
  &:focus {
    svg {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  .iframe__image {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: $white;
    content: "";
    z-index: 1;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 86px;
    height: 86px;
    transition: all 0.2s;

    @include tablet {
      width: 150px;
      height: 150px;
    }
  }

  .iframe__iframe {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
