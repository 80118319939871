$savingsBackground: #EFF1F2;

.savings-loading,
.savings-exception {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 $container-padding-mobile 64px;
    min-height: 50vh;
    text-align: center;

    @include tablet {
        padding: 100px 0;
    }

    .title {
        margin: 0;
    }

    p {
        margin: 0;
        max-width: 530px;
    }

    svg {
        @include mobile-only {
            transform: scale(0.5);
            transform-origin: bottom;
        }
    }
}