.hero-instruction {
  .block--outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $hero-pro-gradient;
    color: $white;

    .block--content {
      padding: 107px 14px 100px 14px;
      max-width: 780px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .hero-instruction--label {
        background: $white;
        color: #005691;
        padding: 3px 12px;
        width: fit-content;
      }

      .hero-instruction--title {
        width: 100%;
        font-size: 42px;
        padding: 16px 0 21px 0;
        margin: 0;
        color: $white;
        @include tablet {
          width: 569px;
        }
      }

      .hero-instruction--subtitle {
        width: 90%;
        font-size: 18px;
        margin: 0;
        @include tablet {
          width: 656px;
        }

        * {
          color: $white;
        }
      }

      .block__cta__list .cta {
        color: white;
      }
      .hero-instruction__steps-container {
        margin-top: 32px;
      }
      .steps__title {
        margin-bottom: 16px;
      }
      .steps__list {
        padding: 0 20px 0 0;
        margin: 0;
      }
      .steps__list__item {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        list-style: none;
      }
      .steps__list__item__count {
        width: 44px;
        height: 44px;
        display: block;
        padding: 8px;
        border-radius: 50%;
        border: 2px solid white;
        margin-right: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }
}
