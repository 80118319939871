.article-page {
  .neo__content-block,
  .intro-date {
    grid-column: 2/14;
  }

  .intro-date {
    grid-row: 2;
    margin-bottom: 0;
    font-size: 12px;
    color: $neo-dark-gray;
  }
  @media (min-width: 1050px) {
    .neo__content-block {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
