.neo__text-with-articles {
  display: flex;
  flex-wrap: wrap;

  .block__inner{
    grid-column: 2/14;
    display: flex;
    flex-wrap: wrap;
    @include until-desktop{
      display: block;
    }
  }

  .neo__text-with-articles-title {
    flex: 0 0 100%;

    h2 {
      font-size: 32px;
      line-height: 40px;
      @include tablet {
        max-width: calc(50% - 16px);
      }
    }
  }

  .neo__text-with-articles--text {
    flex: 1;
    padding-right: 16px;
    margin-top: -6px;

    .neo__cta-list {
      padding-left: 0;
      justify-content: left;

      li:first-child {
        padding-left: 0;
      }
    }

    * > p:first-child {
      margin-top: 0;
    }
  }

  .neo__text-with-articles--articles {
    flex: 1;
    padding-left: 16px;

    li {
      overflow: hidden;
      margin-bottom: 32px;
    }
  }

  .neo__text-with-articles--text,
  .neo__text-with-articles--articles {
    @include until-tablet {
      flex: 0 0 100%;
      padding: 0;
      margin-bottom: 16px;
    }
  }
}
