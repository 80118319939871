.block--neo-text-columns-bl {
  .block{
    &--outer {
      grid-column: 2/14;
    }
    &--inner{
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
    }

    &__item{
      display: flex;
      justify-content: center;
      flex-direction: column;

      &.col-2 {
        width: 100%;
        @include tablet {
          flex: 0 0 calc( (100% - 1 * 40px ) / 2 );
        }
      }

      &.col-3 {
        width: 100%;
        @include tablet {
          flex: 0 0 calc( (100% - 2 * 40px ) / 3 );
        }
      }

      &.col-4 {
        width: 100%;
        @include tablet {
          flex: 0 0 calc( (100% - 1 * 40px ) / 2 );
        }
        @include desktop {
          flex: 0 0 calc( (100% - 3 * 40px ) / 4 );
        }
      }
    }

    &__item__inner {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: nowrap;
    }

    &--image-container {
      margin-bottom: 16px;
      .image {
        height: 160px;
        object-fit: cover;
      }
    }

    &--title {
      max-width: 100%;
      margin-top: 8px;
      word-break: break-word;
      color: $neo-blue;
      display: flex;
      align-items: center;
      @include tablet {
        flex-direction: row;
      }
    }

    &--text {
      p {
        font-size: 16px;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-weight: bold;
      }
    }

  }
}
