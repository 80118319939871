.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 20px 0 90px 0;
  padding: 0;
  grid-column: 1/15;
  @include tablet {
    grid-column: 2/14;
    margin: 20px 0 90px 0;
  }
  @include desktop {
    grid-column: 4/12;
    margin: 20px 0 90px 0;
  }

  li {
    align-self: center;
    margin-right: 10px;

    &:not(.pagination__nav) {
      a {
        color: black;
        font-size: 24px;
        padding: 0 9px;
      }
    }

    &:not(.pagination__current) {
      a {
        &:hover {
          color: $red;
        }
        text-decoration: none;
        line-height: normal;
      }
    }

    &.pagination__current {
      font-size: 24px;
      border-bottom: 1px solid black;
    }

    &.pagination__nav {
      border: none;
      width: 20px;
      height: 20px;

      a {
        width: 20px;
        height: 25px;
        position: absolute;
        background: url("#{ $base-url }icons/epsilon_black.svg") no-repeat;

        &:active,
        &:focus,
        &:hover {
          background: url("#{ $base-url }icons/epsilon_red.svg") no-repeat;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.next {
        margin: -7px 0 0 5px;
      }

      &.prev {
        transform: rotate(180deg);
      }
    }
  }
}
