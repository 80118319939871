.blog-post {
	.blog-post-header {
		margin: 32px 0;

		h1 {
			text-align: left;
			font-size: 30px;
		}
	}

	.blog-post-image {
		margin: 32px 0;

		@include desktop {
			order: 2;
		}

		img {
			width: 100%;
		}
	}

	.column {
		display: flex;
		flex-direction: column;
	}

	.blog-post-section {
		order: 2;

		@include desktop {
			order: 1;
		}

		.boilerplate {
			p {
				margin-bottom: 16px;
			}
		}

		.main-content {
			margin-bottom: 32px;
		}

		p {
			margin: 0;
		}
	}
}

.blog-author-section {
	.row {
		justify-content: space-between;
	}

	.author-img-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.author-img {
			max-height: 250px;
		}
	}

	.author-details {
		border-left: 3px solid $blue;
		padding-left: 28px;

		.author-name {
			margin-bottom: 0;
			font-size: 20px;
			font-weight: bold;
		}

		.author-role {
			color: $grey;
		}
	}
}
