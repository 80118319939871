.savings-installer {
    grid-column: 1 / -1;
    display: flex;
    position: fixed;
    width: 100%;
    background: white;
    bottom: 0;

    @include mobile-only {
        flex-direction: column;
        margin-left: $container-padding-mobile;
        width: calc(100vw - #{$container-padding-mobile * 2});
    }

    @include tablet {
        align-items: center;
        justify-content: flex-end;
    }

    .link {
        margin: 0;

        @include tablet {
            order: 1;
        }
    }

    .link--installer {
        padding: 0;
    }

    .btn {
        margin-bottom: 16px;

        @include tablet {
            order: 2;
            margin: 0 0 0 24px;
        }
    }

    .icon--fitter{
        &:before {
            content: url('data:image/svg+xml, <svg width="30" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.924 13.563A7.133 7.133 0 0 0 18 7.125 7.133 7.133 0 0 0 10.875 0 7.133 7.133 0 0 0 3.75 7.125a7.133 7.133 0 0 0 4.076 6.438C3.309 14.885 0 19.062 0 24h1.5c0-5.17 4.206-9.375 9.375-9.375 5.17 0 9.375 4.206 9.375 9.375h1.5c0-4.938-3.31-9.115-7.826-10.437Zm14.54 1.398a5.27 5.27 0 0 1-4.638 1.455l-1.942 1.942c-.237-.46-.5-.905-.791-1.33l2.27-2.27.417.112a3.765 3.765 0 0 0 3.623-.97 3.757 3.757 0 0 0 1.06-3.181l-2.121 2.12c-.425.426-.99.66-1.591.66a2.235 2.235 0 0 1-1.591-.66 2.235 2.235 0 0 1-.66-1.59c0-.601.235-1.166.66-1.591l2.12-2.121a3.758 3.758 0 0 0-3.18 1.06 3.764 3.764 0 0 0-.97 3.623l.11.417-2.132 2.133a12.463 12.463 0 0 0-1.186-.935l1.662-1.662a5.27 5.27 0 0 1 1.454-4.637 5.259 5.259 0 0 1 5.976-1.025l.957.457-3.75 3.75a.745.745 0 0 0-.22.53c0 .201.078.39.22.531a.768.768 0 0 0 1.06 0l3.75-3.75.457.957a5.259 5.259 0 0 1-1.025 5.975ZM10.874 1.5A5.631 5.631 0 0 0 5.25 7.125a5.631 5.631 0 0 0 5.625 5.625A5.631 5.631 0 0 0 16.5 7.125 5.631 5.631 0 0 0 10.875 1.5Z" fill="%23000"/></svg>');
            display: flex;
            margin-right: 16px;
            height: 24px;
            width: 30px;
        }
    }
}