@use "sass:list";

html{
  scroll-behavior: smooth;
}

@mixin list-animation(
  $name,
  $duration: 0.3s,
  $delay: 0,
  $list: (),
  $listMobile: ()
) {
  animation: $name $duration forwards;
  animation-delay: $delay;
  opacity: 0;

  @keyframes #{$name} {
    0% {
      @each $item in $list {
        #{list.nth($item, 1)}: #{list.nth($item, 2)};
      }
    }
    100% {
      @each $item in $list {
        #{list.nth($item, 1)}: #{list.nth($item, 3)};
      }
    }
  }
}


// Zoom
.animation__zoom {
  .image,
  .background-image {
    transition: all 0.5s;
  }
  @include on-event{
    .image,
    .background-image {
      transform: scale(1.2);
    }
  }
}

// Slide up
.animation__slider-up,
.load-slide-up{
  opacity: 0;
  &.animation--active {
    transition: opacity 0.5s ease;
    animation: slidUp 0.8s forwards;
    animation-delay: 0.5s;
  }
}
@keyframes slidUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  60% {
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


// start - Old animatiosn
.animate__zoom-child-image {
.neo__image-loading__background-image {
    transition: all 0.5s;
  }
  &:hover,
  &:focus {
    .neo__image-loading__background-image {
      transform: scale(1.2);
    }
  }
}

.load-slide-up {
  .neo__image-loading__container {
    opacity: 0;
  }
  &.animation--active {
    .neo__image-loading__container {
      transition: opacity 0.5s ease;
      animation: slidUp 0.8s forwards;
      animation-delay: 0.5s;
    }
  }
}
// End - Old animations