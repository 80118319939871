.cta-section {
  margin: 48px 0;
  text-align: center;

  @include tablet {
    text-align: left;
  }

  .cta-text {
    font-size: 20px;

    p {
      font-size: 24px !important;
      line-height: 1.5;
      margin: 0;
    }
  }

  .cta__button {
    margin: 8px 0;
  }
}

.cta-container {
  display: flex;
  flex-direction: column;

  .cta__button {
    margin: 8px 0;
  }
}