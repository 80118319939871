.block--neo-product-specs{
  .block__outer{
    grid-column: 2/14;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .block__text{
    font-size: 12px;
    color: #808285;
    line-height: 16px;
    padding: 8px 24px;
    margin: 0;
    position: relative;
    display: flex;
    &>*{
      width: 100%;
    }
    &:before{
      content: '*';
      display: inline-block;
    }
  }
}
