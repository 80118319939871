.block--neo-image-links-block {
  .block--outer {
    grid-column: 2/14;

    .block--header {
      .block--title {
        text-align: center;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        @include tablet {
          flex-direction: row;
          justify-content: center;
        }
      }
    }

    .block--content {
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: center;
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      &.col-3 {
        .block--link {
          width: 100%;
          @include tablet {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
          }
        }
      }
      &.col-4 {
        .block--link {
          width: 100%;
          @include tablet {
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
          }
          @include desktop {
            flex: 0 0 calc(100% / 4);
            max-width: calc(100% / 4);
          }
        }
      }

      .block--link-image__outer {
        .image {
          object-fit: cover;
        }
      }

      .block--link {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin: 0;

        a {
          padding: 16px;
          color: $black;
          .image__container .image {
            transition: all 0.3s;
          }

          &:hover,
          &:focus {
            .image__container .image {
              transform: scale(1.2);
            }
          }
        }

        > a {
          text-decoration: none;
          display: block;
          width: 100%;
          @include until-desktop {
            max-width: 360px;
            margin: 0 auto;
          }

          .block--link-image__outer {
            display: block;
            width: 100%;
            height: 232px;
            overflow: hidden;
          }

          > .block--link-text {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 0 16px;

            p {
              font-size: 24px;
              line-height: 32px;
              width: max-content;
              max-width: 200px;
              @include mq(1200px) {
                max-width: calc(100% - 32px);
              }
            }

            .neo-epsilon {
              align-self: center;
              justify-self: center;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
}
