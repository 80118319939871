// BG IMAGE
.campaign-banner__banners {
  padding: 48px 0;
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;

  @include tablet {
    padding: 48px 0;
  }
  .campaign-banner__banner {
    position: relative;
    padding: 32px 16px;
    min-height: 300px;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    @include tablet {
      min-height: 360px;
      padding: 64px 32px;
    }
    @include desktop {
      min-height: 420px;
      padding: 100px 32px;
    }
  }
}

// TEXT CONTENT
.campaign-banner__banner {
  .campaign-banner__content {
    z-index: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  .bannerText {
    max-width: 400px;
    margin-bottom: 30%;
    @include tablet {
      margin-bottom: 0;
    }
  }
  .bannerText,
  .bannerText > * {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    @include tablet {
      font-size: 32px;
      line-height: 1.63;
    }
  }
}
