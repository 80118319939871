.block--neo-faq-block {

  // Shows the FAQ block to be white whenever there's already a $neo-faq-active-link-color background on it.
  &--has-background{
    .neo__faq-page-block__main__question{
      background: white !important;
    }
    .neo__faq-page-block__main__question__outer{
      border-color: white !important;
    }

  }

  .neo__faq-page-block__outer {
    grid-column: 2/14;

    &.full-width {
      .neo__faq-page-block__aside,
      .neo__faq-page-block__main {
        width: 100%;
      }
    }
    .neo__faq-page-block__inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .neo__faq-page-block__aside {
      width: 100%;
      @include tablet {
        width: calc((100% / 3) - 32px);
      }

      .neo__faq-page-block__aside__buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .neo__cta.link {
          font-size: 16px;
          padding: 15px 25px 15px 15px;
          text-decoration: none;
          margin: 0;
          text-align: left;
          width: 90%;
          color: $black;
          @include mobile-only {
            width: 100%;
          }

          &:hover,
          &:focus {
            background: #dbdee2;
          }

          &:after {
            display: block;
            content: "";
            background-image: url("#{ $base-url }icons/epsilon_black.svg");
            position: absolute;
            right: 5px;
            top: calc(50%);
          }

          &.active {
            background: #dbdee2;
            font-weight: bold;
          }
        }
      }
    }

    .neo__faq-page-block__main {
      width: 100%;
      @include tablet {
        width: calc((100% / 3) * 2);
      }

      .neo__faq-page-block__main__title {
        margin: 0 0 18px 0;
        display: block;
        @include mobile-only {
          margin: 18px 0;
        }
      }

      .neo__faq-page-block__main__question__outer {
        border: 1px solid $neo-faq-active-link-color;
        margin-bottom: 16px;

        &.open {
          .neo__faq-page-block__main__question {
            background: $white;

            &:after {
              border-top: 3px solid $bosch-blue;
              border-right: 3px solid $bosch-blue;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }

          .neo__faq-page-block__main__answer {
            max-height: 600px;
            padding: 0 24px 24px;
          }
        }
      }

      .neo__faq-page-block__main__question {
        padding: 20px 60px 20px 24px;
        background: $neo-faq-active-link-color;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        font-size: 16px;
        font-weight: 700;

        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-top: 3px solid $black;
          border-right: 3px solid $black;
          display: block;
          position: absolute;
          right: 20px;
          top: calc(50% - 10px);
          transform: rotate(135deg);
          transition: all 0.3s;
        }
      }

      .neo__faq-page-block__main__answer {
        max-height: 0;
        overflow: hidden;
        padding: 0 24px;
      }
    }
  }
}
