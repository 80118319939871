@font-face {
  font-family: "BoschSansBold";
  src: url("#{ $base-url }fonts/BoschSans-Bold.woff") format("woff2"),
    url("#{ $base-url }fonts/BoschSans-Bold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}
@font-face {
  src: url("#{ $base-url }fonts/BoschSans-Regular.woff2") format("woff2"),
    url("#{ $base-url }fonts/BoschSans-Regular.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "BoschSans";
  src: url("#{ $base-url }fonts/BoschSans-Regular.woff2") format("woff2"),
    url("#{ $base-url }fonts/BoschSans-Regular.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

// BASIC FALLBACK FONTS
$ff-sans-serif-fallback: "Helvetica Neue", "Helvetica", "Roboto", "Arial",
  sans-serif;
$ff-serif-fallback: "Times", "Times New Roman", serif;
$ff-mono-fallback: "Monaco", "Courier New", "Courier", monospace;

// PROJECT FONTS
$ff-bosch-sans: "BoschSans", $ff-sans-serif-fallback;
$ff-bosch-sans-bold: "BoschSansBold", $ff-sans-serif-fallback;

html {
  height: 100%;
  font-family: $ff-bosch-sans;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 144%;
  word-break: break-word;
  color: #000000;
  @include tablet {
    font-size: 18px;
  }
}

// Headings
h1,
h2,
h3,
h4,
h4,
h5,
h6 {
  font-family: $ff-bosch-sans;
  line-height: 120%;
  margin: 0 0 0.5em 0;
}

h1,
.h1 {
  font-family: $ff-bosch-sans-bold;
  font-size: 48px;
  @include desktop {
    font-size: 48px;
  }
}
h2,
.h2 {
  font-size: 36px;
  font-weight: 400;
  @include desktop {
    font-size: 48px;
  }
}
h3,
.h3 {
  font-size: 24px;
  font-weight: 400;
  @include desktop {
    font-size: 32px;
  }
}
h4,
.h4 {
  font-size: 18px;
  @include desktop {
    font-size: 24px;
  }
}
h5,
.h5 {
  font-family: $ff-bosch-sans-bold;
  font-size: 18px;
}
h6,
.h6 {
  font-family: $ff-bosch-sans-bold;
  font-size: 16px;
}
p {
  margin: 0.5em 0;
}

figure,
iframe {
  margin: 0;
  padding: 0;
}

// Quotes
q,
blockquote {
  margin: 1em 0;
  padding: 8px 0px 8px 16px;
  border-left: 2px solid $neo-cherry-red;
}

// Redactor/Wysiwyg
.neo__wysiwyg,
.wysiwyg {
  iframe {
    max-width: 100%;
  }

  a {
    color: $hoverblue;
  }
}

// Custom List marking
ul,
ol {
  &:not(
    .neo__cta-list,
    .external-link-list__outer,
    .download-list__outer,
    .checkmark-list__outer,
    .cross-list__outer,
    .block__cta__list) {
    padding-left: 22px;
    li {
      padding-left: 0;
    }
  }
}

// Button font overwrite
button {
  font-family: $ff-bosch-sans;
}

.external-link-list__outer,
.download-list__outer,
.checkmark-list__outer,
.cross-list__outer {
  margin: 0 0 16px 0;
  padding: 0;
  li {
    text-align: left;
    margin: 0;
    padding: 2px 0 2px 28px;
    list-style: none;
    position: relative;

    &::before {
      content: "";
      width: 16px;
      height: 16px;
      padding: 2px;
      position: absolute;
      left: 0;
      top: 50%;
      display: block;
      transform: translateY(-50%);
      background-position: center;
      background-size: 12px;
      background-repeat: no-repeat;
    }
  }
}
.external-link-list__outer {
  li::before {
    background-image: url("#{ $base-url}neo/icons/external-link.svg");
  }
}
.download-list__outer {
  li::before {
    background-image: url("#{ $base-url}neo/icons/download.svg");
  }
}
.checkmark-list__outer {
  li::before {
    background-image: url("#{ $base-url}neo/icons/check-light.svg");
  }
}
.cross-list__outer {
  li::before {
    background-image: url("#{ $base-url}neo/icons/cross-light.svg");
  }
}

form {
  font-size: 14px;

  textarea,
  input {
    font-family: $ff-bosch-sans;
  }
}
