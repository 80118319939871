.neo__hero-outer {
  position: relative;
  overflow: hidden;
  min-height: 300px;
  max-height: 600px;
  height: 500px;
  @include desktop {
    height: 474px;
  }
  &.custom-btn {
    .neo__hero-cta {
      background: transparent;
      padding: 16px;
      transition: all 0.3s;
      &:after {
        display: none;
      }
      &--blue {
        border: 2px solid $bosch-blue;
        color: $bosch-blue;
      &:hover,
      &:focus {
        padding: 14px;
        background-color:#d1e4ff;
        border-width: 4px;
      }
      }
      &--white {
        border: 2px solid white;
        color: white;
      &:hover,
      &:focus {
        padding: 14px;
        border-width: 4px;
      }
      }
    }
  }

  .neo__hero-inner {
    padding-top: 24px;
    padding-bottom: 24px;
    overflow: visible !important;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 0;
    @include tablet {
      flex-direction: row;
      padding-top: 64px;
      padding-bottom: 64px;
      align-items: flex-start;
    }
  }

  .neo__hero-image__container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .neo__hero-image,
  .neo__hero-image-desktop,
  .neo__hero-image-tablet,
  .neo__hero-image-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    z-index: -1;

    &:not(&--no-gradient):after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to right,
        rgba($black, 0.3),
        transparent
      );
    }
  }

  // .neo__hero-outer
  .neo__hero-image,
  .neo__hero-image-desktop,
  .neo__hero-image-tablet,
  .neo__hero-image-mobile {
    display: block;

    picture {
      height: 100%;
    }
  }

  .neo__hero-title,
  .neo__hero-subtitle {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    line-height: 1.2;
    color: $white;
  }
  .hero-cta {
    display: none;
    @include tablet {
      display: block;
    }
  }

  .hero--label {
    display: block;
    background: $white;
    color: $black;
    padding: 3px 12px;
    margin: 0 0 15px 0;
    width: fit-content;
    font-size: 12px;
  }

  .neo__hero-title {
    font-weight: bold;
    padding: 0 0 8px 0;
    font-size: 36px;
    margin: 0;
    @include tablet {
      font-size: 48px;
    }
  }

  .neo__hero-subtitle {
    padding: 0 0 8px 0;
    font-weight: normal;
    font-size: 18px;
    margin: 0;
  }

  .neo__hero-cta {
    margin-top: 32px;
    margin-left: 0;
    margin-right: 0;

    &--left{
      float:left;
    }
    
    &--right{
      float:right;
    }
  }

  .neo__hero-inner-text,
  .neo__hero-secondary-image {
    max-width: 100%;
    flex: 0 0 1;
    @include tablet {
      @include flex-width(50%, 16px);
    }
  }
  .neo__hero-inner-text {
    z-index: 1;
    @include tablet {
      padding-top: 32px;
    }
    @include desktop {
      padding-top: 64px;
    }
  }

  .neo__hero-secondary-image {
    margin-left: auto;
    margin-right: 0;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    
    &.mobile {
      display: flex;
      text-align: center;
      margin: 16px 0;
      @include tablet {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @include tablet {
        display: flex;
        margin-top: auto;
      }
    }
   
    .neo__hero-secondary-image-holder {
      width: 100%;
      height: 100%;
      max-width: 400px;
      max-height: 400px;
      position: relative;
      display: flex;
      padding-right: 50px;
      padding-bottom: 100px;

      @include tablet {
        max-width: 100%;
        max-height: 100%;
      }
      .image {
        object-fit: contain;
      }
      .neo__image-loading__container {
        &.bottom,
        &.center,
        &.top {
          margin-top: auto;
          margin-bottom: 0;
          &__left {
            margin-left: 0;
            margin-right: auto;
          }
          &__center {
            margin-left: auto;
            margin-right: auto;
          }
          &__right {
            margin-left: auto;
            margin-right: 0;
          }
        }
        &.center {
          margin-top: auto;
          margin-bottom: auto;
        }
        &.top {
          margin-top: 0;
          margin-bottom: auto;
        }
      }
    }
  }
}

.neo__hero-has-click{
  cursor: pointer;
}

.neo__hero-animated {
  overflow: hidden;

  .neo__hero-image,
  .neo__hero-image-desktop,
  .neo__hero-image-tablet,
  .neo__hero-image-mobile {
    animation: neo-zoom 15s;
    animation-timing-function: ease-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}

@keyframes neo-zoom {
  100% {
    transform: scale(1.2);
  }
}

.neo__hero-inner {
  &.align-center {
    .neo__hero-inner-text {
      max-width: 100%;
      flex: 0 0 1;
      text-align: center;
      margin: auto auto !important;
      @include tablet {
        padding-top: 32px;
        @include flex-width(100%, 0);
      }
      @include desktop {
        padding-top: 64px;
      }
      .neo__hero-title {
        max-width: 90%;
        margin: 0 auto;
        @include tablet {
          max-width: 60%;
        }
      }
    }
    .neo__hero-secondary-image {
      margin-left: -50%;
    }
    @include until-desktop {
      .neo__hero-inner-text {
        text-align: center;
        .neo__hero-title {
          margin: 0 auto;
        }
      }
    }
  }
}
.neo__hero-secondary-image.mobile-banner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f1f1f1;
  padding: 12px 24px;
  margin-bottom: 12px;
  &.center {
    justify-content: center;
  }
  @include tablet {
    display: none;
  }
  .neo__hero-secondary-image-holder {
    margin-right: 16px;
  }
  .neo__cta {
    max-width: 100%;
    margin-right: 0;
  }
}

.neo__hero-outer {
  .neo__content-block {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    padding: 0 16px;
    margin: 0 auto;
    margin-top: 50px;
    box-sizing: border-box;
    @include tablet {
      padding: 0 24px;
      margin-bottom: 50px;
    }
    @include desktop {
      max-width: 1600px;
    }

    &.full {
      padding: 0;
      max-width: none;
    }

    .neo__content-block__inner {
      width: 100%;
      max-width: 100vw;
      overflow: hidden;
      padding: 0 16px;
      margin: 0 auto;
      box-sizing: border-box;
      @include tablet {
        padding: 0 24px;
      }
    }
  }
}
