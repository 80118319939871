.chart-container {
    box-sizing: border-box;
    
    @include tablet {
      padding: 30px;
    }
  }

.chart-title {
    text-align: center;
    color: $black;
}

#doughnut-chart {
    height: 232px !important;
    width: 232px !important;
    margin: 0 auto;
}

#chart-legends {
    .chart-legend {
        list-style: none;
        margin: 0 auto;

        @include phone-big {
            width: 45%;
        }

        .chart-label-item {
            display: flex;
            margin: 16px 0;

            .label-block {
                margin-right: 16px;
                width: 48px;
                min-width: 48px;
                height: 16px;
                display: block;
            }
        }
    }
}