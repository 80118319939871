.neo__link-list-block__outer {
  &.full {
    padding: 64px 0;
    background-color: $neo-light-gray;

    > .neo__content-block {
      margin: 0;
    }
    .neo__link-list-block__inner {
      .neo__link-list-block__row {
        padding: 12px;

        @include desktop {
          padding: 24px 0;
        }
      }
    }
  }

  .neo__link-list-block__inner {
    display: flex;
    flex-wrap: wrap;

    @include mobile-only {
      flex-direction: column;
    }

    .neo__link-list-block__title {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 32px;
    }
    .neo__locator-block,
    .neo__link-list-block__row {
      width: 100%;

      @include tablet {
        width: calc((100% - (1 * 32px)) / 2);
        margin-right: 32px;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }

      @include desktop {
        width: calc((100% - (2 * 32px)) / 3);
        margin-right: 32px;
        &:nth-of-type(2n) {
          margin-right: 32px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .neo__locator-block {
      margin: 0;
    }

    .neo__link-list-block__row {
      padding: 24px;
      .neo__link-list-block__row__title {
        font-weight: bold;
        line-height: 2;
      }

      ul {
        justify-content: left;
        text-align: left;
        padding: 0;
        display: block;
        margin-top: 0;

        li {
          .neo__cta {
            margin: 0;
            padding: 8px 0;
            min-height: unset;
            height: auto;
          }
          &,
          .neo__cta.link {
            display: block;
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
    }

    .neo__locator-block {
      min-height: 132px;
      position: relative;
      display: flex;

      @include until-desktop {
        min-height: 86px;
      }

      .neo__locator-block_content {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;

        .neo__locator-block__title {
          width: calc(100% - 100px);
          padding-bottom: 8px;
        }

        .neo__locator-block__text {
          padding-right: 16px;
          margin: 8px 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          > div {
            line-height: 1.75;
            color: black;
          }
        }

        .neo__locator-block__form {
          display: flex;

          button {
            margin: 0;
            height: 100%;
            width: 48px;
            font-size: 0;
          }
        }
      }

      .neo__locator-block__image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        z-index: 0;
      }
    }
  }
}
