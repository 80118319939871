.block--intro-block {
  .block__outer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column: 2/14;
    .block__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 568px;
      margin: 0 auto;
      text-align: center;
      & > .pro-label {
        line-height: 120%;
        font-size: 24px;
        font-weight:bold;
        align-self: center;
        @include desktop {
          font-size: 32px;
        }
      }
    }
    .block__title{
      display: inline-block;
      @include mobile-only{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }
    }
    .neo__cta-list {
      margin-top: 24px;
    }

    .neo__text p {
      line-height: 1.5;
    }

    * {
      max-width: 568px;
    }
  }
}
