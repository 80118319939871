.freeform-row {
    .freeform-column {
        h5 {
            margin: 0;
        }

        label,
        input {
            font-family: "BoschSans", sans-serif;
        }

        input {
            transition: .3s ease-in-out;

            &:focus {
                outline: none;
                border-color: $blue;
                transition: .3s ease-in-out;
            }
        }

        label[for="form-input-preferredDay"] {
            position: relative;

            &::after {
                position: absolute;
                right: 12px;
                top: 6px;
                content: url('https://s3.eu-west-1.amazonaws.com/np-nefit/Iconen/Dropdown.svg');
                color: $black;
            }
        }

        select[name="preferredDay"] {
            text-align: center;
        }

        .seperator {
            border: 1px solid #eee;
        }

        .freeform-label {
            font-size: 16px;

            &.freeform-required {
                // display: none;

                &.show {
                    margin-bottom: 12px;
                    display: block;
                }

                &.terms-conditions-check {
                    display: block;
                    font-size: 12px;

                    a {
                        color: $black;
                    }
                }
            }
        }

        .input-group-one-line {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 8px;

            label {
                flex: 35%;
                margin-bottom: 12px;
                position: relative;
                padding-left: 28px;
                padding-right: 0;
                cursor: pointer;

                input[type="radio"] {
                    appearance: none;
                    position: absolute;
                    border-radius: 25px;
                    left: 0;
                    top: -3px;

                    &:focus {
                        outline: none;
                    }

                    &:checked {
                        border-color: $blue;
                        border-width: 6px;
                        padding: 3px;
                        background: white;
                    }
                }

                input[name="deviceSelection[]"],
                input[name="pricelistSelection[]"] {
                    appearance: none;
                    position: absolute;
                    left: 0;
                    top: -3px;

                    &:checked {
                        border-color: $red;
                        background: $red;
                    }
                }
            }
        }

        select {
            background: white;
            appearance: none;
            font-size: 14px;
            padding: 8px;
            border-radius: 5px;
            border: 1px solid #808285;
        }
    }
}


.freeform-column {
    position: relative;

    .freeform-required {
        display: block;

        &::after {
            content: "*";
            position: absolute;
            left: 0;
            top: 22px;
            transform: translate(-50%, 0);
            height: 12px;
            width: 12px;
            margin: 0 !important;
            text-align: center;
            color: #000 !important;
            background: #fff;
            border-radius: 50%;
        }
    }
}