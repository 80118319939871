.info-block-container {
  margin: 80px 0;

  .info-block {
    padding: 10px;
    box-sizing: border-box;

    .info-block-header {
      height: 180px;
      overflow: hidden;
      margin-bottom: 24px;

      img {
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}