.block {
  position: relative;
  margin-bottom: 40px;
  padding: 20px 0;
  scroll-margin-top: 4rem;
  @include tablet {
    margin-bottom: 80px;
  }
  &:nth-last-of-type() {
    margin-bottom: 0;
  }

  &.bg--grey {
    background: $component-background-grey;
  }

  &.bg--waves {
    background: url("#{ $base-url }img/defaultbg.png");
    background-size: cover;
  }

  .block__header {
    .block--title {
      font-family: $ff-bosch-sans;
      font-size: 36px;
      display: flex;
      align-items: center;
      @include tablet {
        font-size: 48px;
      }
    }
    .block--sub-title {
    }
    .block--description {
      margin: 24px 0;
    }
  }

  .block--content {
    .block__item,
    .block__partial {
    }

    .block__item--title,
    .block__partial--title {
      font-family: $ff-bosch-sans;
      font-size: 24px;
      margin: 0;
      display: flex;
      align-items: center;
      @include tablet {
        font-size: 32px;
      }

      span {
        display: flex;
        align-items: center;
      }
    }

    .block__item--sub-title,
    .block__partial--sub-title {
    }

    .block__item--description,
    .block__partial--description {
      margin: 24px 0;
    }
  }
}
