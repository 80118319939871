.block--neo-link-list-block {
  .block--header,
  .block--content {
    grid-column: 2/14;
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: center;
    flex-direction: column;
  }

  .block--header {
    .block--title {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      @include tablet {
        text-align: center;
      }

      &--container {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include mobile-only {
          align-items: flex-start;
          padding: 0 5px;
        }

        &--icon {
          display: block;
          margin: 3px 16px 0 0;
          @include tablet {
            margin: 0 16px;
          }
        }
      }
    }
  }

  .block--content {
    @include desktop {
      flex-direction: row;
      &.col-3 {
        .partial__block {
          flex: 0 0 calc(100% / 3);
          max-width: calc(100% / 3);
        }
      }
    }

    .partial__block {
      background: white;
      padding: 16px 16px 16px 0;
      flex: 1;
      @include tablet {
        padding: 16px;
      }

      .block__partial--title {
        display: flex;
      }

      .block__cta__list {
        a {
          margin: 0;
          padding: 6px 18px 6px 0;
          color: $hoverblue;
        }
      }
    }
  }
}
