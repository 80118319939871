.block--cluster-navigation-block {
  .block--outer {
    grid-column: 2/14;

    .block--title {
      width: 100%;
      text-align: center;
      padding: 80px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      @include desktop {
        flex-direction: row;
      }
    }

    .block--inner {
      width: 100%;
      padding: 20px 0 0 0;
      @include tablet {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 33px;
        max-width: 966px;
        margin: 0 auto;
      }

      .block--block {
        display: flex;
        flex-direction: column;
        margin: 0 0 60px 0;
        height: unset;

        .image__container {
          width: 100%;
          height: 183px;
          @include tablet {
            height: 262px;
          }

          .image {
            object-fit: cover;
          }
        }

        .block--content {
          padding: 25px 16px;
          margin: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          background: $white;
          flex-grow: 1;
          @include desktop {
            padding: 40px 32px;
          }

          .block__partial--title {
            font-size: 20px;
            margin: 0 0 25px 0;
            @include desktop {
              margin: 0 0 15px 0;
            }
          }

          .block--links {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: 16px;
            align-self: stretch;

            li {
              margin: 0 0 20px 0;

              &:last-child {
                margin: 0;
              }

              .block--link {
                color: $hoverblue;
                padding-left: 0;
                @include on-event {
                  color: $neo-cherry-red;
                }
              }
            }
          }
        }
      }
    }
  }
}
