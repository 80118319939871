.block--neo-quote-block {
  .block--outer {
    grid-column: 2/14;

    .block--inner {
      padding-left: 4px;
      border-left: solid 4px $neo-blue;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .block--content {
        flex-grow: 1;
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include tablet {
          @include flex-width(50%, 0);
        }

        .block--name {
          font-weight: bold;
          font-size: 16px;
          line-height: 2;
        }

        .block--role {
          font-size: 14px;
          line-height: 16px;
          color: $neo-dark-gray;
          line-height: 1;
        }

        div,
        p {
          line-height: 1.75;
        }
      }

      .block--image {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;

        @include tablet {
          @include flex-width(50%, 0);
        }
        .image {
          object-fit: contain;
        }
      }
    }
  }
}

//THIS STYLING IS USED FOR _neo-personal-info.scss TOO, IF YOU CHANGE SOMETHING HERE..
