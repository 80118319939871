.notFound__outer {
  background-color: #f7f7f7;

  .container {
    padding: 0;
  }

  .notFound__inner {
    text-align: center;

    padding: 60px 0 80px;
    grid-column: 2/14;
    @include desktop {
      padding: 100px 0;
    }
  }
  .notFound__title {
    width: 100%;
    margin-bottom: 0;
    font-size: 72px;
    background: -webkit-linear-gradient(#b90276 10%, #50237f 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include tablet {
      font-size: calc(7.2 * 16px);
    }
    @include desktop {
      font-size: calc(10.2 * 16px);
    }
  }

  .notFound__text {
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 40px;
    p {
      line-height: unset;
      font-size: 16px;
      @include desktop {
        font-size: calc(1.8 * 16px);
      }
    }
  }
}
