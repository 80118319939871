.neo-hero-savings {
  position: relative;
  min-height: 500px;

  @include tablet {
    padding: 80px 16px 56px;
  }

  .title {
    margin: 0;
    color: #fff;

    @include mobile-only {
      display: flex;
      align-items: flex-end;
      min-height: 110vw;
      padding: 0 $container-padding-mobile 16px;
      font-size: 32px;
    }
    
    @include tablet {
      padding-bottom: 24px;
      width: 60%;
      font-size: 42px;
    }
  }
}

.neo-hero-savings-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  z-index: -1;
  overflow: hidden;

  @include mobile-only {
    height: 110vw;
  }

  .hero__image,
  picture {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%), rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;

    @include tablet {
      background: rgba(0,0,0,0.2);
    }
  }
}
