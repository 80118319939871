.product-usp-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: 32px auto;
  // position: relative;
  // bottom: -200px;
  // opacity: 0;
  // transition: opacity 1.5s, bottom 1.5s;

  &.animate {
    opacity: 1;
    bottom: 0;
  }

  section {
    margin-bottom: 32px;

    @include tablet { 
      width: 350px;
    }
  }

  @supports (display: grid) {
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 80%;
      margin: 32px auto;
    }

    @include desktop {
      grid-gap: 64px;
    }
  }

  h5 {
    margin: 0;
  }
}
