.job-positions-grid {
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(auto-fill, 280px);
        grid-gap: 16px;
    }

    .job-position-block {
        .job-footer {
            display: flex;
            justify-content: space-between;
        }
    }

    .job-position-block {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: white;
        border-radius: 5px;
        border: 1px solid $black;
        border-bottom: 3px solid #00608B;

        .job-link {
            text-decoration: none;
            color: $black;

            &:hover {
                .job-title {
                    color: $red;
                }
            }
        }

        .job-body {
            padding: 16px;
            min-height: 100px;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                border-right: 2px solid #e20015;
                border-bottom: 2px solid #e20015;
                transform: rotate(-45deg);
                right: 6px;
                top: 100px;
                margin-right: 10px;
                transition: 0.3s transform;
            }

            .job-title {
                margin-top: 0;
                font-size: 16px;


            }
        }

        .job-footer {
            padding: 16px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 32px;
            background: rgba(241, 241, 241, 0);
        }
    }
}