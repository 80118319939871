.highlights-section {
  .highlights-section__text {
    max-width: 180px;
  }
}

.highlights-section__list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    margin: 0 auto;
  }

  @include tablet {
    align-items: flex-start;
  }

  &.animated {
    .highlights-section__listitem {
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          img {
            padding-top: 30px;
            animation: highight-item-#{$i} 0.5s forwards;
            animation-delay: #{0.2 * $i}s;

            @keyframes highight-item-#{$i} {
              0% {
                transform: translateY(200px);
              }

              60% {
                transform: translateY(-30px);
              }

              100% {
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
}

.highlights-section__listitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0;

  @include tablet {
    flex-direction: column;
    width: 33%;
    text-align: center;
  }
}

.highlights-section__image-container {
  overflow: hidden;

  img {
    width: 120px;
    height: 120px;
    margin-right: 32px;
    transform: translateY(200px);

    @include tablet {
      width: 144px;
      height: 144px;
      max-height: 140px;
      max-width: 140px;
      margin-right: 0;
    }
  }
}