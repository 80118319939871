.neo__current-actions-block__outer,
.neo__previous-actions-block__outer {
  margin-bottom: 80px;
  grid-column: 2/14;
  .neo__current-actions-block__title {
    font-size: 24px;
    margin: 0 0 32px 0;
  }

  .neo__current-actions-block__sub-title {
    font-size: 16px;
  }

  .neo__current-actions-block__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .neo__current-actions-block__list-item {
    list-style: none;
    min-height: 180px;
    height: calc((100vw - 32px) * 0.35);
    max-height: 320px;
    position: relative;
    margin-bottom: 32px;
    width: 100%;
    @include tablet {
      height: calc((100vw / 2 - 32px) * 0.35);
      width: calc(100% / 2 - 16px);
    }
    @include desktop {
      height: 320px;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      .neo__current-actions-block__list-item__background {
        width: auto;
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .neo__current-actions-block__list-item__hover {
        opacity: 0;
        transition: all 0.5s;
        z-index: -1;
        opacity: 1;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        transform: scale(0);
        padding: 16px;
        box-sizing: border-box;
      }

      &:hover,
      &:focus {
        .neo__current-actions-block__list-item__hover {
          opacity: 0.8;
          z-index: 1;
          transform: scale(1);
        }
      }

      .neo__current-actions-block__list-item__hover__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .neo-hover__show__title,
        .neo-hover__show__date,
        .neo-hover__show-more {
          color: #fff;
          list-style: none;
          text-decoration: none;
          text-align: center;
        }

        .neo-hover__show__title {
          font-size: 22px;
          @include limit-lines(2);
          margin: 0 0 8px 0;
        }

        .neo-hover__show__date {
          font-size: 14px;
          margin: 0 0 32px 0;
        }

        .neo-hover__show-more {
          margin: 0;
          font-size: 14px;
          text-decoration: underline;
        }
      }
    }
  }
}

.neo__current-actions-block__outer {
  .neo__current-actions-block__list-item {
    img {
      width: 100%;
    }
  }
}
