.embed-block {
  grid-column: 1/15;
  position: relative;

  @include until-tablet {
    padding: 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    grid-column: 2/14;
    gap: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    width: 100%;
    font-weight: normal;
    line-height: 1.25;
    font-size: 32px;
    margin: 0;
  }

  &__text {
    overflow: hidden;
    position: relative;
    width: 100%;

    @include tablet {
      margin-right: 16px;
      width: calc(50% - 16px);
    }
  }
}
