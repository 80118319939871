@use "sass:color";

.neo__download-list-block__outer {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex-direction: column;
    width: 168px;
    margin-right: 32px;

    > span {
      width: 100%;
      color: $neo-dark-gray;
      padding-bottom: 8px;
    }

    .neo-file__image {
      flex: 0 0 auto;
      width: 100%;
      height: 250px;
    }

    > .neo-file-overlay {
      display: block;
      position: relative;
      width: 100%;
      height: 0;

      &:after {
        content: "";
        pointer-events: none;
        width: 32px;
        height: 32px;
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        background-image: linear-gradient(
          45deg,
          color.scale($neo-dark-gray, $lightness: 10%),
          $neo-dark-gray 50%,
          $white 51%
        );
      }
    }

    > a {
      margin-left: 0 !important;
      padding-left: 0 !important;
      color: $black;
    }
  }
}
