.savings-address-bar {
  margin-bottom: 42px;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;

  @include mobile-only {
    flex-direction: column;
  }
}

.savings-address-bar__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  padding: 40px $container-padding-mobile;
  color: #fff;
  background: linear-gradient(90deg, #3fa9c6 3.14%, #007bc0 94.88%);

  @include tablet {
    order: 1;
    padding: 70px 40px;
    width: 64%;
  }

  h2 {
    margin: 0;
    font-size: 22px;

    @include tablet {
      font-size: 42px;
    }
  }

  p {
    margin: 0 0 8px;
  }
}

.savings-address-bar__image {
  object-fit: cover;

  @include tablet {
    width: 36%;
    order: 2;
  }
}
