@use "sass:color";

.block--neo-download-list {
  .block--header {
    grid-column: 2/14;

    .block--title {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      @include tablet {
        flex-direction: row;
      }
    }
  }

  .block--content {
    grid-column: 2/14;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include tablet {
      justify-content: center;
      align-items: stretch;
    }

    .partial__block {
      display: flex;
      flex-direction: column;
      width: 168px;
      @include tablet {
        margin-right: 32px;
      }

      .partial__block--header {
        .partial--title {
          font-size: 14px;
          width: 100%;
          color: $neo-dark-gray;
          padding-bottom: 8px;
          text-align: center;
          @include tablet {
            text-align: left;
          }
        }
      }

      .partial__block--content {
        margin: 0 0 32px 0;
        @include tablet {
          margin: 0;
        }

        .partial__block__image-container {
          display: block;
          position: relative;
          width: 168px;
          height: 250px;

          .image {
            display: block;
            position: relative;
            &:after {
              content: "";
              pointer-events: none;
              width: 32px;
              height: 32px;
              z-index: 1;
              position: absolute;
              right: 0;
              top: 0;
              background-image: linear-gradient(
                45deg,
                color.scale($neo-dark-gray, $lightness: 10%),
                $neo-dark-gray 50%,
                $white 51%
              );
            }
          }
        }

        .partial__block--cta {
          .cta {
            font-size: 14px;
            padding: 0;
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
