.block--related-jobs {
  .block--outer {
    grid-column: 2/14;

    .block--title {
      justify-content: center;
    }

    .block--jobs-list {
      margin: 40px 0 0 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      list-style: none;

      .block--jobs-list__item {
        width: 100%;
        margin-bottom: 16px;
        @include phone-big {
          width: calc((100% - (1 * 32px)) / 2);
          margin-right: 32px;
          margin-bottom: 32px;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
        @include desktop {
          width: calc((100% - (2 * 32px)) / 3);
          margin-right: 32px;
          margin-bottom: 32px;
          &:nth-of-type(2n) {
            margin-right: 32px;
          }
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
        @include desktop {
          width: calc((100% - (3 * 32px)) / 4);
          margin-right: 32px;
          margin-bottom: 32px;
          &:nth-of-type(3n) {
            margin-right: 32px;
          }
          &:nth-of-type(4n) {
            margin-right: 0;
          }
        }

        .link {
          text-decoration: none;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          list-style: none;
          height: 100%;
          border-radius: 4px;
          border: 1px solid $neo-blue;
          border-bottom: 4px solid $neo-blue;
          transition: all 0.5s;
          padding: 0;
          &:hover,
          &:focus {
            transform: translateY(-8px);
          }

          &__general-info {
            padding: 16px 16px 16px 16px;
            border-radius: 4px 4px 0 4px;
            background: $white;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            min-height: 96px;

            &__title {
              font-size: 16px;
              word-break: break-word;
              margin-bottom: 8px;
            }

            &__type {
              font-size: 12px;
              color: $neo-dark-gray;
            }
          }

          &__details {
            padding: 16px 16px 16px 16px;
            border-radius: 0 4px 4px 4px;
            background: $neo-light-gray;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;

            &__content {
              font-size: 12px;
              color: $neo-dark-gray;
              width: calc(100% / 2 - 16px);
              margin-right: 32px;
              max-width: 100%;
              display: flex;
              flex-direction: column;
              margin: 0;

              &:nth-of-type(2) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
